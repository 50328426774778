import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderNovomaticBig = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="120" height="19" viewBox="0 0 120 19">
    <g fill="#000">
      <path
        d="M.028 17.828h3.999v-.343c-.171 0-.4 0-.4-.286V5.433L14.65 17.085c.171.4-.285.342-.514.342v.4h4.227v-.342c-.171 0-.4 0-.457-.4V1.093c0-.229.229-.286.457-.286V.465h-4.227v.342c.4 0 .514.057.514.457v10.738L4.54 1.092c-.114-.228-.114-.341.343-.285V.465H.028v.342c.286-.056.457-.056.457.229L.428 17.199c0 .229-.114.229-.4.286v.343zm117.26-1.714c.912-.571.57-.456 1.312-.913.173-.115.8-.629 1.086-.115l.286-.171-2.456-3.541-.286.17c.115.686-.171 1.144-.686 1.486-6.168 5.312-15.078.686-12.45-6.454 2.17-4.97 8.796-4.512 12.279-2.227.286.285.342.4.342.857l.401.114 2.227-3.598-.228-.171c-.857.514-.742.343-1.6-.057-2.798-1.428-6.11-1.827-9.139-1.2-5.71 1.2-8.567 5.255-7.54 10.795 2.059 6.796 10.626 8.851 16.451 5.025zm-80.477-.4h3.2v-.285c-.286 0-.344-.172-.286-.4l4.854-11.08c.115-.4.286-.4.4-.4v-.228h-3.141v.228c.228 0 .4.115.342.342l-3.54 8.568-3.942-8.453c-.056-.171-.114-.457.286-.457v-.228h-3.37v.228c.456.057.4.229.514.4l4.912 11.137c.057.115 0 .343-.229.343v.285zm48.263-.684l-3.199-6.855-1.884-4.112c-.114-.17-.172-.514.286-.514v-.228h-3.542v.228c.4 0 .4.286.343.457-.682 1.373-1.33 2.764-1.943 4.17-.228.513-.513 1.027-.742 1.541l-2.455 5.426c-.058.171-.058.286-.286.286v.285h2.912v-.285c-.4 0-.228-.343-.17-.572l1.198-2.627h5.713l1.256 2.627c.056.286.114.572-.343.572v.285h3.256v-.285c-.286 0-.286-.115-.4-.4zm-5.026-4.856h-3.485c-.056 0-.056-.057-.056-.115l.857-1.884.913-2.056.972 2.056.857 1.884c0 .115-.058.115-.058.115z"
      />
      <path
        d="M71.88 15.43c-.228 0-.4-.058-.4-.344V3.835c0-.171.172-.229.343-.286v-.228h-3.599v.228c.171 0 .4.229.344.342l-3.77 6.969-3.599-6.854s-.171-.457.342-.457v-.228h-3.54v.228c.456 0 .4.4.4.457v11.137c0 .171-.172.286-.4.286v.285h2.74v-.285c-.228 0-.399-.057-.399-.286v-7.71l4.34 8.281 4.514-8.452v7.824c0 .343-.344.343-.344.343v.285h3.028v-.285zm18.562.284v-.285c-.17-.057-.4 0-.457-.286l.058-9.481h4.227c.342 0 .4.114.4.343h.285V3.32h-.286c0 .228-.057.228-.514.285H83.589c-.172-.057-.343-.057-.4-.229l-.171-.056v2.684h.228c0-.229 0-.343.343-.343h3.998v9.481c0 .229-.229.286-.4.286v.285h3.255zm8.854 0v-.285c-.229 0-.457-.115-.457-.4V3.95c0-.286.113-.4.4-.4V3.32h-3.256v.228c.456 0 .4.17.4.4l.056 11.136c-.056.229-.227.344-.456.344v.284h3.313v.001zM33.041 9.432c-.228-3.37-3.027-6.111-6.968-6.111-4.055 0-7.025 2.626-7.139 6.11v.172c0 3.542 2.855 6.111 6.91 6.111 4.17 0 7.255-2.34 7.255-5.882 0-.115 0-.287-.058-.4zm-2.056.228c0 2.456-2.17 4.112-5.026 4.112-2.913 0-4.912-1.828-4.912-4.34.057-2.457 2.17-4.341 5.026-4.341 2.799 0 4.798 1.941 4.912 4.34v.229zm26.788-.228c-.229-3.37-3.027-6.111-6.968-6.111-4.112 0-7.026 2.626-7.14 6.11v.172c0 3.542 2.799 6.111 6.911 6.111s7.197-2.34 7.197-5.882v-.4zm-2.057.228c0 2.456-2.17 4.112-5.083 4.112-2.913 0-4.855-1.828-4.855-4.34 0-2.457 2.114-4.341 5.027-4.341 2.798 0 4.798 1.941 4.912 4.34v.229z"
      />
    </g>
  </svg>
);

IconProviderNovomaticBig.propTypes = {
  className: PropTypes.string,
};
IconProviderNovomaticBig.defaultProps = {
  className: null,
};
