import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderGameArtBig = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="120" height="15" viewBox="0 0 1695.3 215.9">
    <polygon points="321.2 128.1 393.5 128.1 357 74.9 321.2 128.1" fill="#000" />
    <path d="M989.7,3l-2-3H0V215.9H887l24.5-36.6h140l23.3,34,1.8,2.6h59.1l-6.5-9.4Zm-759.6,97v61.2l-1.6,1.4c-19.6,16.8-48.5,25.3-86.1,25.3C77.1,187.8,38,158.1,38,108.3,38,82.9,48.6,62,68.7,47.9c18.4-13,43.9-19.8,73.8-19.8,36.2,0,61.9,7.2,81.1,22.7l3.6,2.9-2.9,3.6-12.8,16-3,3.7-3.6-3.1C192.2,63.3,176.6,56.2,142,56.2c-46.2,0-69.6,17.4-69.6,51.7s22.8,51.9,69.6,51.9c24.7,0,43.3-4.2,56.8-12.7V124.3H126.7v-29H230.1ZM460.9,185H432.6l-1.4-2-17.9-26.1H301.9L284.5,183l-1.4,2.1H247.3l4.8-7.2L352.6,28.3l1.4-2.1h6.8l1.4,2L464.7,177.8l5,7.2Zm244-152.1V185H671.7V93.3l-64.3,61.6-1.3,1.3h-5.5l-1.3-1.2L531.4,93V185H498.1V28.3h8.5l1.3,1.2,95.4,87.3L695,29.6l1.3-1.3h8.6Zm228.3,4.9L919.7,58.5l-1.4,2.1H790.8V91.4H898.2l-4.7,7.2-12.9,19.8-1.4,2.1H790.8v34.7h65.7l-4.7,7.2-13.5,20.7-1.4,2.1H757.5V30.6H937.9Zm9.4,95,38.1-56.6,38.9,56.6Z" fill="#000" />
    <polygon points="1689.1 0 1444.8 0 1438.6 0 1438.6 6.2 1438.6 42.9 1438.6 49.1 1444.8 49.1 1540 49.1 1540 209.7 1540 215.9 1546.2 215.9 1587.6 215.9 1593.8 215.9 1593.8 209.7 1593.8 49.1 1689.1 49.1 1695.3 49.1 1695.3 42.9 1695.3 6.2 1695.3 0 1689.1 0" fill="#000" />
    <path d="M1354.3,133.4c48.5-12.6,56.1-45.4,56.1-64.7,0-23.2-9.1-40.8-27.1-52.3S1340.7,0,1306.2,0H1159.6V215.9h53.7V138.7h78.1l68.2,75.2,1.8,2H1429l-9.4-10.4Zm-51.6-41.3h-89.4v-44h89.4c53,0,53,11.6,53,21.8S1355.7,92.1,1302.8,92.1Z" fill="#000" />
  </svg>
);

IconProviderGameArtBig.propTypes = {
  className: PropTypes.string,
};
IconProviderGameArtBig.defaultProps = {
  className: null,
};
