import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderGameArt = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="25" height="11" viewBox="0 0 25 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 5.73572V11H22.0348L24.5 10.9949V0.476567V0.471432H0.5V5.73572ZM20.5043 5.4327C21.891 7.45624 23.062 9.17676 23.1133 9.25893L23.2109 9.40788H22.3635H21.5109L21.059 8.74021L20.607 8.07768L17.8593 8.07254H15.1116L14.675 8.74021L14.2385 9.40788H13.4322C12.9853 9.40788 12.6207 9.39247 12.6207 9.37706C12.6207 9.36165 13.7711 7.64113 15.1732 5.55082C17.4536 2.156 17.736 1.7554 17.8542 1.7554C17.9723 1.76054 18.2856 2.19195 20.5043 5.4327ZM8.4144 1.90948C9.37481 2.00706 10.212 2.25358 10.8026 2.60796C11.2597 2.88016 11.4497 3.03937 11.4189 3.12155C11.3727 3.23454 10.6536 4.11791 10.6023 4.11791C10.5766 4.11791 10.4636 4.04087 10.3558 3.94329C10.0784 3.70704 9.58025 3.47079 9.09234 3.34239C8.52739 3.18832 6.94554 3.14723 6.2676 3.26535C4.81928 3.51701 4.09512 4.23604 4.00781 5.5046C3.90509 6.93751 4.61385 7.83116 6.09298 8.13418C6.51926 8.22149 6.7247 8.23176 7.63888 8.21121C8.79446 8.18553 9.20019 8.11363 9.83704 7.81575L10.1555 7.66681V7.07618V6.48042H8.40927H6.66306V5.81275V5.14509H9.15397H11.6449V6.7064V8.27284L11.4292 8.44746C10.6331 9.09459 9.33886 9.49005 7.81864 9.54654C6.44222 9.5979 5.34828 9.40274 4.42895 8.95078C3.79724 8.63749 3.35555 8.28312 3.03713 7.84143C2.54408 7.14808 2.36946 6.49069 2.41569 5.50974C2.45677 4.56473 2.73411 3.9022 3.35042 3.27563C4.41355 2.19195 6.2676 1.69377 8.4144 1.90948Z" className="icon-switch-dark" fill="#00004E" />
    <path d="M16.9402 5.37106C16.4575 6.09523 16.062 6.69613 16.062 6.71153C16.062 6.72694 16.8581 6.73721 17.8339 6.73721C19.0614 6.73721 19.6058 6.72181 19.6058 6.68072C19.6058 6.6345 17.9777 4.22576 17.8596 4.09736C17.8339 4.07682 17.423 4.6469 16.9402 5.37106Z" className="icon-switch-dark" fill="#00004E" />
  </svg>
);

IconProviderGameArt.propTypes = {
  className: PropTypes.string
};

IconProviderGameArt.defaultProps = {
  className: ''
};

