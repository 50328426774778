import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderTvbet = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-1016 -1065) translate(1016 1065)">
          <mask id="icon-tvbet-small-mask" fill="#fff">
            <use xlinkHref="#icon-tvbet-small-8" />
          </mask>
          <path d="M0 0H24V24H0z" />
          <g fillRule="nonzero" mask="url(#icon-tvbet-small-mask)">
            <path fill="url(#icon-tvbet-small-0)" d="M14.709 23.597H8.302c-.189 0-.36-.108-.443-.277L.195 7.635c-.075-.152-.066-.332.024-.476.09-.143.247-.23.416-.231h6.636c.19 0 .363.11.444.281l7.438 15.685c.073.152.063.332-.028.474-.09.143-.247.23-.416.229z" />
            <path fill="url(#icon-tvbet-small-1)" d="M17.16.29h.408c.27 0 .49.22.49.49v4.578c0 .13-.051.256-.143.348-.092.093-.217.145-.347.145h-.408c-.272 0-.491-.22-.491-.49V.78c0-.27.22-.49.49-.49z" transform="rotate(23.74 17.363 3.071)" />
            <path fill="url(#icon-tvbet-small-2)" d="M18.812 2.75h.406c.271 0 .491.22.491.492v5.954c0 .276-.223.5-.5.5h-.397c-.271 0-.491-.22-.491-.492V3.24c0-.27.22-.489.49-.489z" transform="rotate(23.74 19.015 6.223)" />
            <path fill="url(#icon-tvbet-small-3)" d="M21.308.313c.384 0 .696.312.696.696 0 .384-.312.695-.696.695-.384 0-.695-.311-.695-.695 0-.384.311-.696.695-.696z" transform="rotate(23.74 21.308 1.009)" />
            <path fill="url(#icon-tvbet-small-4)" d="M18.957 11.532l.453-1.033c.053-.12.056-.255.008-.376-.047-.122-.14-.22-.26-.272l-.423-.188c-.103-.048-.22-.053-.327-.013-.106.04-.192.12-.238.224l-.684 1.556-1.23 2.788c-.052.12-.15.213-.271.26-.122.047-.257.044-.377-.008l-.368-.163c-.248-.111-.36-.401-.252-.65l1.23-2.794 1.628-3.7c.052-.12.055-.255.008-.376-.047-.122-.14-.22-.26-.272l-.377-.165c-.12-.053-.255-.056-.376-.01-.122.048-.22.142-.272.262l-1.627 3.702-.549 1.228c-.11.24-.39.352-.635.252l-.372-.163c-.12-.054-.255-.057-.376-.01-.122.048-.22.142-.272.261L7.88 22.907c-.067.152-.053.327.038.466.09.14.245.224.411.224h6.422c.195 0 .37-.114.449-.292l4.697-10.45c.037-.083.04-.177.007-.262-.033-.084-.098-.153-.18-.19l-.509-.21c-.123-.052-.221-.151-.27-.276-.049-.125-.044-.264.012-.385z" />
            <path fill="url(#icon-tvbet-small-5)" d="M20.682 8.18c.384 0 .695.312.695.696v.925c0 .384-.311.696-.695.696-.384 0-.695-.312-.695-.696v-.925c0-.384.31-.695.695-.695z" transform="rotate(23.74 20.682 9.339)" />
            <path fill="url(#icon-tvbet-small-6)" d="M14.63 8.137c.383 0 .694.312.694.696v.925c0 .384-.31.695-.695.695-.384 0-.695-.311-.695-.695v-.925c0-.384.311-.696.695-.696z" transform="rotate(23.74 14.63 9.295)" />
            <path fill="url(#icon-tvbet-small-7)" d="M22.433 3.501c.384 0 .696.312.696.696v2.315c0 .384-.312.696-.696.696-.384 0-.695-.312-.695-.696V4.197c0-.384.311-.696.695-.696z" transform="rotate(23.74 22.433 5.354)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconProviderTvbet.propTypes = {
  className: PropTypes.string
};

IconProviderTvbet.defaultProps = {
  className: ''
};
