import React from 'react';

export const IconProviderNolimit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
    <title>providers logo/small/nolimit-city</title>
    <g id="providers-logo/small/nolimit-city" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" x="0" y="0" width="24" height="24" />
      <g id="Group" transform="translate(3.000000, 2.000000)" fill="#FFFC00" fillRule="nonzero">
        <path d="M4.47926411,0.00111843838 C5.62956034,0.00111843838 6.77985656,0.00111843838 7.93015279,0.00111843838 C8.69715127,-0.0102384295 9.46162473,0.0923343433 10.1989398,0.30553159 C11.5448613,0.654805755 12.806947,1.2742237 13.9095728,2.12666992 C15.4611854,3.32742086 16.5045929,5.07499825 16.8303711,7.01864268 C16.9151855,7.46191095 16.946991,7.91051981 17,8.35912867 C17,8.46950069 17,8.5816529 17,8.69558531 C17,8.95193322 17,8.97863613 16.7349548,8.9893173 L13.0243218,8.9893173 C12.975696,8.99621255 12.9266357,8.98008901 12.8913989,8.94563258 C12.856162,8.91117614 12.8387013,8.86225178 12.8440911,8.8130781 C12.8423466,8.49006535 12.808607,8.16803443 12.7433739,7.85177341 C12.6253821,7.13430898 12.3401616,6.45527162 11.9111319,5.87041763 C11.2488905,5.01022754 10.2857112,4.43716334 9.21827253,4.26824315 C8.84110104,4.19367222 8.4576427,4.15611251 8.07327721,4.15609093 L4.33083879,4.15609093 C4.24801779,4.16180224 4.18465801,4.23272894 4.18771437,4.31630838 C4.18771437,4.44448234 4.18771437,4.5726563 4.18771437,4.70083026 L4.18771437,8.56741135 C4.18771437,9.00533904 4.18771437,9 3.77424384,9 L0.413470533,9 L0.121920798,9 C0.0548894628,8.99462475 0.00247785432,8.93952502 0,8.8718245 C0,8.75967229 0,8.64752007 0,8.53536786 L0,0.487111365 C0,-0.0469467962 0,0.00111843838 0.530090427,0.00111843838 L4.47926411,0.00111843838 Z" id="Path" />
        <path d="M3.95800445,20.9387155 C3.86295184,20.9892509 3.75313894,21.0093375 3.644999,20.9959694 L0.171648152,20.9959694 C0.0706786509,20.9959694 0,20.9434867 0,20.8385213 C0,20.7335558 0,20.6333616 0,20.5331673 L0,11.5920227 C0,11.4632015 0,11.3296092 0,11.200788 C0,11.0719668 0.0403878005,11.0147129 0.227181378,11.0003995 L3.52888407,11.0003995 L3.85198647,11.0003995 C3.89033926,10.9974854 3.92811366,11.0106214 3.95532068,11.0363339 C3.98252771,11.0620464 3.99642726,11.0977458 3.99334377,11.1339918 C3.99334377,11.2484995 3.99334377,11.3630072 3.99334377,11.477515 L3.99334377,20.5427096 C4.00899144,20.6756266 3.99698642,20.8101527 3.95800445,20.9387155 Z" id="Path" />
        <path d="M16.9854076,16.0071599 L16.9854076,20.451074 C16.9854076,20.5513126 16.9854076,20.6563246 16.9854076,20.7565632 C16.9854076,20.9761337 16.9551004,21 16.7176935,21 L13.2475126,21 L13.11113,21 C13.0473628,20.992718 12.9995394,20.9413634 13,20.8806683 C13,20.7804296 13,20.6801909 13,20.5799523 L13,11.3770883 C13,11.0047733 13,11 13.4041001,11 L16.7833592,11 C16.9500492,11 16.9854076,11.0429594 16.99551,11.2195704 C17.0056125,11.3961814 16.99551,11.477327 16.99551,11.6062053 L16.9854076,16.0071599 Z" id="Path" />
      </g>
    </g>
  </svg>
);
