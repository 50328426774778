import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProvider3oaksGaming = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="24" height="24" viewBox="0 0 25 12" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.813 0.893616H20.8805C20.7553 1.00041 20.6535 1.13553 20.5963 1.32732C20.1741 1.34693 19.7498 1.40796 19.3276 1.51475C18.8546 1.63462 18.443 1.92448 18.443 2.42357C18.443 2.58485 18.4855 2.73741 18.5576 2.87035L18.5533 2.76574L18.617 2.73305C18.6743 2.70253 18.7358 2.67638 18.7952 2.65023C18.9479 2.58485 19.107 2.53036 19.2661 2.48459C20.1995 2.21435 21.5742 2.21435 22.5076 2.48459C22.6667 2.53036 22.8258 2.58702 22.9786 2.65023C23.038 2.6742 23.0995 2.70253 23.1568 2.73305L23.2204 2.76574L23.2162 2.87035C23.2883 2.73741 23.3307 2.58485 23.3307 2.42357C23.3307 1.92448 22.9192 1.63026 22.4461 1.51475C21.9964 1.40578 21.5466 1.3404 21.0969 1.32514C21.1902 1.20309 21.3345 1.12463 21.4787 1.04835C21.57 0.998228 21.6612 0.950281 21.7439 0.893616H23.9608C24.262 0.893616 24.5081 1.14643 24.5081 1.45591V10.5419C24.5081 10.8514 24.262 11.1042 23.9608 11.1042H17.813C17.5117 11.1042 17.2656 10.8514 17.2656 10.5419V1.45591C17.2635 1.14643 17.5096 0.893616 17.813 0.893616ZM21.3451 5.31784C22.1236 4.86235 22.48 4.3371 22.5246 3.46751C22.2085 4.53107 21.746 5.04541 21.3451 5.31784ZM18.7188 3.95788C18.8291 5.48349 20.4329 5.61207 20.8869 6.47512C21.3409 5.61207 22.9446 5.48129 23.055 3.95788L23.1059 2.8333C22.0091 2.24704 19.7668 2.24704 18.67 2.8333L18.7188 3.95788Z" fill="url(#icom-provider-3oaks-a)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.42916 0.893616H15.577C15.8782 0.893616 16.1243 1.14643 16.1243 1.45591V10.5419C16.1243 10.8514 15.8782 11.1042 15.577 11.1042H9.42916C9.12791 11.1042 8.88184 10.8514 8.88184 10.5419V1.45591C8.88184 1.14643 9.12791 0.893616 9.42916 0.893616ZM12.9952 7.88303C13.7738 7.42751 14.1302 6.90227 14.1747 6.03268C13.8586 7.09624 13.3962 7.6106 12.9952 7.88303ZM10.3371 6.60587C10.4474 8.13148 12.0512 8.26223 12.5052 9.12311C12.9592 8.26006 14.5629 8.12928 14.6733 6.60587L14.7242 5.48129C13.6274 4.89503 11.3851 4.89503 10.2883 5.48129L10.3371 6.60587ZM12.2146 3.97532C11.7924 3.99493 11.3681 4.05595 10.946 4.16275C10.4729 4.28262 10.0613 4.57248 10.0613 5.07156C10.0613 5.23284 10.1038 5.38539 10.1759 5.51834L10.1717 5.41373L10.2353 5.38105C10.2926 5.35054 10.3541 5.32437 10.4135 5.29823C10.5662 5.23284 10.7253 5.17836 10.8845 5.13258C11.8179 4.86235 13.1925 4.86235 14.1259 5.13258C14.2851 5.17836 14.4442 5.23501 14.5969 5.29823C14.6563 5.3222 14.7178 5.35054 14.7751 5.38105L14.8387 5.41373L14.8345 5.51834C14.9066 5.38539 14.9491 5.23284 14.9491 5.07156C14.9491 4.57248 14.5375 4.27825 14.0644 4.16275C13.6147 4.05378 13.1649 3.98839 12.7152 3.97315C12.8086 3.85108 12.9528 3.77262 13.0971 3.69636C13.4153 3.52417 13.725 3.35636 13.7632 2.87471C13.1734 2.95099 13.4704 2.92266 12.8892 3.29098C12.6091 3.44354 12.3227 3.59829 12.2146 3.97532Z" fill="url(#icom-provider-3oaks-b)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.04732 0.893616H1.93195L1.95316 1.37091C2.06347 2.8965 3.66726 3.02509 4.12124 3.88815C4.5752 3.02509 6.17899 2.89432 6.28931 1.37091L6.31053 0.893616H7.19513C7.49638 0.893616 7.74246 1.14643 7.74246 1.45591V10.5419C7.74246 10.8514 7.49638 11.1042 7.19513 11.1042H6.31265L6.34022 10.5005C5.24345 9.91425 3.00113 9.91425 1.90437 10.5005L1.93195 11.1042H1.04732C0.746082 11.1064 0.5 10.8514 0.5 10.5419V1.45591C0.5 1.14643 0.746082 0.893616 1.04732 0.893616ZM5.80137 0.893616C5.48955 1.80243 5.06738 2.26229 4.69614 2.51293C5.40256 2.09883 5.76108 1.6259 5.85443 0.893616H5.80137ZM3.83273 8.99671C3.41056 9.01631 2.98628 9.07733 2.56412 9.18413C2.09105 9.30401 1.6795 9.59387 1.6795 10.0929C1.6795 10.2542 1.72193 10.4068 1.79405 10.5397L1.78981 10.4351L1.85345 10.4024C1.91073 10.3719 1.97225 10.3458 2.03165 10.3196C2.18439 10.2542 2.3435 10.1997 2.5026 10.154C3.43602 9.88373 4.81068 9.88373 5.7441 10.154C5.90322 10.1997 6.06231 10.2564 6.21506 10.3196C6.27445 10.3436 6.33598 10.3719 6.39325 10.4024L6.4569 10.4351L6.45266 10.5397C6.52479 10.4068 6.5672 10.2542 6.5672 10.0929C6.5672 9.59387 6.15565 9.29964 5.6826 9.18413C5.23285 9.07516 4.78311 9.00978 4.33336 8.99451C4.42671 8.87247 4.57096 8.79401 4.71522 8.71774C5.03345 8.54556 5.34315 8.37773 5.38134 7.8961C4.79161 7.97236 5.0886 7.94405 4.50734 8.31237C4.2273 8.46276 3.94091 8.61748 3.83273 8.99671Z" fill="url(#icom-provider-3oaks-c)" />
  </svg>
);

IconProvider3oaksGaming.propTypes = {
  className: PropTypes.string
};

IconProvider3oaksGaming.defaultProps = {
  className: ''
};
