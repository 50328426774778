import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderEvolution = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="26" height="35" viewBox="0 0 26 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.4333 32.8493H23.2183H23.0035V32.2044H23.2183C23.4333 32.2044 23.6484 32.2044 23.6484 32.2044C23.8632 32.2044 23.8632 32.4195 23.8632 32.4195C23.8632 32.8493 23.6484 32.8493 23.4333 32.8493ZM24.2936 32.4195C24.2936 32.2044 24.0785 31.9893 24.0785 31.7745C23.8635 31.7745 23.6487 31.7745 23.4336 31.7745H22.7887V33.9246H23.2185V33.0644H23.6487L24.0785 33.9246H24.2936L23.8635 33.0644C24.0785 33.0644 24.2936 32.8493 24.2936 32.4195ZM23.4333 34.5696C22.5731 34.5696 21.7133 33.7093 21.7133 32.8496C21.7133 31.9893 22.5733 31.1296 23.4333 31.1296C24.2933 31.1296 25.1533 31.9896 25.1533 32.8496C25.1533 33.9244 24.5084 34.5696 23.4333 34.5696ZM23.4333 30.6995C22.1435 30.6995 21.2832 31.7745 21.2832 32.8496C21.2832 34.1397 22.3583 34.9997 23.4333 34.9997C24.7235 34.9997 25.5837 33.9246 25.5837 32.8496C25.5837 31.7745 24.7235 30.6995 23.4333 30.6995Z" fill="#A3A3A3" />
    <path d="M0.425781 2.10406C10.7463 -3.48605 24.2919 2.53419 25.367 16.7248C6.01589 18.23 3.65097 9.41444 0.425781 2.10406Z" fill="url(#icon-evo-0)" />
    <path d="M0.425781 32.6364C10.9613 38.2268 24.5067 32.4216 25.367 18.0157C6.01589 16.5107 3.65097 25.3263 0.425781 32.6364Z" fill="url(#icon-evo-1)" />
    <path d="M21.7119 6.6188C16.7667 -0.0466382 7.73637 -1.76663 0.210938 2.10349C3.22106 8.76893 5.37119 16.2941 19.7769 16.7242C21.067 14.359 21.927 11.564 21.927 8.55387C21.9267 8.12374 21.7119 7.26374 21.7119 6.6188ZM3.86599 25.5395C4.29612 25.5395 4.72599 25.5395 5.15612 25.5395C10.9613 25.5395 16.1218 22.5294 19.1317 18.0141C10.5314 18.4442 6.44625 21.6694 3.86599 25.5395Z" fill="url(#icon-evo-2)" />
  </svg>
);

IconProviderEvolution.propTypes = {
  className: PropTypes.string
};

IconProviderEvolution.defaultProps = {
  className: ''
};
