import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderSpribe = ({ className }) => (
  <svg className={classNames('icon-spribe', className)} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.78223 15.0198V13.8402H10.8954V15.213C10.8954 16.4695 11.3809 17.098 12.3511 17.098H12.3634C13.3248 17.098 13.8062 16.4715 13.8062 15.2191C13.8062 14.5035 13.599 13.8327 13.1833 13.2062C12.7682 12.5797 12.2718 12.0069 11.694 11.4878L10.1337 9.87763C9.48889 9.31436 8.94941 8.6164 8.51522 7.78443C8.08035 6.95178 7.86291 6.03001 7.86291 5.01912C7.86291 3.46334 8.27317 2.27014 9.09368 1.43817C9.91351 0.606198 11.028 0.190552 12.4359 0.190552C13.8438 0.190552 14.9248 0.606198 15.6797 1.43749C16.4345 2.26878 16.812 3.46198 16.812 5.01708V5.63409H13.9409V4.83001C13.9409 3.56946 13.4335 2.93885 12.4181 2.93885C11.4027 2.93885 10.8954 3.57014 10.8954 4.83137C10.8954 5.35926 11.0062 5.86266 11.227 6.34157C11.4479 6.82048 11.7344 7.25178 12.0865 7.63681C12.4386 8.02116 12.827 8.40144 13.2509 8.77695C13.6749 9.15314 14.094 9.54702 14.507 9.95858C14.92 10.3701 15.3022 10.8103 15.653 11.2803C16.0031 11.7497 16.2882 12.3048 16.5084 12.9443C16.7286 13.5844 16.8386 14.2797 16.8386 15.0314C16.8386 16.5831 16.4523 17.7735 15.6797 18.6028C14.907 19.432 13.8164 19.847 12.4079 19.847C10.9993 19.847 9.8759 19.4314 9.03829 18.6001C8.20069 17.7688 7.78223 16.5749 7.78223 15.0198Z"
      fill="black"
      className="icon-switch-dark"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.7002 23.4H16.7002V21H7.7002V23.4Z"
      fill="black"
      className="icon-switch-dark"
    />
  </svg>
);

IconProviderSpribe.propTypes = {
  className: PropTypes.string
};

IconProviderSpribe.defaultProps = {
  className: ''
};
