import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderAvatarUx = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path d="M1 19.3192L9.63114 3.67592L13.6 1L22 20L12.0455 8.22006L1 19.3192Z" fill="white" />
    <path d="M8 17.6431L11.4116 20.4928L24 9L11.6064 23L8 17.6431Z" fill="#7FD4F7" />
  </svg>
);

IconProviderAvatarUx.propTypes = {
  className: PropTypes.string,
};
IconProviderAvatarUx.defaultProps = {
  className: null,
};
