import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderPlayson = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="31" height="28" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.5727 13.5955L20.5195 18V9L30.5727 13.5955Z" fill="#FFE800" />
    <path d="M20.52 9.5L10.4668 14V5L20.52 9.5Z" fill="#00AEEF" />
    <path d="M10.4673 4.50024L0.414062 9V0L10.4673 4.50024Z" fill="#EC008C" />
    <path d="M20.52 18.4043L10.4668 23V14L20.52 18.4043Z" fill="#02B5A5" />
    <path d="M10.4673 13.5955L0.414062 18V9L10.4673 13.5955Z" fill="#ED1C24" />
    <path d="M10.4673 23.5L0.414062 28V19L10.4673 23.5Z" fill="#FCB040" />
  </svg>
);

IconProviderPlayson.propTypes = {
  className: PropTypes.string
};

IconProviderPlayson.defaultProps = {
  className: ''
};
