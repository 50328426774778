import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderThunderkick = ({ className }) => (
  <svg className={className} width="25" height="25" viewBox="0 0 652 652" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M467.3 357.4L375.4 359.8L372.1 459.7L367.1 518.8L287 518V461.3L280.4 362.2L184.3 366.2L181 270.3L322.5 269.5L469 265.5L471.5 278.3L467.3 357.4ZM537.5 416.8L553.4 309.8L487 175.5L441.9 190.4L383 129.5L279 74.8L256.2 156L240.1 113.3L160.2 188.6L115.5 264L113.5 342.1L82 327.6L103.5 452.8L152.2 533.4L267 571.4L361.1 574.7L523.3 517.5L570 406.1L537.5 416.8Z" fill="black" />
  </svg>
);

IconProviderThunderkick.propTypes = {
  className: PropTypes.string,
};
IconProviderThunderkick.defaultProps = {
  className: null,
};
