import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderApollo = ({ className }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M57.7402 30.3347C57.7402 30.3347 52.4501 30.2638 44.3023 29.832C39.6802 29.5857 36.502 29.2618 34.6093 29.0256C32.639 28.695 32.1026 28.1012 31.8327 26.2389C31.5661 24.2348 31.1883 20.9049 30.9082 16.502C30.4325 9.01215 30.2031 3 30.2031 3C30.2031 3 30.1323 8.33063 29.7004 16.5121C29.4777 20.7699 29.1876 23.8131 28.9582 25.753C28.6815 28.1653 28.1822 28.7524 25.6248 29.0965C23.5938 29.3563 20.4663 29.7004 16.4244 29.9568C8.96491 30.4359 3 30.6653 3 30.6653C3 30.6653 8.29015 30.7362 16.4379 31.1714C21.0297 31.4143 24.1977 31.7348 26.0938 31.971C28.0945 32.3016 28.6376 32.8887 28.9109 34.7679C28.9278 34.8995 29.5756 40.4258 29.8354 44.498C30.3111 51.9879 30.5405 58 30.5405 58C30.5405 58 30.6113 52.6694 31.0398 44.4879C31.2928 39.6802 31.6302 36.4244 31.8698 34.552C32.1734 32.72 32.8178 32.2139 35.1086 31.9035C37.1363 31.6437 40.2706 31.2996 44.3192 31.0398C51.7787 30.5607 57.7436 30.3313 57.7436 30.3313L57.7402 30.3347Z"
      fill="url(#icon-provider-apollo)"
    />
  </svg>
);

IconProviderApollo.propTypes = {
  className: PropTypes.string
};

IconProviderApollo.defaultProps = {
  className: ''
};
