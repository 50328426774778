import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderCandleBets = ({ className }) => (
  <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#candle-bets-f)">
      <path d="M15.7044 18.072C17.7522 17.8006 19.7412 17.4603 21.4226 16.0406C22.3319 15.2751 23.405 16.106 23.4737 17.5257C23.562 19.338 22.2514 21.6507 20.0791 22.7368C18.8159 23.3684 16.8297 23.4435 16.8297 23.4435C11.8685 24 8.07905 23.3684 3.02642 18.3158C3.02642 18.3158 2.26607 17.5683 2.17773 17.2837C3.62036 17.264 4.56155 15.6548 5.55273 14.5263C7.23089 12.6028 7.51989 10.9815 8.71063 8.8421C8.36219 9.2645 8.71667 12.2741 8.70717 12.8217C8.31789 16.4855 11.72 21.4708 15.2496 22.377C15.5572 22.4556 15.9792 22.5961 16.3292 22.0728C14.7197 21.7947 14.2094 20.954 14.4744 19.3642C14.638 18.4842 15.6914 18.8375 15.7044 18.072Z" fill="url(#candle-bets-a)" />
      <path d="M3.49879 4.483C3.90768 4.37185 4.32314 4.28671 4.7255 4.15584C7.4112 3.23008 8.92338 4.07841 9.3421 6.94729C9.3421 6.94729 9.3421 7.57887 8.71053 8.84206C7.37827 11.5066 6.7919 14.1487 5.6022 15.4908C4.41249 16.8329 3.94607 17.2736 2.18701 17.2736C-0.472522 13.3514 0.0476078 8.3038 3.49879 4.483Z" fill="url(#candle-bets-b)" />
      <path d="M3.49854 4.48296C7.58762 -0.00188205 12.8544 -1.17952 18.2192 1.21176C18.746 1.50658 19.36 1.60624 19.953 1.49307C21.5363 1.06448 22.4293 1.90195 23.1228 3.12873C23.9514 4.55269 24.271 6.21614 24.029 7.84587C23.8197 9.26553 23.0084 10.224 21.5559 10.538C20.1035 10.8522 19.1744 10.1194 18.5202 8.9025C17.9509 7.84587 17.3425 6.83496 16.3677 6.07277C13.7507 4.03151 11.1337 4.40768 9.38681 7.10654C8.99099 4.21479 7.41096 3.23332 4.71218 4.14936C4.3229 4.28667 3.90744 4.3718 3.49854 4.48296Z" fill="url(#candle-bets-c)" />
      <path d="M3.49854 4.48296C7.58762 -0.00188205 12.8544 -1.17952 18.2192 1.21176C18.746 1.50658 19.4471 1.89472 19.4471 1.89472C19.4471 1.89472 18.6988 1.47847 18.2192 1.21176C17.7129 1.24531 16.9208 1.26314 16.9208 1.26314C16.9208 1.26314 16.5352 1.24422 16.2892 1.26314C15.7684 1.3032 15.0981 1.5582 14.3945 1.89472C13.5922 2.27841 13.188 2.59467 12.4998 3.15788C10.9801 4.40147 11.1337 4.40767 9.38681 7.10654C8.99099 4.21479 7.41096 3.23332 4.71218 4.14936C4.3229 4.28667 3.90744 4.3718 3.49854 4.48296Z" fill="url(#candle-bets-d)" />
      <path d="M14.9486 17.9412C16.921 17.9412 20.7102 15.7895 22.2847 15.7148C25.3424 15.5697 22.9414 21.7806 20.0786 22.7369C18.2257 23.3558 16.2892 22.7369 14.5221 22.1513C11.2364 20.8421 8.07738 18.3158 8.07861 13.2632C8.07892 12 8.71034 6.94733 8.71034 10.1052C8.71034 16.9936 12.9762 17.9412 14.9486 17.9412Z" fill="url(#candle-bets-e)" />
    </g>
  </svg>
);

IconProviderCandleBets.propTypes = {
  className: PropTypes.string,
};
IconProviderCandleBets.defaultProps = {
  className: null,
};
