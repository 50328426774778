import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderAmusnetEgt = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="45"
    height="30"
    viewBox="0 0 45 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5865 14.9213C8.5865 11.0488 12.2706 6.01106 18.5763 3.32083C29.56 -1.44275 35.8486 2.96099 37.425 7.10771C38.95 10.9803 35.9 17.9029 30.7594 22.9406C34.089 20.6271 37.062 17.8388 39.584 14.6643C42.3771 10.9803 43.5423 7.46755 42.4627 4.67451C40.9377 0.630611 34.7176 -0.723068 27.0411 0.356448C20.6119 1.11185 14.3654 2.99102 8.5865 5.90825C3.18892 8.54706 0.481567 11.7685 0.0360521 15.4011C-0.512273 20.713 5.1766 27.9098 18.3021 28.8008C27.11 29.5196 35.9754 28.6667 44.4847 26.282C42.9597 26.4362 8.50083 30.8228 8.5865 14.9213Z"
      fill="#A5CD39"
    />
    <path
      d="M21.2662 21.1243C22.7453 21.1242 24.191 20.6852 25.4204 19.8628C26.6497 19.0403 27.6072 17.8715 28.1716 16.5043C28.7361 15.1372 28.882 13.6333 28.591 12.1832C28.2999 10.733 27.585 9.40188 26.5367 8.35843C25.4884 7.31498 24.154 6.60616 22.7026 6.32177C21.2511 6.03739 19.7479 6.19024 18.3834 6.76095C17.0188 7.33167 15.8544 8.29457 15.0376 9.52766C14.2209 10.7607 13.7885 12.2085 13.7952 13.6876C13.8043 15.6662 14.5943 17.5613 15.9934 18.9604C17.3925 20.3595 19.2875 21.1495 21.2662 21.1585V21.1243Z"
      fill="#A5CD39"
    />
    <path
      d="M21.2661 20.9359C22.7437 20.9359 24.1881 20.4977 25.4167 19.6768C26.6453 18.8559 27.6029 17.6891 28.1683 16.324C28.7338 14.9588 28.8818 13.4567 28.5935 12.0075C28.3052 10.5582 27.5937 9.22704 26.5489 8.18221C25.504 7.13738 24.1728 6.42584 22.7236 6.13757C21.2744 5.84931 19.7722 5.99726 18.4071 6.56271C17.042 7.12817 15.8752 8.08574 15.0542 9.31433C14.2333 10.5429 13.7952 11.9873 13.7952 13.465C13.8218 15.4381 14.6174 17.3229 16.0128 18.7183C17.4081 20.1136 19.293 20.9093 21.2661 20.9359Z"
      fill="#A5CD39"
    />
    <path
      opacity="0.71"
      d="M23.34 17.8856C24.6956 17.8856 26.0207 17.4837 27.1479 16.7305C28.275 15.9774 29.1535 14.9069 29.6723 13.6545C30.1911 12.4021 30.3268 11.024 30.0623 9.6944C29.7979 8.36484 29.1451 7.14356 28.1865 6.185C27.228 5.22644 26.0067 4.57366 24.6771 4.30919C23.3476 4.04473 21.9694 4.18046 20.717 4.69923C19.4646 5.218 18.3942 6.0965 17.641 7.22365C16.8879 8.35079 16.4859 9.67596 16.4859 11.0316C16.4859 12.8494 17.208 14.5927 18.4934 15.8781C19.7788 17.1635 21.5222 17.8856 23.34 17.8856Z"
      fill="#A5CD39"
    />
  </svg>
);

IconProviderAmusnetEgt.propTypes = {
  className: PropTypes.string,
};

IconProviderAmusnetEgt.defaultProps = {
  className: '',
};
