import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderSwintt = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1039 0H8.55087L4.30115 7.28727L4.32811 7.33344L6.41439 10.9111L6.41408 10.912H6.41501V10.9123L8.55273 14.5779L8.55242 14.5789L8.22397 14.1057L6.22848 11.2327L6.00631 10.9129V10.912H2.18946L0 14.6666L4.27637 22H12.8291L17.1058 14.6666L17.1048 14.665L17.155 14.5789H17.1513L15.0133 10.9129L15.0139 10.912H15.0158L12.8781 7.24606L12.8784 7.24544L15.4245 10.9111V10.912H19.2426H19.2903L19.2918 10.9145L21.3803 7.33344L17.1039 0Z"
      fill="#E84E1C"
    />
  </svg>
);

IconProviderSwintt.propTypes = {
  className: PropTypes.string
};

IconProviderSwintt.defaultProps = {
  className: ''
};
