import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderReevo = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M14.014 12.3477V20.4902C14.014 20.6069 14.1078 20.701 14.224 20.701H19.738C19.8542 20.701 19.9479 20.6069 19.9479 20.4902V16.5497C19.9479 14.2281 18.0736 12.3477 15.7624 12.3477H15.3243H15.1535V12.3323C17.9378 12.2634 19.9493 11.8418 19.9493 8.00943C19.9493 4.00281 17.3736 4.03373 14.0154 4.00281C13.6767 4 13.3295 4 12.9754 4C12.6212 4 12.2741 4 11.9353 4.00281V4H6.20998C6.09379 4 6 4.09416 6 4.2108V20.4959C6 20.6125 6.09379 20.7067 6.20998 20.7067L11.7239 20.7039C11.8401 20.7039 11.9339 20.6097 11.9339 20.4931V7.17466C11.9339 6.60269 12.372 6.10379 12.9418 6.08693C13.5311 6.06866 14.014 6.54366 14.014 7.13109V12.3477Z"
      fill="#151617"
    />
  </svg>
);

IconProviderReevo.propTypes = {
  className: PropTypes.string,
};

IconProviderReevo.defaultProps = {
  className: '',
};
