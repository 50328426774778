import React from 'react';

export const IconProviderHacksaw = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path d="M0 0H24V24H0z" transform="translate(-658 -715) translate(654 713)" />
          <path fill="#000" fillRule="nonzero" d="M11 17l5 .133v4.601c0 .16-.079.266-.21.266h-4.553c-.132 0-.21-.106-.21-.266L11 17zm-7 0l5 .102v4.643c0 .153-.106.255-.238.255h-4.55C4.079 22 4 21.898 4 21.745V17zm6.699-15c.13 0 .209.125.209.275v6.5h3.132v-6.5c0-.175.078-.275.209-.275h4.542c.13 0 .209.125.209.275V16l-4.96-.125v-2.45h-3.132v2.4L6 15.7V2.275C6 2.1 6.078 2 6.209 2h4.49z" transform="translate(-658 -715) translate(654 713)" className="icon-switch-dark" />
        </g>
      </g>
    </g>
  </svg>
);
