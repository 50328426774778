import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderNetent = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="36" height="39" viewBox="0 0 36 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.93403 17.6428C4.93403 22.3569 4.93403 27.0713 4.93403 32C3.42616 32 1.91803 32 0.410156 32C0.410156 24.0714 0.410156 16.143 0.410156 8.00026C1.70254 8.00026 2.99519 8.00026 4.28783 8.00026C4.50332 8.00026 4.93403 8.42867 4.93403 8.64301C7.7348 12.9284 10.3198 17.2141 13.1204 21.4998C13.3358 21.7139 13.5511 22.1426 13.7665 22.5713C13.7665 17.6426 13.7665 12.9284 13.7665 8C15.4902 8 16.998 8 18.5059 8C18.5059 15.9284 18.5059 23.857 18.5059 31.9997C17.2135 31.9997 15.9209 31.9997 14.6282 31.9997C14.4127 31.9997 13.982 31.5713 13.7665 31.357C10.966 27.0713 8.381 22.7859 5.79571 18.5002C5.36474 18.0717 5.36474 17.8574 5.14951 17.6428C4.93403 17.6428 4.93403 17.6428 4.93403 17.6428Z" fill="white" />
    <path d="M20.5156 0C20.9405 0 21.1527 0 21.5779 0C21.5779 2.37026 21.5779 4.95579 21.5779 7.32579C26.2504 7.32579 30.9231 7.32579 35.5954 7.32579C35.5954 8.83421 35.5954 10.3424 35.5954 11.8508C31.9846 11.8508 28.3743 11.8508 24.7635 11.8508C24.7635 13.5745 24.7635 15.2984 24.7635 17.0221C27.737 17.0221 30.7102 17.0221 33.8964 17.0221C33.8964 18.5303 33.8964 20.0387 33.8964 21.7624C30.9232 21.7624 27.9494 21.7624 24.7635 21.7624C24.7635 23.4861 24.7635 25.21 24.7635 26.9337C28.3743 26.9337 31.7724 26.9337 35.5954 26.9337C35.5954 28.4418 35.5954 29.9503 35.5954 31.4584C30.9229 31.4584 26.2504 31.4584 21.5779 31.4584C21.5779 34.0442 21.5779 36.4145 21.5779 39C21.153 39 20.9408 39 20.5156 39C20.5156 26.0718 20.5156 13.1437 20.5156 0Z" fill="#78BD1F" />
  </svg>
);

IconProviderNetent.propTypes = {
  className: PropTypes.string
};

IconProviderNetent.defaultProps = {
  className: ''
};
