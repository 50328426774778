import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderISoftbetBig = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="110" height="24" viewBox="0 0 110 24">
    <g fill="none" fillRule="evenodd">
      <path fill="#FCAF17" d="M109.942 2.578h-4.666v21.51h-2.438V2.578h-4.665V.394h11.769v2.184zm-14.276 0h-7.93v8.071h5.862v2.162h-5.863v9.116h8.305v2.162H85.294V.394h10.372v2.184zm-18.086.818c-.638-.56-1.67-.833-3.094-.818h-2.464v8.003h1.397c1.742.015 3.027-.31 3.857-.977.827-.65 1.233-1.773 1.219-3.365.014-1.335-.29-2.283-.915-2.843zM75.618.394c1.7.03 3.015.569 3.943 1.616.917 1.06 1.383 2.47 1.397 4.229 0 1.211-.268 2.288-.805 3.23-.554.953-1.44 1.627-2.66 2.02v.069c1.365.396 2.367 1.117 3.008 2.161.639 1.045.958 2.311.958 3.797 0 2.199-.596 3.844-1.789 4.937-1.19 1.09-3.027 1.636-5.51 1.636h-4.58V.394h6.038zm-1.132 12.349h-2.464v9.184h2.464c1.482 0 2.601-.356 3.356-1.068.769-.696 1.153-1.81 1.153-3.343.015-1.562-.333-2.744-1.044-3.547-.726-.817-1.881-1.226-3.465-1.226zm-72-10.12c-.393.364-.589.871-.589 1.522 0 .636.196 1.137.588 1.503a1.98 1.98 0 0 0 1.393.545 2.07 2.07 0 0 0 1.419-.545c.392-.38.588-.882.588-1.503 0-.65-.196-1.158-.588-1.522a2.07 2.07 0 0 0-1.419-.545 1.98 1.98 0 0 0-1.393.545zM3.877.098c1.019 0 1.912.356 2.681 1.068.29.242.56.615.806 1.117.246.484.377 1.105.392 1.862-.015.76-.146 1.382-.392 1.867-.247.484-.523.855-.827 1.113a3.932 3.932 0 0 1-2.66 1.045c-1.001-.016-1.887-.364-2.659-1.045-.29-.258-.559-.629-.805-1.113C.152 5.527.014 4.905 0 4.145.015 3.388.152 2.76.414 2.26c.246-.487.522-.86.827-1.117C1.998.462 2.877.113 3.878.098zm1.136 9.915v14.12H2.638v-14.12h.021a3.69 3.69 0 0 0 1.176.182c.406 0 .8-.06 1.179-.182z" />
      <path fill="#000" d="M66.925 2.578h-4.644v21.51h-2.463V2.578h-4.64V.394h11.747zM52.192 2.578h-7.3v8.071h5.468v2.162h-5.468v11.277h-2.441V.394h9.741zM32.21.076c1.276-.015 2.395.386 3.355 1.204.958.805 1.706 2.102 2.246 3.891.522 1.787.79 4.144.805 7.072-.014 2.925-.283 5.282-.805 7.072-.54 1.787-1.288 3.09-2.246 3.91-.96.803-2.079 1.197-3.356 1.181-1.294.016-2.42-.378-3.378-1.18-.96-.82-1.708-2.124-2.245-3.911-.523-1.79-.791-4.147-.806-7.072.015-2.928.283-5.285.806-7.072.537-1.79 1.285-3.086 2.245-3.891C29.79.462 30.915.06 32.21.076zm-2.007 2.98c-.595.56-1.074 1.568-1.437 3.024-.348 1.454-.522 3.508-.522 6.163 0 2.652.181 4.706.544 6.16.348 1.456.82 2.471 1.415 3.047.583.563 1.251.829 2.006.799.74.03 1.409-.236 2.006-.8.595-.575 1.067-1.59 1.415-3.047.349-1.453.523-3.506.523-6.159 0-2.655-.174-4.71-.523-6.163-.363-1.456-.841-2.465-1.436-3.025-.583-.56-1.245-.833-1.985-.818-.74-.03-1.409.243-2.006.818zM22.294 18.38c0 1.895-.502 3.374-1.506 4.436-.987 1.045-2.424 1.575-4.313 1.59-1.19 0-2.207-.28-3.052-.84-.841-.56-1.494-1.341-1.959-2.343-.466-1-.714-2.167-.743-3.502h2.267c.03 1.41.356 2.517.98 3.32.626.79 1.549 1.193 2.768 1.208 1.033 0 1.846-.335 2.441-1.004.595-.666.9-1.629.915-2.888-.03-1.06-.341-1.976-.936-2.748-.61-.775-1.366-1.488-2.268-2.14-.9-.635-1.8-1.31-2.703-2.025a9.907 9.907 0 0 1-2.263-2.453c-.598-.926-.911-2.063-.94-3.41.029-1.684.53-3.02 1.505-4.006.987-.984 2.309-1.484 3.966-1.5 1.657.016 2.971.569 3.943 1.659.99 1.108 1.542 2.7 1.658 4.777h-2.267c-.087-1.244-.4-2.26-.936-3.047-.537-.787-1.387-1.196-2.55-1.227-.958.016-1.714.31-2.268.886-.551.576-.834 1.319-.849 2.23.03 1.045.341 1.938.936 2.68.612.73 1.368 1.405 2.268 2.026.9.62 1.8 1.28 2.702 1.98a9.702 9.702 0 0 1 2.268 2.566c.595 1.017.907 2.275.936 3.775z" />
    </g>
  </svg>
);

IconProviderISoftbetBig.propTypes = {
  className: PropTypes.string,
};
IconProviderISoftbetBig.defaultProps = {
  className: null,
};
