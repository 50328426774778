import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderBetsoft = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.93791 20.4003C7.49583 20.4003 7.05376 20.4003 6.6115 20.4003C6.31667 20.4003 6.16943 20.4003 6.31667 20.1056C6.6115 18.7792 6.90616 17.6001 7.201 16.2738C8.23274 11.9996 9.26431 7.72574 10.2961 3.45169C10.5907 3.00961 10.5907 3.00961 10.8856 3.00961C11.7697 3.00961 12.5068 3.00961 13.3909 3.00961C14.7173 3.00961 15.6017 4.1886 15.4543 5.36777C15.3068 6.10468 15.1594 6.84159 14.8648 7.57832C14.7173 8.31523 14.5699 9.05232 14.2753 9.78906C14.1279 10.3786 13.8332 10.8206 13.3909 11.2629C13.2435 11.4103 13.2435 11.5575 13.2435 11.705C13.5382 12.2946 13.5382 13.0314 13.3909 13.6209C13.0963 14.9473 12.8015 16.1264 12.5068 17.4528C12.0647 19.2213 10.5907 20.4003 8.82224 20.4003C8.67482 20.4003 8.38016 20.4003 7.93791 20.4003ZM11.7699 13.4736C11.7699 12.8839 11.4752 12.7365 11.033 12.7365C10.8856 12.7365 10.5909 12.7365 10.4435 12.7365C10.1486 12.7365 10.1486 12.7365 10.0014 13.0314C9.55933 14.7998 9.11707 16.5685 8.675 18.1897C8.675 18.4844 8.675 18.4844 8.96965 18.4844C9.11707 18.4844 9.26449 18.4844 9.26449 18.4844C10.0014 18.4844 10.4435 18.1897 10.5909 17.4528C10.8857 16.4211 11.033 15.3895 11.3278 14.5052C11.6225 14.2105 11.7699 13.7683 11.7699 13.4736ZM11.1804 10.6734C11.9173 10.6734 12.3594 10.3786 12.5068 9.78906C12.8015 8.46265 13.0963 7.13607 13.5384 5.80984C13.6858 5.36777 13.2437 4.92551 12.9489 4.92551C12.8014 4.92551 12.6542 4.92551 12.3594 4.92551C12.212 4.92551 12.0647 4.92551 12.0647 5.22017C11.6226 6.84141 11.1804 8.61007 10.8857 10.2311C10.5909 10.6734 10.7381 10.6734 11.1804 10.6734Z" fill="black" />
    <path d="M20.3168 23.0529C18.5483 23.0529 16.9271 23.0529 15.1584 23.0529H15.011C14.7164 23.0529 14.7164 23.0529 14.7164 22.7583C15.011 21.4319 15.3059 20.1053 15.7479 18.7789C16.19 16.7157 16.7797 14.7997 17.2218 12.7363C17.3692 12.2944 17.3692 12.2944 17.6638 12.2944C18.2535 12.2944 18.9901 12.2944 19.5797 12.2944C19.8744 12.2944 19.8744 12.2944 20.0218 11.9996C20.0218 11.8524 20.0218 11.7049 20.1692 11.5575C20.1692 11.4103 20.1692 11.4103 20.0218 11.2629C19.8744 11.2629 19.8744 11.2629 19.7272 11.2629C19.1377 11.2629 18.5482 11.2629 17.9585 11.2629C17.6638 11.2629 17.6638 11.2629 17.6638 10.9682C18.1059 9.34697 18.4007 7.72591 18.8428 6.10466C19.2849 4.48342 19.5797 2.86236 20.0218 1.24112C20.1692 0.799042 20.1692 0.799042 20.4639 0.799042C23.7062 0.799042 27.0961 0.799042 30.3386 0.799042C30.6331 0.799042 30.7806 0.799042 31.0753 0.799042C31.2227 0.799042 31.3701 0.94646 31.2227 1.0937C30.6331 3.59927 30.0437 6.10466 29.4542 8.75747C29.3068 9.49456 29.1594 10.2315 28.8646 10.9682C28.7172 11.4103 28.7172 11.4103 28.2749 11.4103C27.6854 11.4103 27.0961 11.4103 26.5064 11.4103C26.359 11.4103 26.2118 11.4103 26.2118 11.7049C26.2118 11.8524 26.0643 11.9996 26.0643 12.147C26.0643 12.2944 26.0643 12.4417 26.2118 12.4417C26.5064 12.4417 26.8013 12.4417 27.2433 12.4417C27.5382 12.4417 27.8328 12.4417 28.1277 12.4417C28.4223 12.4417 28.4223 12.4417 28.4223 12.7363C27.9804 14.505 27.5382 16.2737 27.0961 18.0421C26.654 19.6634 26.359 21.1372 25.9169 22.7584C25.9169 22.9057 25.7697 23.0531 25.6223 23.0531C23.8541 23.0529 22.0855 23.0529 20.3168 23.0529Z" fill="#FFE000" />
    <path d="M8.37926 23.0533C6.61078 23.0533 4.84213 23.0533 3.07364 23.0533C2.77881 23.0533 2.77881 23.0533 2.77881 22.7587C3.51572 19.3688 4.40005 16.1265 5.13696 12.7366C5.13696 12.4419 5.28438 12.4419 5.57904 12.4419C6.16853 12.4419 6.75802 12.4419 7.20028 12.4419C7.3477 12.4419 7.49511 12.2947 7.49511 12.1473C7.49511 11.9998 7.49511 11.9998 7.49511 11.8526C7.49511 11.5578 7.49511 11.4105 7.20028 11.4105C6.61078 11.4105 6.16853 11.4105 5.57904 11.4105C5.28438 11.4105 5.28438 11.4105 5.28438 11.1159C6.16853 7.72597 6.90544 4.48366 7.78977 1.09376C7.78977 0.946521 7.93719 0.799103 8.08461 0.799103C11.1795 0.799103 14.1271 0.799103 17.2222 0.799103C17.6643 0.799103 18.254 0.799103 18.6962 0.799103C18.9907 0.799103 19.1383 0.799103 18.9907 1.24118C18.254 4.48348 17.3696 7.87338 16.6325 11.1159C16.6325 11.2631 16.4853 11.4105 16.3381 11.4105C15.7484 11.4105 15.0115 11.4105 14.422 11.4105C14.1273 11.4105 13.9799 11.5578 13.9799 11.8526C13.9799 11.9998 13.9799 12.2947 14.1273 12.4421H14.2747C14.8642 12.4421 15.4537 12.4421 16.1906 12.4421C16.4855 12.4421 16.4855 12.4421 16.4855 12.7367C15.6011 16.1266 14.8642 19.5164 13.9799 22.9061C13.9799 23.2008 13.8325 23.2008 13.5378 23.2008C11.9164 23.0533 10.1477 23.0533 8.37926 23.0533ZM7.93701 20.4003C8.23185 20.4003 8.67392 20.4003 8.96876 20.4003C10.7372 20.4003 12.2111 19.2214 12.6533 17.4529C12.948 16.1265 13.2428 14.9473 13.5375 13.6209C13.6849 13.0314 13.6849 12.2947 13.39 11.705C13.2426 11.5576 13.39 11.4103 13.5375 11.2629C13.9795 10.8209 14.2744 10.3786 14.4218 9.7891C14.5692 9.05219 14.7166 8.3151 15.0113 7.57837C15.1587 6.84146 15.4534 6.10455 15.6008 5.36781C15.7482 4.18883 14.7166 3.00966 13.39 3.00966C12.6531 3.00966 11.7688 3.00966 10.8847 3.00966C10.5898 3.00966 10.5898 3.00966 10.4426 3.45174C9.41083 7.72579 8.37926 11.9997 7.34752 16.2739C7.05286 17.6001 6.75802 18.7793 6.46337 20.1057C6.31595 20.4003 6.46337 20.4003 6.75802 20.4003C7.05286 20.4003 7.49494 20.4003 7.93701 20.4003Z" fill="white" />
    <path d="M19.8742 16.4213C20.0216 16.4213 20.3163 16.4213 20.4639 16.4213C20.7586 16.4213 20.7586 16.4213 20.6113 16.7162C20.4639 17.0108 20.4639 17.4531 20.3165 17.7477C20.3165 18.0424 20.3165 18.3374 20.4639 18.4846C20.7586 18.6321 21.0536 18.6321 21.3482 18.6321C21.7901 18.4846 22.0851 18.19 22.2324 17.7477C22.3798 16.8634 22.6745 15.9791 22.822 15.2422C22.9695 14.3578 22.6746 13.7683 22.0851 13.0316C21.7903 12.7368 21.4956 12.4419 21.2008 12.1473C20.6113 11.5576 20.3165 10.8209 20.4639 9.93655C20.4639 9.34705 20.6113 8.75756 20.7586 8.3153C20.906 7.57839 21.0534 6.69424 21.3482 5.95715C21.7901 4.63093 22.822 3.7466 24.1485 3.599C25.0326 3.45176 25.9169 3.599 26.5066 4.33591C26.9487 4.92558 26.9487 5.5149 26.8013 6.10457C26.654 6.54664 26.654 6.84148 26.5066 7.28356C26.5066 7.43098 26.3592 7.57822 26.212 7.57822C25.7699 7.57822 25.3276 7.57822 24.8855 7.57822C24.8855 7.43098 24.8855 7.43097 24.8855 7.13614C25.0328 6.84148 25.0328 6.39923 25.1804 6.10457C25.3276 5.5149 24.7383 5.073 24.1486 5.22024C23.7066 5.36766 23.4117 5.66232 23.2643 6.10457C23.1169 6.84148 22.8222 7.72581 22.6746 8.46272C22.5274 9.34705 22.6746 10.2314 23.4117 10.8209C23.7064 11.1157 24.0012 11.4105 24.2961 11.7052C24.8855 12.2949 25.0328 13.0316 25.0328 13.7685C24.8855 14.5054 24.7383 15.2423 24.5907 15.9791C24.4435 16.4212 24.2962 17.0108 24.2962 17.4531C23.8542 18.9269 22.3802 20.2533 20.7589 20.2533C19.4325 20.2533 18.5484 19.2216 18.6958 17.8952C18.6958 17.3055 18.9903 16.8634 18.9903 16.2739C18.9903 16.1265 19.1378 16.1265 19.2851 16.1265C19.2847 16.4213 19.5796 16.4213 19.8742 16.4213Z" fill="black" />
    <path d="M11.7675 13.4738C11.7675 13.7685 11.6201 14.0633 11.4728 14.5054C11.178 15.5372 11.0307 16.5687 10.7359 17.4531C10.5885 18.19 9.99899 18.4846 9.4095 18.4846C9.26208 18.4846 9.26208 18.4846 9.11466 18.4846C8.82001 18.4846 8.82001 18.3374 8.82001 18.19C9.26208 16.4213 9.70434 14.6528 10.1464 13.0316C10.1464 12.8842 10.2938 12.7368 10.5885 12.7368C10.7359 12.7368 11.0306 12.7368 11.178 12.7368C11.6201 12.7368 11.7675 13.0316 11.7675 13.4738Z" fill="white" />
    <path d="M11.1789 10.6735C10.7369 10.6735 10.5894 10.6735 10.7369 10.0838C11.1789 8.46258 11.6212 6.6941 11.916 5.07303C11.916 4.92562 12.0635 4.7782 12.2107 4.7782C12.3579 4.7782 12.5055 4.7782 12.8002 4.7782C13.2423 4.7782 13.5371 5.22027 13.3897 5.66253C13.095 6.98893 12.8002 8.31534 12.3579 9.64175C12.3579 10.3787 11.9159 10.6735 11.1789 10.6735Z" fill="white" />
    <path d="M0.716431 14.6533V14.5059C1.15851 12.8846 1.45334 11.411 1.89542 9.78974C2.04283 9.49508 2.04283 9.05282 2.19025 8.75799C2.19025 8.75799 2.19025 8.61075 2.33767 8.61075H2.48509C3.36942 9.49508 4.25375 10.3792 4.99066 11.411C5.13808 11.5582 4.99066 11.7056 4.99066 11.7056C3.81167 12.59 2.63251 13.4743 1.45352 14.3584C1.15851 14.5059 1.01109 14.5059 0.716431 14.6533C0.863849 14.6533 0.716431 14.6533 0.716431 14.6533Z" fill="#FFE000" />
  </svg>
);

IconProviderBetsoft.propTypes = {
  className: PropTypes.string
};

IconProviderBetsoft.defaultProps = {
  className: ''
};
