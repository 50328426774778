import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderVivo = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 34.13">
    <path fill="#0072bc" fillRule="evenodd" d="M96.49,13.63c1.13-.21,8.93-.09,10.72-.09a23.68,23.68,0,0,1,5.22.23c7.61,2,7.56,13.9-.7,15.31-1.22.22-8.89.1-10.75.1A24.1,24.1,0,0,1,95.77,29c-4.47-1.21-6.58-6-5.17-10.33a7.43,7.43,0,0,1,5.89-5Zm26.31,9.21a12.69,12.69,0,0,0-4.34-11.25A12.21,12.21,0,0,0,113.2,9c-1.94-.46-5.33-.26-7.55-.26H97.79a11.91,11.91,0,0,0-6.27,1.63c-7.72,4.44-8.15,15.32-1.78,20.77,4,3.38,7.78,2.89,12.8,2.89h7.86a12.28,12.28,0,0,0,12.4-11.17ZM48.36,12.16a13.37,13.37,0,0,0,1.92,3.24l6.22,9.14c1.21,1.79,2.89,4.37,4.14,6.09a7.78,7.78,0,0,0,6.75,3.49c3.12-.14,4.85-2.11,6.19-4s2.71-4,4.14-6.09L85,13.3a2.6,2.6,0,0,0-2.51-4.14c-1.34.26-1.92,1.46-2.61,2.51l-4.14,6.09c-1.37,2-2.75,4.1-4.14,6.09-.71,1-1.35,2-2.06,3-.58.84-1.51,2.38-3.07,2-1.16-.28-2-1.72-2.54-2.58-.7-1-1.34-2-2.07-3.05-1.38-2-2.7-3.95-4.14-6.1s-2.69-4-4.14-6.09c-.65-.94-1.37-2.24-3.13-1.93a2.59,2.59,0,0,0-2.08,3ZM0,12.09c.12.9,1.44,2.57,1.9,3.25l8.28,12.18c2,3.08,4,6.77,8.76,6.6,4.35-.15,6.14-3.8,8.36-7.08l6.2-9.13c.66-1,1.37-2,2.08-3s1.76-2.25,1.58-3.51a2.59,2.59,0,0,0-2.93-2.22c-1.36.19-1.93,1.35-2.66,2.45-1.41,2.13-2.7,4-4.13,6.1s-2.73,4.07-4.15,6.09c-.72,1-1.35,2-2.07,3-.6.88-1.44,2.38-3,2.09-1.25-.22-1.95-1.64-2.61-2.58-3.43-4.88-7-10.25-10.35-15.23-.69-1-1.39-2.23-3.13-2A2.6,2.6,0,0,0,0,12.09ZM42.31,9.17c-2.54.53-2.13,2.71-2.13,5.37V29.67c0,1.26-.18,2.41.44,3.33a2.6,2.6,0,0,0,4.61-.67c.24-.79.11-9.95.11-11.19V13.57c0-1.27.18-2.38-.43-3.32a2.54,2.54,0,0,0-2.6-1.08ZM42.43.08a3.16,3.16,0,0,0-1,.86L39.55,2.79C38.34,4,39.33,4.49,40.85,6l.93.92C43,8.15,43.43,7.26,45,5.67c2.2-2.2,2.13-1.74-1.3-5.14a1.13,1.13,0,0,0-1.3-.45Z" />
  </svg>
);

IconProviderVivo.propTypes = {
  className: PropTypes.string
};

IconProviderVivo.defaultProps = {
  className: ''
};
