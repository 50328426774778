import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderHigh5 = ({ className }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.7938 25.6149C21.7131 25.6457 21.6372 25.6878 21.5684 25.7401L27.3082 12.7215L3.03049 12.7062V20.7298H5.61701V26.5975L5.62027 26.5943L15.086 26.6003L15.0833 26.5975L15.0942 20.7304H18.1652V35.0381H15.0909L15.0844 29.6441H5.61592V29.7154H5.58489V35.0338L3.03049 35.0381V35.061H3.02178L3 43.0536L13.1261 43.0601C13.3172 43.316 13.5328 43.5893 13.7729 43.8691C14.9666 45.3947 16.5785 46.5401 18.4119 47.1655C19.3363 47.4805 20.3065 47.64 21.2831 47.6376C21.3566 47.6376 21.4296 47.6376 21.5009 47.6348C21.6032 47.6348 21.7056 47.6381 21.8085 47.6381C28.6964 47.6381 33.1688 42.832 33.1688 36.5957C33.1672 28.2139 26.0054 25.6018 21.7938 25.6149Z" fill="#EFB433" />
    <path d="M28.4884 16.6211L26.7593 20.8943L26.5584 21.3908C26.5437 21.4125 26.5361 21.4267 26.5421 21.4272V21.4327L26.5492 21.4278C28.9571 21.6292 37.2252 23.4693 37.6449 33.1529C37.8082 36.8974 36.8778 40.6451 34.7878 43.0253H57L56.9929 34.9679L47.568 34.9271C46.607 34.9109 45.6551 34.7384 44.7495 34.4164C43.9112 34.0441 43.1444 33.5283 42.4837 32.8921C41.8537 32.2175 41.345 31.4391 40.98 30.5914C40.6624 29.671 40.4927 28.7062 40.477 27.7327C40.4912 26.7784 40.6588 25.8326 40.9735 24.9316C41.3536 24.1331 41.8763 23.4106 42.5158 22.7997C43.1764 22.2147 43.9152 21.7244 44.7109 21.3429C45.5983 21.0163 46.5316 20.8311 47.4765 20.7941L56.9444 20.8039L56.853 20.695L56.9455 20.8039V12.7237H56.9254V12.7139L41.1237 12.7237L39.092 16.6081L28.4884 16.6211Z" fill="#EFB433" />
    <path d="M43.6193 27.8932C43.6193 30.8059 46.57 31.9089 48.39 31.9089L54.9104 31.9154L54.881 31.8909V31.8865L54.9104 31.9078V29.6757H48.0748L47.416 29.6729V26.6787H56.9977V23.8531L48.3388 23.876C46.1513 23.8738 43.6193 24.6638 43.6193 27.8932Z" fill="#EFB433" />
  </svg>
);

IconProviderHigh5.propTypes = {
  className: PropTypes.string,
};

IconProviderHigh5.defaultProps = {
  className: '',
};
