import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderAviatrix = ({ className }) => (
  <svg className={classNames('producers-svg-icon producer-aviatrix', className)} width="24" height="24" viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#icon-provider-aviatrix-e)">
      <path fillRule="evenodd" clipRule="evenodd" d="M129.316 89.7297C128.942 89.5297 128.552 89.3425 128.157 89.1681L131.703 79.6191C139.378 60.2979 150.374 36.8207 163.004 22.43C169.018 15.7568 176.596 10.6636 185.061 7.60181C187.608 7.18727 190.193 6.97976 192.778 6.98132H223.652C224.426 6.94246 225.19 7.14253 225.841 7.55405C226.204 7.85566 226.536 8.19134 226.835 8.5564C226.979 8.76234 227.102 8.98068 227.203 9.20873C227.224 9.2618 227.256 9.30952 227.294 9.34817C227.337 9.38682 227.39 9.41534 227.443 9.43146C228.512 11.4202 228.159 13.7908 225.841 16.4319L194.327 52.341L142.881 110.842V110.54C142.785 107.607 142.144 104.716 140.996 102.013C139.613 98.7847 137.519 95.9042 134.876 93.5798C133.204 92.0508 131.335 90.7558 129.316 89.7297ZM151.479 52.691C152.425 48.9914 153.653 45.3691 155.159 41.8562C158.166 34.9062 162.241 28.4648 167.239 22.7641C163.896 29.3622 161.028 36.1891 158.657 43.1927C157.776 45.9134 157.06 48.6817 156.419 51.4819L170.577 35.9059L155.223 57.4641C154.807 59.7764 154.454 62.0994 154.166 64.4328L167.143 50.0182L170.802 45.8497L167.8 50.034L153.605 69.715L153.493 69.6195L152.905 75.506C152.905 75.7295 152.868 75.9524 152.831 76.1716C152.809 76.3119 152.788 76.4516 152.777 76.5882L172.083 54.7276L152.088 81.9338C151.88 83.5248 151.64 85.1157 151.335 86.7067L151.24 87.2281C151.113 87.9295 150.985 88.6308 150.844 89.3318L172.227 64.8461L164.233 75.554L149.081 95.9986C147.826 100.163 146.069 104.159 143.842 107.899C144.943 103.935 145.802 99.9074 146.417 95.8391L146.513 95.2188C146.582 94.807 146.652 94.3896 146.705 93.9777C147.068 91.4001 147.373 88.807 147.581 86.2139C147.656 85.1648 147.73 84.1157 147.81 83.064C148.003 80.4314 148.195 77.7823 148.334 75.0767C148.526 71.29 148.911 67.6147 149.354 63.9395C149.53 62.7339 149.722 61.5123 149.913 60.2908L149.915 60.2802C150.363 57.7186 150.86 55.1889 151.479 52.691Z" fill="url(#icon-provider-aviatrix-a)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M92.2242 109.358L72.838 70.5672C72.571 69.7714 72.4375 69.2407 72.4375 68.9757C72.4375 67.6498 72.9021 66.4562 73.8367 65.3954C74.8995 64.2023 76.1652 63.6055 77.6285 63.6055H102.403C104.935 63.6055 106.734 64.7986 107.797 67.1858L120.326 91.1408C130.933 93.4277 138.879 102.824 138.879 114.068C138.879 127.024 128.332 137.528 115.317 137.528C102.302 137.528 91.7489 127.024 91.7489 114.068C91.7489 112.455 91.9145 110.88 92.2242 109.358ZM124.3 114.068C124.3 119.008 120.278 123.013 115.317 123.013C110.355 123.013 106.329 119.008 106.329 114.068C106.329 109.128 110.355 105.123 115.317 105.123C120.278 105.123 124.3 109.128 124.3 114.068Z" fill="url(#icon-provider-aviatrix-b)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M102.173 139.139C102.547 139.339 102.932 139.526 103.332 139.701L99.7861 149.25C92.1118 168.571 81.1156 192.048 68.4852 206.439C62.4717 213.112 54.8934 218.205 46.4287 221.267C43.8759 221.682 41.2964 221.889 38.7116 221.887H7.83251C7.06347 221.926 6.29979 221.726 5.6429 221.315C5.28508 221.013 4.95398 220.677 4.65491 220.313C4.51071 220.107 4.38785 219.888 4.28638 219.66C4.26502 219.607 4.23301 219.559 4.19563 219.521C4.15291 219.482 4.09948 219.453 4.04608 219.437C2.97797 217.449 3.33044 215.078 5.6429 212.437L88.6083 118.026V118.329C88.7045 121.262 89.3453 124.153 90.4936 126.856C91.8768 130.084 93.9702 132.965 96.6138 135.289C98.2801 136.818 100.155 138.113 102.173 139.139ZM80.0101 176.178C79.0648 179.877 77.8311 183.5 76.3304 187.012C73.3237 193.963 69.2488 200.404 64.2501 206.105C67.5933 199.506 70.4611 192.68 72.8323 185.676C73.7135 182.956 74.4292 180.187 75.07 177.387L60.9122 192.963L76.2663 171.405C76.6829 169.093 77.0354 166.769 77.3237 164.436L64.3463 178.851L60.6879 183.019L63.6893 178.835L77.8845 159.154L77.9967 159.249L78.5841 153.363C78.5841 153.165 78.6135 152.967 78.6425 152.772L78.6535 152.697C78.6802 152.557 78.7016 152.417 78.7123 152.281L59.4062 174.141L79.4012 146.935C79.6095 145.344 79.8499 143.753 80.1543 142.162C80.3091 141.287 80.4693 140.412 80.6455 139.537L59.262 164.023L67.2568 153.315L82.4026 132.87C83.6576 128.706 85.4201 124.71 87.6471 120.97C86.5416 124.934 85.687 128.961 85.0729 133.03L84.9768 133.65L84.975 133.661C84.9062 134.071 84.8374 134.481 84.7845 134.891C84.4213 137.469 84.117 140.062 83.9087 142.655C83.8339 143.704 83.7591 144.753 83.679 145.805C83.4868 148.437 83.2945 151.087 83.1556 153.792C82.9634 157.579 82.5788 161.254 82.1356 164.929C81.9596 166.136 81.7676 167.358 81.5757 168.58C81.1271 171.141 80.6296 173.68 80.0101 176.178Z" fill="url(#icon-provider-aviatrix-c)" />
      <path d="M139.411 128.77C134.465 136.811 125.557 142.176 115.394 142.176C113.023 142.176 110.727 141.885 108.526 141.337L120.115 163.49C121.178 165.877 122.978 167.07 125.509 167.07H150.284C151.747 167.07 153.013 166.474 154.081 165.28C155.011 164.219 155.475 163.026 155.475 161.7C155.475 161.435 155.342 160.904 155.08 160.109L139.411 128.77Z" fill="url(#icon-provider-aviatrix-d)" />
    </g>
  </svg>
);

IconProviderAviatrix.propTypes = {
  className: PropTypes.string
};

IconProviderAviatrix.defaultProps = {
  className: ''
};
