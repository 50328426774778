import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderHigh5Big = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="139"
    height="30"
    viewBox="0 0 139 30"
    fill="none"
  >
    <g clipPath="url(#iconProviderHigh5big-1)">
      <path
        d="M47.5736 11.086C47.5068 11.1124 47.4439 11.1486 47.3869 11.1935L52.1426 0.0130983L32.0275 0V6.89068H34.1705V11.9299L34.1732 11.9271L42.016 11.9322L42.0137 11.9299L42.0228 6.89115H44.5673V19.1787H42.02L42.0146 14.5462H34.1696V14.6075H34.1439V19.1749L32.0275 19.1787V19.1983H32.0202L32.0022 26.0623L40.3921 26.068C40.5505 26.2877 40.7291 26.5224 40.928 26.7627C41.9171 28.0729 43.2525 29.0566 44.7716 29.5937C45.5375 29.8642 46.3414 30.0012 47.1505 29.9991C47.2114 29.9991 47.2719 29.9991 47.331 29.9967C47.4158 29.9967 47.5006 29.9995 47.5858 29.9995C53.2928 29.9995 56.9983 25.8721 56.9983 20.5163C56.997 13.318 51.0631 11.0747 47.5736 11.086Z"
        fill="#EFB433"
      />
      <path
        d="M53.1205 3.3621L51.6878 7.03186L51.5214 7.45825C51.5092 7.47695 51.5029 7.48912 51.5079 7.48958V7.49425L51.5137 7.49004C53.5088 7.66303 60.3593 9.24334 60.707 17.5596C60.8423 20.7753 60.0715 23.9939 58.3398 26.038H76.7435L76.7377 19.1183L68.9287 19.0833C68.1325 19.0693 67.3438 18.9212 66.5935 18.6447C65.8989 18.325 65.2636 17.882 64.7162 17.3356C64.1942 16.7563 63.7727 16.0878 63.4703 15.3598C63.2072 14.5693 63.0665 13.7407 63.0535 12.9047C63.0653 12.0852 63.2042 11.2729 63.4649 10.4992C63.7799 9.81338 64.2129 9.19292 64.7428 8.66826C65.2901 8.16586 65.9022 7.74478 66.5615 7.41711C67.2968 7.13668 68.07 6.97764 68.8529 6.94582L76.6975 6.95424L76.6217 6.86074L76.6984 6.95424V0.0149491H76.6817V0.00653076L63.5894 0.0149491L61.906 3.35087L53.1205 3.3621Z"
        fill="#EFB433"
      />
      <path
        d="M65.6571 13.0426C65.6571 15.544 68.1019 16.4913 69.6098 16.4913L75.0123 16.4969L74.988 16.4758V16.4721L75.0123 16.4903V14.5734H69.3487L68.8029 14.5711V11.9996H76.7417V9.573L69.5674 9.59263C67.755 9.59076 65.6571 10.2692 65.6571 13.0426Z"
        fill="#EFB433"
      />
      <path d="M136.543 7.79021H136.949V9.01845H137.123V7.79021H137.529V7.63873H136.543V7.79021Z" fill="#EFB433" />
      <path
        d="M138.917 7.63873H138.699L138.461 8.30592C138.402 8.47985 138.355 8.6332 138.321 8.77065H138.315C138.282 8.62712 138.236 8.47564 138.18 8.30592L137.954 7.63873H137.735L137.643 9.01845H137.806L137.842 8.42654C137.854 8.21989 137.864 7.98845 137.867 7.81873H137.871C137.909 7.98237 137.962 8.1605 138.026 8.355L138.241 9.00956H138.371L138.606 8.34005C138.673 8.14976 138.728 7.97816 138.774 7.81639H138.78C138.78 7.98798 138.79 8.21942 138.8 8.41205L138.833 9.01985H139.001L138.917 7.63873Z"
        fill="#EFB433"
      />
      <path d="M16.1939 7.46527H14.2259V19.0665V19.0721H16.1935V19.0665L16.1939 7.46527Z" fill="#EFB433" />
      <path
        d="M31.0279 19.0781L31.0247 14.4438V12.6728H23.6975V14.5219V14.528H29.0554V17.0527H23.8464C23.3573 17.0709 22.8696 16.9887 22.4113 16.8109C21.9529 16.6331 21.533 16.3631 21.1756 16.0166C20.962 15.8072 20.7762 15.5693 20.623 15.3092C20.2841 14.7069 20.1132 14.0188 20.1295 13.3217C20.112 12.8128 20.1962 12.3056 20.3769 11.832C20.5575 11.3584 20.8307 10.9287 21.1792 10.5698C21.5319 10.2142 21.9503 9.93607 22.4092 9.75216C22.8682 9.56826 23.3582 9.48233 23.85 9.49954L30.1312 9.49346H30.2327L30.2354 9.49627V9.49346V7.47461H30.2295H30.0978L23.8559 7.48069C20.1922 7.48069 18.9694 9.85207 18.6144 10.7067C18.5412 10.881 18.477 11.0593 18.4222 11.2407C18.0269 12.6385 18.0753 14.131 18.5603 15.4981C18.8361 16.2091 19.2468 16.8555 19.768 17.3989C20.2892 17.9424 20.9104 18.3719 21.5946 18.662C22.3123 18.9498 23.0765 19.0927 23.8459 19.0828L30.7924 19.0758H31.0234L31.0279 19.0781Z"
        fill="#EFB433"
      />
      <path
        d="M0 7.47978V19.0875L1.61439 19.0828V19.0875H1.97164V14.2634H10.0499V19.0875H12.0179V7.47931H10.049V12.2366H1.97479V7.47931H0V7.47978Z"
        fill="#EFB433"
      />
      <path
        d="M107.513 7.39935C107.489 7.39935 107.465 7.39935 107.44 7.39935C106.905 7.42741 106.473 8.25964 106.367 8.47985C106.359 8.49575 106.351 8.51071 106.342 8.52661L105.638 9.94514L102.59 16.0611L99.4622 9.76233L98.8533 8.52988C98.847 8.51772 98.8407 8.50604 98.8343 8.49342C98.6801 8.15843 98.4599 7.8606 98.188 7.6191L98.1807 7.61302L98.1284 7.57423C98.114 7.56394 98.0989 7.55411 98.0833 7.54476L98.0716 7.53728C98.0176 7.50297 97.9605 7.4743 97.9011 7.45172C97.8835 7.44517 97.8659 7.43957 97.8483 7.43442L97.8275 7.42882C97.8127 7.42461 97.7978 7.42086 97.7824 7.41806L97.7468 7.41198C97.7355 7.41198 97.7243 7.40824 97.713 7.4073C97.6891 7.4073 97.6647 7.40311 97.6403 7.40311C97.6043 7.40311 97.5695 7.4031 97.5357 7.40824H97.5276C97.2833 7.42883 97.0542 7.53966 96.8821 7.72056C96.8753 7.72664 96.8686 7.73225 96.8623 7.7388C96.829 7.77336 96.7989 7.81091 96.772 7.851C96.5255 8.22714 96.361 8.6544 96.2898 9.10309C96.2601 9.2485 93.9213 19.0852 93.9213 19.0852H93.9285L93.9213 19.0908H96.1315L97.7378 11.0443L101.49 18.4516C101.584 18.6633 101.73 18.8456 101.914 18.9804C102.098 19.1153 102.312 19.198 102.536 19.2203H102.557H102.587C102.834 19.1979 103.071 19.1071 103.272 18.9573C103.474 18.8075 103.633 18.6042 103.732 18.3689L107.439 11.0364V11.0425L109.062 19.0889L111.258 19.0833L108.767 8.52801C108.717 8.21803 108.565 7.93576 108.336 7.72976C108.107 7.52377 107.816 7.40693 107.513 7.39935Z"
        fill="#EFB433"
      />
      <path d="M115.531 14.2681H121.922V12.2576H115.529L115.531 14.2681Z" fill="#EFB433" />
      <path
        d="M112.762 19.1001H122.43V17.0897H114.713V9.60477H122.429V7.60181L112.76 7.59479L112.762 19.1001Z"
        fill="#EFB433"
      />
      <path
        d="M86.8417 8.67807C86.7665 8.54585 86.6803 8.4207 86.5841 8.30404C86.443 8.09395 86.2599 7.91777 86.0472 7.78749C85.8346 7.6572 85.5974 7.57585 85.3518 7.54895H85.3477C84.5119 7.54895 83.9733 8.3723 83.8317 8.61776C83.8186 8.63833 83.8055 8.65937 83.7938 8.68041L82.3089 11.2519L77.7833 19.073H80.219L85.2665 10.5553L87.2161 13.8711L84.2336 13.8795L83.3657 15.3415L83.0536 15.8605H83.0576L83.054 15.8666L88.3798 15.8577L90.264 19.073L92.7715 19.0898L92.7679 19.0838H92.7715L86.8417 8.67807Z"
        fill="#EFB433"
      />
      <path
        d="M136.379 15.4664C136.379 15.428 136.379 15.3897 136.377 15.3523C136.377 15.3289 136.375 15.3055 136.373 15.2822C136.367 14.5176 136.104 13.7792 135.629 13.1943C135.154 12.6095 134.497 12.2148 133.771 12.0785C133.37 11.9697 132.957 11.9161 132.542 11.9191H127.26C126.919 11.9292 126.585 11.8227 126.308 11.6161C126.18 11.518 126.077 11.3887 126.009 11.2397C125.94 11.0906 125.908 10.9264 125.915 10.7615C125.91 10.5962 125.943 10.432 126.011 10.2825C126.079 10.1331 126.181 10.0029 126.308 9.90258C126.581 9.68617 126.917 9.57329 127.26 9.58278H135.708V7.57748H127.213C126.345 7.54606 125.494 7.83907 124.816 8.40316C124.504 8.65943 124.249 8.98279 124.068 9.35099C123.888 9.7192 123.787 10.1235 123.772 10.5361V10.5632C123.772 10.602 123.77 10.6418 123.769 10.681V10.7395V10.7456C123.755 11.1964 123.843 11.6445 124.025 12.054C124.207 12.4636 124.48 12.8234 124.82 13.1048C125.515 13.6693 126.381 13.9616 127.264 13.9305L132.653 13.9277C132.721 13.9277 132.786 13.9338 132.851 13.9398C133.311 13.9894 134.611 14.2479 134.611 15.5795C134.575 15.8747 134.46 16.1536 134.279 16.3845C134.098 16.6153 133.858 16.7889 133.586 16.8854C132.91 17.1593 131.927 17.0771 130.348 17.0771H123.998V19.0823V19.088L124.003 19.0823H129.781H132.497C132.573 19.0823 132.646 19.0824 132.717 19.0786C132.749 19.0786 132.779 19.0758 132.808 19.0744L132.92 19.0683L132.961 19.0651C133.57 19.035 134.165 18.8686 134.706 18.5774C135.32 18.2235 135.807 17.6732 136.095 17.0088L136.1 16.9966C136.121 16.9499 136.141 16.897 136.16 16.8451C136.169 16.8194 136.178 16.7928 136.187 16.7666C136.196 16.7404 136.204 16.7175 136.212 16.6923C136.22 16.667 136.23 16.6343 136.238 16.6048C136.246 16.5754 136.252 16.5525 136.259 16.5258C136.266 16.4992 136.273 16.4739 136.279 16.4477C136.287 16.4122 136.295 16.3762 136.303 16.3393L136.313 16.286C136.324 16.2308 136.333 16.1752 136.341 16.1176C136.367 15.9294 136.381 15.7394 136.381 15.5491V15.5276C136.381 15.5056 136.38 15.486 136.379 15.4664Z"
        fill="#EFB433"
      />
    </g>
  </svg>
);

IconProviderHigh5Big.propTypes = {
  className: PropTypes.string,
};

IconProviderHigh5Big.defaultProps = {
  className: '',
};

