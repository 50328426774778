import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderMascot = ({ className, isDarkTheme }) => (
  <svg
    className={className}
    width="24"
    height="32"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 70.54"
    fill={isDarkTheme ? 'white' : 'black'}
  >
    <path d="M31.17,23.64A14.45,14.45,0,0,0,31.84,28a16.37,16.37,0,0,1,3.81-4.31,10,10,0,0,1,6.48-9.33,3.54,3.54,0,0,0,4.23,1.84,3.46,3.46,0,0,0,2.38-2.59,3.51,3.51,0,1,0-2.09-6.7,3.4,3.4,0,0,0-3.43-.75A3.53,3.53,0,0,0,40.79,10,14.42,14.42,0,0,0,31.17,23.64Z" />
    <path d="M4.48,23.64V4.44h51v19.2A10,10,0,0,1,39,31.17a8.62,8.62,0,0,0-1.72,4.31A14.39,14.39,0,0,0,60,23.68V0H0V23.64A14.66,14.66,0,0,0,.67,28,16.19,16.19,0,0,1,4.48,23.64Z" />
    <path d="M43.18,53.85a3.4,3.4,0,0,0,3.43-.75,3.51,3.51,0,0,0,2.09-6.7,3.47,3.47,0,0,0-2.38-2.59,3.52,3.52,0,0,0-4.23,1.84,10,10,0,1,1,10-16.86,8.62,8.62,0,0,0,1.72-4.31,14.39,14.39,0,0,0-22.64,11.8,14.58,14.58,0,0,0,9.58,13.64A3.52,3.52,0,0,0,43.18,53.85Z" />
    <path d="M28.83,36.28A14.45,14.45,0,0,0,28.16,32a16.52,16.52,0,0,1-3.81,4.31,10,10,0,0,1-6.48,9.33,3.53,3.53,0,0,0-4.23-1.84,3.48,3.48,0,0,0-2.39,2.59,3.51,3.51,0,0,0,2.1,6.7,3.4,3.4,0,0,0,3.43.75,3.52,3.52,0,0,0,2.42-3.94A14.44,14.44,0,0,0,28.83,36.28Z" />
    <path d="M19.2,10a3.47,3.47,0,0,0-2.42-3.93,3.58,3.58,0,0,0-3.43.75,3.51,3.51,0,1,0-2.1,6.7,3.53,3.53,0,0,0,6.62.75,10,10,0,1,1-10,16.86,7.87,7.87,0,0,0-1.72,4.31A14.43,14.43,0,1,0,19.2,10Z" />
    <path d="M59.33,32a16.19,16.19,0,0,1-3.81,4.31v19.2h-51V36.32A10,10,0,0,1,21,28.79a8.62,8.62,0,0,0,1.72-4.31A14.39,14.39,0,0,0,0,36.28V70.54H60V36.32A14.66,14.66,0,0,0,59.33,32ZM11,66.74l0-4.31-2.3,4.35H8.16L5.82,62.47l-.05,4.27H4.48l.21-7.58h.58l3.14,5.49,3.05-5.49h.59l.21,7.58Zm10.5,0H20.17v-1.8h-3.6v1.8H15.23V62.43c0-2.3,1.13-3.35,3.1-3.35s3.13,1.05,3.13,3.35Zm4.9.12a4.85,4.85,0,0,1-2.47-.71l.46-1.21a3.84,3.84,0,0,0,2,.67c.88,0,1.3-.42,1.3-.92s-.29-.8-1.63-1.3-2-1.26-2-2.13a2.28,2.28,0,0,1,2.51-2.18,4.3,4.3,0,0,1,2.09.5l-.42,1.17a3.53,3.53,0,0,0-1.67-.5c-.79,0-1.17.42-1.17,1s.42.8,1.46,1.18c1.68.67,2.18,1.29,2.18,2.3C29.08,66,28.07,66.86,26.36,66.86Zm9,0A3.9,3.9,0,0,1,31.17,63a4,4,0,0,1,4.06-3.89,5.35,5.35,0,0,1,2.64.63l-.46,1.13a5.2,5.2,0,0,0-2-.5A2.58,2.58,0,0,0,32.59,63a2.63,2.63,0,0,0,2.72,2.64,5,5,0,0,0,2.18-.5l.42,1.12A4.9,4.9,0,0,1,35.4,66.86Zm8.32,0a3.86,3.86,0,0,1-4-3.89,3.91,3.91,0,0,1,4-3.89,3.81,3.81,0,0,1,4,3.89A3.86,3.86,0,0,1,43.72,66.86Zm11.8-6.44H53.05v6.32H51.72V60.42H49.2V59.21h6.32Z" />
    <path d="M18.33,60.34c-1-.05-1.8.58-1.8,2.09v1.46h3.6V62.47C20.13,60.88,19.37,60.34,18.33,60.34Z" />
    <path d="M43.72,60.25A2.63,2.63,0,0,0,41.13,63a2.6,2.6,0,1,0,2.59-2.72Z" />
  </svg>
);

IconProviderMascot.propTypes = {
  className: PropTypes.string,
  isDarkTheme: PropTypes.bool,
};

IconProviderMascot.defaultProps = {
  className: '',
  isDarkTheme: false,
};
