import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderElysium = ({ className }) => (
  <svg className={className} fill="none" width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.4273 17.6065H45.9705V10H18.4935V17.1384L26.4273 21.6877V17.6065Z" fill="#000" />
    <path d="M26.4273 38.3123L18.4935 42.8615V50H46V42.3861H26.4273V38.3123Z" fill="#000" />
    <path d="M14 39.6361L26.3094 32.2271H26.2726L30.0958 30.011L25.639 27.4365L14 20.437C14 20.437 19.3481 23.6551 19.3481 28.87V31.2105C19.3481 36.2278 14 39.6434 14 39.6434V39.6361Z" fill="#000" />
  </svg>
);

IconProviderElysium.propTypes = {
  className: PropTypes.string,
};

IconProviderElysium.defaultProps = {
  className: '',
};
