import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderGamomat = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-656 -894) translate(654 892)">
          <path d="M0 0H24V24H0z" />
          <g fill="#A6CE3D" mask="url(#icon-provider-gamotat-b)">
            <path d="M19.916 8.191h-9.792v4.382h3.837c-.757 1.36-2.17 2.27-3.837 2.27-2.422 0-4.391-1.967-4.391-4.386.001-1.162.464-2.276 1.287-3.097.823-.822 1.94-1.284 3.104-1.285.656 0 1.312.152 1.867.403l2.575-4.987C13.203.838 11.74.435 10.124.435 4.62.484.13 4.915.13 10.457c0 5.543 4.491 9.978 9.994 9.978 5.551 0 9.993-4.487 9.993-9.978.051-.754-.05-1.511-.2-2.266z" transform="translate(1.87 1.565)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconProviderGamomat.propTypes = {
  className: PropTypes.string,
};
IconProviderGamomat.defaultProps = {
  className: null,
};
