import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderTomHornGamingBig = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} xmlns="http://www.w3.org/2000/svg" width="110" height="35" viewBox="0 0 110 35">
    <g fill="none" fillRule="evenodd">
      <path fill="#000" fillRule="nonzero" d="M43.044 19.56c0 1.038.816 1.855 1.83 1.855.223 0 .446-.05.99-.248l.766 1.905c-.742.297-1.236.396-1.756.396a3.9 3.9 0 0 1-3.908-3.908V6.92h2.078v3.166h3.586v2.053h-3.586v7.42zM47.999 19.56v-6.333a3.9 3.9 0 0 1 3.908-3.908 3.9 3.9 0 0 1 3.908 3.908v6.333a3.9 3.9 0 0 1-3.908 3.908 3.9 3.9 0 0 1-3.908-3.908m3.908-8.188c-.99 0-1.83.841-1.83 1.855v6.333c0 1.013.84 1.855 1.83 1.855 1.014 0 1.83-.817 1.83-1.855v-6.333a1.832 1.832 0 0 0-1.83-1.855M67.571 12.807c0-.767-.643-1.435-1.434-1.435-.767 0-1.435.668-1.435 1.435v10.388h-2.053V12.807c0-.767-.643-1.435-1.434-1.435-.767 0-1.435.668-1.435 1.435v10.388h-2.053V12.807a3.483 3.483 0 0 1 3.488-3.488c.964 0 1.83.371 2.473 1.014.618-.643 1.484-1.014 2.449-1.014a3.49 3.49 0 0 1 3.512 3.488v10.388h-2.078V12.807zM80.706 13.227c0-1.014-.816-1.83-1.83-1.83-.99 0-1.83.816-1.83 1.83v9.968h-2.079V4.348h2.078v5.441a3.8 3.8 0 0 1 1.83-.47 3.9 3.9 0 0 1 3.908 3.908v9.968h-2.077v-9.968zM84.626 19.56v-6.333a3.9 3.9 0 0 1 3.908-3.908 3.9 3.9 0 0 1 3.908 3.908v6.333a3.9 3.9 0 0 1-3.908 3.908 3.9 3.9 0 0 1-3.908-3.908m3.908-8.188c-.99 0-1.83.841-1.83 1.855v6.333c0 1.013.84 1.855 1.83 1.855 1.014 0 1.83-.817 1.83-1.855v-6.333a1.832 1.832 0 0 0-1.83-1.855M98.188 11.372c-.989 0-1.83.841-1.83 1.855v9.968h-2.053v-9.968a3.88 3.88 0 0 1 3.883-3.908c1.36 0 2.647.717 3.488 2.152l-1.756 1.04c-.495-.793-1.064-1.139-1.732-1.139M107.922 13.227c0-1.014-.841-1.855-1.83-1.855-1.014 0-1.83.817-1.83 1.855v9.968h-2.079v-9.968a3.9 3.9 0 0 1 3.909-3.908 3.88 3.88 0 0 1 3.883 3.908v9.968h-2.053v-9.968z" />
      <path fill="#21B3E8" fillRule="nonzero" d="M58.267 33.049v-3.606c0-.843.677-1.52 1.52-1.52.608 0 1.156.365 1.442 1.016l-.669.287c-.165-.391-.46-.574-.773-.574-.443 0-.8.357-.8.791v3.606c0 .434.365.799.8.799.434 0 .799-.365.799-.8v-.781h-1.155v-.73h1.876v1.512c0 .842-.677 1.52-1.52 1.52s-1.52-.678-1.52-1.52M66.86 32.45h-1.59v2.032h-.722V29.46c0-.842.678-1.52 1.52-1.52.843 0 1.521.678 1.521 1.52v5.022h-.73V32.45zm-.791-3.789c-.435 0-.8.356-.8.8v2.267h1.59V29.46c0-.443-.356-.799-.79-.799zM74.088 30.208L73.08 33.11h-.4l-.999-2.902v4.274h-.721v-6.481h.73l1.19 3.475 1.2-3.475h.729v6.481h-.721zM78.71 28.001h1v6.481h-1zM84 30.13v4.352h-.73v-6.481h.713l1.607 4.37V28h.721v6.481H85.6zM89.647 33.049v-3.606c0-.843.678-1.52 1.52-1.52.61 0 1.157.365 1.443 1.016l-.67.287c-.164-.391-.46-.574-.772-.574-.444 0-.8.357-.8.791v3.606c0 .434.365.799.8.799.434 0 .8-.365.8-.8v-.781h-1.156v-.73h1.876v1.512c0 .842-.678 1.52-1.52 1.52-.843 0-1.52-.678-1.52-1.52" />
      <path d="M0 0h109.975v34.569H0z" />
      <g>
        <path fill="url(#icon-provider-tom-horn-gaming-big-b)" fillRule="nonzero" d="M21.61-14.566L-14.902 8.249 7.4 43.942l36.512-22.815z" mask="url(#icon-provider-tom-horn-gaming-big-c)" />
      </g>
      <g transform="translate(3.88 5.02)">
        <path fill="url(#icon-provider-tom-horn-gaming-big-e)" fillRule="nonzero" d="M8.976 45.71l37.276-23.292-23.068-36.916-37.276 23.293z" mask="url(#icon-provider-tom-horn-gaming-big-f)" />
      </g>
    </g>
  </svg>

);

IconProviderTomHornGamingBig.propTypes = {
  className: PropTypes.string,
};
IconProviderTomHornGamingBig.defaultProps = {
  className: null,
};
