import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderRedSeven = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.4706 6.72595C20.4376 6.52783 20.1639 4.10361 20.1455 3.77259C20.0905 3.23237 19.8461 3.25452 19.5407 3.27667C18.5289 3.27421 11.2778 3.3099 10.2722 3.27667C9.46324 3.2496 6.89104 3.02318 6.66131 3.00103C6.43159 2.97888 6.34361 3.32097 6.29962 3.48587C6.29962 3.48587 5.15098 7.64027 5.03001 8.05989C4.90904 8.47828 5.18275 8.61118 5.33672 8.67763C5.33672 8.67763 8.35738 9.81222 8.6091 9.88974C8.6091 9.88974 8.81683 9.97834 9.07955 9.94512C9.34227 9.91189 9.45102 9.47135 9.47302 9.43812C9.49501 9.4049 9.63676 8.95328 9.69175 8.8536C10.0205 8.23586 10.4139 8.26908 10.6766 8.24693L14.4085 8.01559C14.4085 8.01559 14.4415 8.12634 14.4525 8.20263C13.96 8.74285 12.4607 10.7598 12.209 11.1449C11.2681 12.5121 9.58299 15.0458 8.95858 17.5697C8.33538 19.7626 8.43314 21.206 8.41114 21.5814C8.38915 21.9555 8.69586 21.9998 8.8706 21.9998C8.8706 21.9998 14.0248 21.9444 14.2325 21.9333C14.4402 21.9222 14.5062 21.7463 14.5062 21.6244C14.5062 21.5026 14.5172 20.9747 14.5612 19.9607C14.6162 18.3302 14.8899 17.1279 15.0097 16.6542C15.6549 14.1414 16.7937 12.4001 17.2422 11.6285C17.6906 10.857 18.4678 9.79868 20.1748 7.63903C20.3716 7.40892 20.5256 7.2108 20.4706 6.72595ZM7.88571 7.72887L7.06456 5.96546L8.07511 4.47771L9.01234 6.08729L7.88571 7.72887Z" fill="#DA2128" />
  </svg>
);

IconProviderRedSeven.propTypes = {
  className: PropTypes.string,
};
IconProviderRedSeven.defaultProps = {
  className: null,
};
