import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderTurboGames = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="32"
    height="32"
    viewBox="0 0 107 107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="surface1">
      <path d="M52.5 105C81.4949 105 105 81.4949 105 52.5C105 23.5051 81.4949 0 52.5 0C23.5051 0 0 23.5051 0 52.5C0 81.4949 23.5051 105 52.5 105Z" fill="black" />
      <path d="M91.5 68.8001L74.7 52.0001L91.5 35.2001C93.6 33.1001 93.6 29.8001 91.5 27.7001L76.6 12.8001C74.5 10.7001 71.2 10.7001 69.1 12.8001L52.3 29.6001L35.5 12.8001C33.4 10.7001 30.1 10.7001 28 12.8001L13.1 27.7001C11 29.8001 11 33.1001 13.1 35.2001L29.9 52.0001L13.1 68.8001C11 70.9001 11 74.2001 13.1 76.3001L28 91.2001C30.1 93.3001 33.4 93.3001 35.5 91.2001L52.3 74.4001L69.1 91.2001C71.2 93.3001 74.5 93.3001 76.6 91.2001L91.5 76.3001C93.6 74.2001 93.6 70.9001 91.5 68.8001Z" fill="#FF003A" />
    </g>
  </svg>
);

IconProviderTurboGames.propTypes = {
  className: PropTypes.string,
};

IconProviderTurboGames.defaultProps = {
  className: '',
};
