import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderEgtBig = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="100" height="30" viewBox="0 0 100 30">
    <g fill="none" fillRule="evenodd">
      <path fill="url(#icon-provider-egt-big-a)" d="M8.929 15.625c0-3.84 3.66-8.84 9.91-11.518 10.893-4.732 17.143-.357 18.75 3.75 1.518 3.84-1.518 10.714-6.607 15.714 3.66-2.678 6.697-5.446 8.75-8.214 2.768-3.66 3.929-7.143 2.857-9.91C41.071 1.428 34.911.088 27.232 1.16 19.554 2.232 13.661 4.196 8.93 6.696 3.57 9.375.893 12.5.446 16.161c-.535 5.268 5.179 12.41 18.125 13.303 6.786.447 13.393.625 25.983-2.5-1.518.09-35.715 4.375-35.625-11.339z" />
      <g transform="translate(48.84 2.232)">
        <use fill="#A5CD39" fillRule="nonzero" xlinkHref="#icon-provider-egt-big-b" />
        <path fill="url(#icon-provider-egt-big-c)" d="M31.34 18.036c-.894-6.072-7.947-10.804-16.43-10.804-8.481 0-15.535 4.732-16.428 10.804H31.34z" mask="url(#icon-provider-egt-big-d)" opacity=".79" />
        <path fill="url(#icon-provider-egt-big-e)" d="M-4.91-.179c-2.68 4.375-1.161 10.715 3.66 14.733 5.179 4.285 12.321 4.285 15.893 0 3.303-4.018 2.41-10.358-1.875-14.733H-4.911z" mask="url(#icon-provider-egt-big-d)" opacity=".39" />
      </g>
      <g transform="translate(64.929 1.786)">
        <use fill="#A5CD39" fillRule="nonzero" xlinkHref="#icon-provider-egt-big-f" />
        <path fill="url(#icon-provider-egt-big-g)" d="M15.16-.09H4.269C.786 3.215-.464 7.947 2.75 11.876c2.946 3.66 8.393 4.107 12.143 1.16 3.84-3.035 3.571-9.017.625-12.678-.09-.178-.268-.268-.357-.446z" mask="url(#icon-provider-egt-big-h)" opacity=".61" />
      </g>
      <g transform="translate(84.507 2.054)">
        <use fill="#A5CD39" fillRule="nonzero" xlinkHref="#icon-provider-egt-big-i" />
        <path fill="url(#icon-provider-egt-big-j)" d="M2.01 0C.046 3.66.671 8.929 3.885 13.125c2.947 3.75 7.233 5.625 10.983 5.268V0H2.01z" mask="url(#icon-provider-egt-big-k)" opacity=".47" />
        <path fill="url(#icon-provider-egt-big-l)" d="M14.957 2.232c-2.679-.536-5.893.268-8.572 2.41C2.1 8.037.85 13.483 3.528 16.876c2.411 3.036 7.322 3.304 11.429.804V2.232z" mask="url(#icon-provider-egt-big-k)" opacity=".66" />
      </g>
      <g fill="#40AA8B" fillRule="nonzero">
        <path d="M48.929 24.375h.714v4.464h-.714zM54.91 24.375h.715v4.464h-.804l-2.5-3.214v3.214h-.714v-4.464h.714l2.59 3.304zM59.196 25.09v3.75h-.714v-3.75h-1.34v-.715h3.483v.714h-1.429zM65.09 24.375v.714h-2.411v1.161h2.142v.625H62.68v1.16h2.5v.715h-3.215v-4.464h3.125zM70.357 25.893c0 .714-.357 1.16-.982 1.428l1.16 1.608h-.981l-1.072-1.518H67.5v1.518h-.714v-4.465h1.696c.714 0 1.16.09 1.429.357.268.179.446.536.446 1.072zm-.982.625c.179-.09.268-.357.268-.625a.81.81 0 0 0-.268-.625 1.783 1.783 0 0 0-.804-.179h-.982v1.607h.893c.447 0 .714-.089.893-.178zM72.679 27.857l-.447.982h-.803l1.964-4.464h.803l1.965 4.464h-.804l-.446-.982h-2.232zm1.964-.714l-.804-1.875-.803 1.875h1.607zM79.464 28.214c.268 0 .447 0 .625-.089.179-.09.357-.268.625-.446l.447.535a2.199 2.199 0 0 1-1.697.804c-.714 0-1.25-.179-1.696-.625-.447-.447-.625-.982-.625-1.607s.268-1.25.714-1.697c.447-.446.982-.625 1.697-.625.714 0 1.25.268 1.696.804l-.446.536a2.713 2.713 0 0 0-.625-.447c-.179-.09-.447-.09-.625-.09-.447 0-.804.18-1.161.447a1.479 1.479 0 0 0-.447 1.072c0 .446.179.803.447 1.16.268.09.625.268 1.071.268zM84.464 25.09v3.75h-.714v-3.75h-1.34v-.715h3.483v.714h-1.429zM87.232 24.375h.714v4.464h-.714zM91.875 28.84h-.804l-1.785-4.465h.803l1.34 3.304 1.339-3.304h.803zM98.125 24.375v.714h-2.41v1.161h2.142v.625h-2.143v1.16h2.411v.715h-3.214v-4.464h3.214z" />
      </g>
      <path fill="#A5CD39" d="M21.518 21.786c4.018 0 7.41-3.304 7.41-7.411 0-4.018-3.303-7.41-7.41-7.41-4.018 0-7.41 3.303-7.41 7.41.088 4.107 3.392 7.41 7.41 7.41z" />
      <g>
        <g transform="translate(14.018 6.25)">
          <use fill="#A5CD39" xlinkHref="#icon-provider-egt-big-m" />
          <path fill="url(#icon-provider-egt-big-n)" d="M4.196 11.964c4.733 0 8.661-3.928 8.661-8.66 0-4.733-3.928-8.661-8.66-8.661-4.733 0-8.661 3.928-8.661 8.66 0 4.733 3.839 8.661 8.66 8.661z" mask="url(#icon-provider-egt-big-o)" opacity=".36" />
          <path fill="url(#icon-provider-egt-big-p)" d="M9.554 12.321c3.75 0 6.785-3.035 6.785-6.875 0-3.839-3.035-6.875-6.785-6.875S2.768 1.607 2.768 5.446c0 3.84 3.036 6.875 6.786 6.875z" mask="url(#icon-provider-egt-big-o)" opacity=".71" />
        </g>
      </g>
    </g>
  </svg>
);

IconProviderEgtBig.propTypes = {
  className: PropTypes.string,
};
IconProviderEgtBig.defaultProps = {
  className: null,
};
