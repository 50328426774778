import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderTurboGamesBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="210"
    height="63"
    viewBox="0 0 210 63"
    fill="none"
  >
    <mask id="mask-icon-provider-turbo-games-big" maskUnits="userSpaceOnUse" x="0" y="0" width="210" height="63">
      <path d="M210 0H0V62.8112H210V0Z" fill="white" />
    </mask>
    <g mask="url(#mask-icon-provider-turbo-games-big)">
      <path
        d="M47.1381 40.5389L37.2391 30.6399L47.1381 20.7409C48.3755 19.5035 48.3755 17.559 47.1381 16.3216L38.2997 7.5422C37.0623 6.30482 35.1179 6.30482 33.8805 7.5422L24.0404 17.4412L14.1414 7.5422C12.904 6.30482 10.9596 6.30482 9.72225 7.5422L0.883842 16.3216C-0.353533 17.559 -0.353533 19.5035 0.883842 20.7409L10.7828 30.6399L0.883842 40.5389C-0.353533 41.7765 -0.353533 43.7207 0.883842 44.9584L9.66334 53.7373C10.9007 54.975 12.8451 54.975 14.0825 53.7373L23.9815 43.8388L33.8805 53.7373C35.1179 54.975 37.0623 54.975 38.2997 53.7373L47.1381 44.9584C48.3165 43.7207 48.3165 41.7765 47.1381 40.5389Z"
        fill="#96C8D8"
      />
      <path
        d="M195.27 0C187.138 0 180.539 6.5993 180.539 14.7306C180.539 22.8619 187.138 29.4613 195.27 29.4613C203.401 29.4613 210 22.8619 210 14.7306C210 6.5993 203.401 0 195.27 0ZM202.104 17.6178C202.458 17.9714 202.458 18.5606 202.104 18.9141L199.512 21.5067C199.158 21.8603 198.569 21.8603 198.215 21.5067L195.328 18.6195L192.441 21.5067C192.088 21.8603 191.499 21.8603 191.145 21.5067L188.552 18.9141C188.199 18.5606 188.199 17.9714 188.552 17.6178L191.439 14.7306L188.552 11.8435C188.199 11.4899 188.199 10.9007 188.552 10.5472L191.145 7.95458C191.499 7.60103 192.088 7.60103 192.441 7.95458L195.328 10.8417L198.215 7.95458C198.569 7.60103 199.158 7.60103 199.512 7.95458L202.104 10.5472C202.458 10.9007 202.458 11.4899 202.104 11.8435L199.158 14.7306L202.104 17.6178Z"
        fill="#96C8D8"
      />
      <path
        d="M102.996 36.473H111.246C111.599 36.473 111.953 36.7087 112.071 37.0622L121.263 61.6329C121.498 62.2221 121.027 62.8112 120.438 62.8112H112.778C112.425 62.8112 112.071 62.5757 111.953 62.2221L111.187 60.101C111.069 59.7474 110.716 59.5119 110.362 59.5119H103.88C103.527 59.5119 103.173 59.7474 103.056 60.101L102.29 62.2221C102.172 62.5757 101.818 62.8112 101.465 62.8112H93.8639C93.2747 62.8112 92.803 62.2221 93.0385 61.6329L102.172 37.0622C102.29 36.7087 102.644 36.473 102.996 36.473ZM108.948 53.5605C109.537 53.5605 109.949 52.9714 109.773 52.441L107.239 44.5454H107.062L104.529 52.3823C104.352 52.9714 104.765 53.5018 105.354 53.5018H108.948V53.5605Z"
        fill="#96C8D8"
      />
      <path
        d="M125.151 36.473H131.221C131.515 36.473 131.751 36.5908 131.927 36.8265L139.175 46.6077C139.529 47.0794 140.236 47.0794 140.59 46.6077L147.837 36.8265C148.013 36.5908 148.249 36.473 148.544 36.473H154.554C155.025 36.473 155.438 36.8855 155.438 37.3569V61.9278C155.438 62.3989 155.025 62.8112 154.554 62.8112H147.424C146.953 62.8112 146.54 62.3989 146.54 61.9278V56.7424L146.6 51.3214H146.482L142.592 56.6243C142.416 56.8605 142.18 56.9779 141.886 56.9779H137.761C137.466 56.9779 137.231 56.8605 137.054 56.6243L133.165 51.2627L133.047 51.3214L133.106 56.7424V61.9278C133.106 62.3989 132.694 62.8112 132.222 62.8112H125.093C124.622 62.8112 124.209 62.3989 124.209 61.9278V37.3569C124.268 36.8855 124.68 36.473 125.151 36.473Z"
        fill="#96C8D8"
      />
      <path
        d="M201.338 46.9026H192.972C192.972 46.9026 191.145 47.02 191.145 45.4294C191.145 43.8381 192.972 43.8381 192.972 43.8381H209.175C209.646 43.8381 210.059 43.4258 210.059 42.9548V37.2979C210.059 36.8265 209.646 36.414 209.175 36.414H192.441C188.14 36.414 183.72 36.414 183.72 45.1345C183.72 53.0301 187.963 52.3228 192.323 52.3228H200.749C200.749 52.3228 202.635 52.3228 202.635 53.796C202.635 55.2692 200.808 55.3279 200.808 55.3279H179.714C179.714 55.3279 179.714 55.3279 179.655 55.3279H165.926V53.2069C165.926 52.7359 166.339 52.3228 166.81 52.3228H175.294C175.766 52.3228 176.178 51.9105 176.178 51.4395V47.6685C176.178 47.1968 175.766 46.7845 175.294 46.7845H165.926V44.7809C165.926 44.3098 166.339 43.8975 166.81 43.8975H179.655C180.126 43.8975 180.539 43.4845 180.539 43.0135V37.3568C180.539 36.8854 180.126 36.4729 179.655 36.4729H159.445C158.974 36.4729 158.561 36.8854 158.561 37.3568V61.9278C158.561 62.3989 158.974 62.8112 159.445 62.8112H178.123H179.714H201.397C205.875 62.8112 210.059 62.8112 210.059 54.0909C210 46.254 205.522 46.9026 201.338 46.9026Z"
        fill="#96C8D8"
      />
      <path
        d="M141.473 28.8131L136.524 20.564C136.288 20.2104 135.935 19.9748 135.522 19.9748H132.87C132.222 19.9748 131.692 20.5051 131.692 21.1532V28.165C131.692 28.8131 131.161 29.3434 130.514 29.3434H123.738C123.089 29.3434 122.559 28.8131 122.559 28.165V1.17847C122.559 0.530286 123.089 0 123.738 0H140.59C147.366 0 150.783 5.00844 150.783 9.9579C150.783 13.3754 149.192 16.7929 146.01 18.6195C145.951 18.6785 145.892 18.7963 145.951 18.8552L150.96 27.5758C151.431 28.3418 150.842 29.3434 149.899 29.3434H142.475C142.062 29.4023 141.65 29.1667 141.473 28.8131ZM131.633 13.0808C131.633 13.1398 131.692 13.1987 131.751 13.1987H138.468C140.707 13.1987 141.827 11.6077 141.827 10.0168C141.827 8.42596 140.707 6.83502 138.468 6.83502H132.87C132.222 6.83502 131.692 7.36531 131.692 8.0135V13.0808H131.633Z"
        fill="#96C8D8"
      />
      <path
        d="M176.06 14.3182C176.002 14.2592 176.002 14.2593 176.002 14.2003C176.002 14.1414 176.002 14.1414 176.06 14.0825C177.475 12.6094 178.299 10.2525 178.299 7.8956C178.299 2.41585 174.764 0 171.229 0H155.025C154.2 0 153.553 0.648182 153.553 1.47303V27.9882C153.553 28.8131 154.2 29.4613 155.025 29.4613H171.229C176.591 29.4613 179.007 25.101 179.007 21.0943C179.007 18.3249 177.946 15.7913 176.06 14.3182ZM168.106 12.3738H161.742V7.60103C161.742 7.12966 162.155 6.7172 162.626 6.7172H168.106C170.168 6.7172 171.111 8.13132 171.111 9.54544C171.052 10.9006 170.11 12.3738 168.106 12.3738ZM162.626 17.5589H168.106C170.168 17.5589 171.111 18.9731 171.111 20.3872C171.111 21.7425 170.168 23.2155 168.106 23.2155H161.742V18.4428C161.742 17.9125 162.096 17.5589 162.626 17.5589Z"
        fill="#96C8D8"
      />
      <path
        d="M90.5052 47.0794H80.9596C80.1937 47.0794 79.6045 47.6685 79.6045 48.4344V51.7344C79.6045 52.5003 80.1937 53.0895 80.9596 53.0895H84.1415C84.2596 53.0895 84.377 53.2076 84.377 53.325V55.0337C84.377 55.0931 84.3183 55.2105 84.2596 55.2692C83.3755 55.7409 82.3741 55.9764 81.0777 55.9764H80.9009C78.1313 55.9764 76.7176 55.9177 75.4212 55.859C72.7104 55.6822 70.7075 53.1482 70.7075 49.8489V49.7895C70.7075 46.9613 72.4749 44.6048 75.185 43.8388C75.8335 43.662 86.2625 43.6026 90.2103 43.662C90.8588 43.662 91.3298 43.1316 91.3298 42.5424C91.3298 40.8338 91.3298 37.7695 91.3298 37.7695C91.3298 37.1214 90.8001 36.65 90.2103 36.65C86.2038 36.65 75.3618 36.65 75.1263 36.7089C67.9378 37.2392 62.7526 42.778 62.7526 49.7895V49.8489C62.7526 57.5677 66.6415 62.3401 73.4176 62.8706C73.7125 62.8706 75.9509 62.9293 79.369 62.9293C80.7241 62.9293 81.7849 62.9293 81.961 62.9293C82.0791 62.9293 82.1973 62.9293 82.3741 62.9293C85.9682 62.9293 91.5066 59.3351 91.919 58.2742C91.9784 58.1568 91.919 48.5525 91.919 48.5525C91.8602 47.6685 91.2711 47.0794 90.5052 47.0794Z"
        fill="#96C8D8"
      />
      <path
        d="M86.8516 0H64.4024C63.7543 0 63.2239 0.530286 63.2239 1.17847V7.8956C63.2239 8.54378 63.7543 9.07407 64.4024 9.07407H69.8822C70.5307 9.07407 71.0604 9.60436 71.0604 10.2525V28.2828C71.0604 28.931 71.5908 29.4613 72.2394 29.4613H78.956C79.6045 29.4613 80.135 28.931 80.135 28.2828V10.2525C80.135 9.60436 80.6647 9.07407 81.3132 9.07407H86.7929C87.4414 9.07407 87.9712 8.54378 87.9712 7.8956V1.17847C88.0306 0.530286 87.5001 0 86.8516 0Z"
        fill="#96C8D8"
      />
      <path
        d="M111.422 0.0589905C110.775 0.0589905 110.244 0.589277 110.244 1.23739V19.2677C110.244 20.5051 109.773 20.9765 108.477 20.9765H102.054C100.758 20.9765 100.286 20.5051 100.286 19.2677V1.23739C100.286 0.589277 99.7558 0.0589905 99.108 0.0589905H92.2139C91.566 0.0589905 91.0356 0.589277 91.0356 1.23739V20.4462C91.0356 23.569 91.8015 25.8671 93.2747 27.2812C94.7479 28.7542 97.1631 29.4613 100.345 29.4613H110.185C113.367 29.4613 115.783 28.6953 117.256 27.2812C118.729 25.8081 119.495 23.5101 119.495 20.4462V1.23739C119.495 0.589277 118.964 0.0589905 118.317 0.0589905H111.422Z"
        fill="#96C8D8"
      />
    </g>
  </svg>
);

IconProviderTurboGamesBig.propTypes = {
  className: PropTypes.string,
};

IconProviderTurboGamesBig.defaultProps = {
  className: '',
};
