import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderBoomingGames = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.8387 7.02315C18.8387 5.06058 19.6397 4.48649 20.601 4.48649C22.1764 4.48649 22.884 5.381 22.884 8.85222H26.6756V7.37028C26.6756 3.89906 24.6864 1.00192 20.6945 1.00192C19.9049 0.949298 19.1132 1.06767 18.3735 1.34894C17.6339 1.63022 16.9636 2.06779 16.4085 2.63178C15.8534 3.19576 15.4265 3.87288 15.157 4.61692C14.8875 5.36096 14.7817 6.15439 14.8468 6.94305V15.9015H18.852L18.8387 7.02315Z" fill="#E61E25" />
    <path d="M20.3476 15.9148V19.0522H22.8842V21.3085C22.8842 23.6182 22.3101 24.5261 20.6947 24.5261C19.3596 24.5261 18.8256 23.5648 18.8256 22.0829V19.0522H14.8203V22.0562C14.8203 25.7544 17.0099 27.9973 20.1607 27.9973C20.948 28.0234 21.729 27.8483 22.4298 27.4885C23.1307 27.1288 23.7282 26.5963 24.1659 25.9413L24.9002 27.6769H26.8227V15.9148H20.3476Z" fill="#E61E25" />
    <path d="M4.113 4.60682H5.62164C7.38396 4.60682 8.22506 5.72829 8.22506 7.89113C8.2645 8.2646 8.22471 8.64219 8.1083 8.99924C7.99188 9.35629 7.80146 9.68477 7.54947 9.96322C7.29747 10.2417 6.98958 10.4638 6.64588 10.6152C6.30219 10.7666 5.93043 10.8437 5.55489 10.8417H4.113V4.60682ZM4.113 14.1126H5.44808C6.08063 14.0129 6.72815 14.1371 7.27896 14.4637C7.82978 14.7902 8.24937 15.2988 8.46537 15.9016H12.4706C12.3059 15.0994 11.9437 14.3509 11.4168 13.7238C10.89 13.0967 10.2152 12.6109 9.45334 12.3103C10.3452 11.8707 11.085 11.1741 11.5775 10.3104C12.0701 9.44669 12.2928 8.4553 12.217 7.4639C12.217 3.45864 10.3211 1.32251 6.04887 1.32251H0.121094V15.9016H4.12635L4.113 14.1126Z" fill="#E61E25" />
    <path d="M12.6572 19.0524H8.65197V20.8147C8.65197 23.1645 7.87762 24.3794 5.75483 24.3794H4.11268V19.0391H0.107422V27.6637H6.42237C10.9483 27.6637 12.6572 24.7666 12.6572 20.9883C12.6572 20.9883 12.6572 20.8948 12.6572 20.8414V19.0524Z" fill="#E61E25" />
  </svg>
);

IconProviderBoomingGames.propTypes = {
  className: PropTypes.string
};

IconProviderBoomingGames.defaultProps = {
  className: ''
};
