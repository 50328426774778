import React from 'react';

export const IconProviderPragmaticPlay = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
    <title>providers logo/small/pragmatic</title>
    <g id="providers-logo/small/pragmatic" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" x="0" y="0" width="24" height="24" />
      <g id="Group" transform="translate(3.155172, 0.000000)" fillRule="nonzero">
        <path d="M2.168,13.5439345 L3.03517241,13.5439345 C3.51986207,13.5439345 3.85144828,13.5949414 4.05544828,13.6714655 C4.25944828,13.7479759 4.43806897,13.9009828 4.54013793,14.1051207 C4.66758621,14.3091207 4.71862069,14.5386379 4.71862069,14.8191897 C4.71862069,15.1252586 4.64206897,15.380431 4.48910345,15.584431 C4.336,15.788431 4.10648276,15.9160172 3.85144828,15.992569 C3.69834483,16.0436034 3.39227586,16.0691207 2.98413793,16.0691207 L2.98413793,17.9055345 L2.168,17.9055345 L2.168,13.5439345 Z M2.98413793,15.2528448 L3.23931034,15.2528448 C3.44331034,15.2528448 3.59641379,15.2273276 3.67282759,15.2018103 C3.74937931,15.1762931 3.82593103,15.1252586 3.85144828,15.0488448 C3.90248276,14.9722931 3.928,14.8957414 3.928,14.7938103 C3.928,14.6151897 3.85144828,14.5131207 3.72386207,14.4367069 C3.6217931,14.3856724 3.44331034,14.3601552 3.18827586,14.3601552 L2.95862069,14.3601552 L2.95862069,15.2528448 L2.98413793,15.2528448 Z" id="Shape" fill="#FFFFFF" />
        <polygon id="Path" fill="#FFFFFF" points="5.27972414 13.5439345 6.096 13.5439345 6.096 17.0892586 7.29475862 17.0892586 7.29475862 17.8800172 5.27972414 17.8800172" />
        <path d="M9.46275862,13.5439345 L10.3045517,13.5439345 L11.9624828,17.8800172 L11.0951724,17.8800172 L10.7635862,16.9873276 L9.00372414,16.9873276 L8.64662069,17.8800172 L7.77944828,17.8800172 L9.46275862,13.5439345 Z M9.87089655,14.6917414 L9.28427586,16.1710517 L10.432,16.1710517 L9.87089655,14.6917414 Z" id="Shape" fill="#FFFFFF" />
        <polygon id="Path" fill="#FFFFFF" points="11.3503448 13.5439345 12.2430345 13.5439345 13.0591724 15.0998793 13.8754483 13.5439345 14.7426207 13.5439345 13.4673103 15.992569 13.4673103 17.8800172 12.6256552 17.8800172 12.6256552 15.992569" />
        <path d="M9.43737931,9.33532759 C6.83572414,9.48837586 8.00896552,12.8807207 10.8656552,12.7021828 C13.6714483,12.4981276 12.192,9.15678966 9.43737931,9.33532759 Z" id="Path" fill="#F6921F" />
        <path d="M8.34055172,7.24382414 C3.72386207,7.24382414 0,10.9932586 0,15.584431 C0,20.2011207 3.74937931,23.9249828 8.34055172,23.9249828 C12.9572414,23.9249828 16.6811034,20.1756034 16.6811034,15.584431 C16.6811034,10.9677552 12.9572414,7.24382414 8.34055172,7.24382414 Z M8.34055172,23.0578103 C4.20855172,23.0578103 0.867172414,19.716431 0.867172414,15.584431 C0.867172414,11.4523759 4.20855172,8.11103793 8.34055172,8.11103793 C12.4725517,8.11103793 15.813931,11.4523759 15.813931,15.584431 C15.813931,19.716431 12.4725517,23.0578103 8.34055172,23.0578103 Z" id="Shape" fill="#F6921F" />
        <path d="M8.08551724,1.40285862 C6.096,2.90772759 6.45310345,5.2033 6.45310345,5.2033 C6.45310345,5.2033 5.20331034,1.96398966 3.59641379,0 C3.36675862,2.55063793 4.18303448,5.91747931 4.18303448,5.91747931 C4.18303448,5.91747931 3.16275862,3.82596207 0.688689655,3.69843103 C2.75462069,4.87172759 3.54537931,7.01426552 3.72386207,7.57539655 C4.92275862,6.83571379 6.3,6.4021 7.80496552,6.3510931 C7.62634483,5.84096897 7.03972414,3.59640345 8.08551724,1.40285862 Z" id="Path" fill="#F6921F" />
      </g>
    </g>
  </svg>
);
