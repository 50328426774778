import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderFormulaSpin = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
  >
    <g clipPath="url(#clip0_685_1729)">
      <path
        d="M55.2808 0.0325317H4.71933C2.11292 0.0325317 0 2.13466 0 4.72776V55.0308C0 57.6239 2.11292 59.7259 4.71933 59.7259H55.2808C57.8871 59.7259 60 57.6239 60 55.0308V4.72776C60 2.13466 57.8871 0.0325317 55.2808 0.0325317Z"
        fill="#E42525"
      />
      <path
        d="M29.4209 21.0384L29.083 24.0423H6.83237L4.82666 21.0384H29.4209ZM23.9615 28.8815L23.6236 31.8854H10.5274L9.64789 39.7341H5.07335L5.87778 32.563C6.10304 30.5356 7.95322 28.8815 9.98577 28.8815H23.9615Z"
        fill="black"
      />
      <path
        d="M54.6958 24.0369H37.0196L36.8 26.0217L36.7034 26.8968C36.5801 27.9905 37.3789 28.8816 38.4784 28.8816H49.5797C52.0947 28.8816 53.929 30.9304 53.6449 33.4328L53.4517 35.1774C53.1728 37.6797 50.8829 39.7285 48.3675 39.7285H30.7559L31.0937 36.7246H46.7106C47.81 36.7246 48.8073 35.8338 48.9306 34.74L49.0272 33.8649C49.1505 32.7712 48.3517 31.8801 47.2522 31.8801H36.1509C33.6358 31.8801 31.8016 29.8313 32.0859 27.3289L32.1342 26.8914L32.2789 25.5842V25.5575L32.4505 24.0316L30.4448 21.0277H55.039L54.7011 24.0316L54.6958 24.0369Z"
        fill="black"
      />
    </g>
  </svg>
);

IconProviderFormulaSpin.propTypes = {
  className: PropTypes.string,
};

IconProviderFormulaSpin.defaultProps = {
  className: '',
};
