import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderPlaytech = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="23" height="16" viewBox="0 0 23 16">
    <path
      d="M20.0677 4.58263C20.0542 4.54938 20.0463 4.51691 20.0327 4.48286C19.2188 2.44857 16.1265 1.83212 12.5617 2.76166L11.9769 2.39017C13.7319 1.61798 15.4797 1.21485 16.9444 1.32516C19.1289 1.49388 20.1974 2.76166 20.0677 4.58263ZM16.8982 8.85561C14.6126 11.8227 10.4821 13.8765 7.67141 13.6639C4.8655 13.4474 4.43987 10.8688 6.72629 7.90174C6.77164 7.8409 6.82096 7.78089 6.8687 7.72005L6.87592 11.4399C6.86081 12.3856 7.75421 12.8293 8.39621 12.4538L16.7996 7.22851C17.4568 6.79456 17.3875 5.82365 16.7686 5.44972L13.3612 3.45031C14.2825 3.16804 15.1664 3.03502 15.9516 3.09423C18.7599 3.3108 19.1822 5.88853 16.8982 8.85561ZM6.21004 15.2602C3.63086 15.0623 2.60221 13.329 3.27365 10.9686C3.26959 11.362 3.33015 11.7392 3.47334 12.0896C4.48131 14.6162 9.00641 14.9585 13.5792 12.8609C13.6731 12.8179 13.7662 12.7709 13.8585 12.7287C11.2912 14.4183 8.43122 15.4281 6.21004 15.2602ZM9.18143 2.05113L14.8227 5.67604C15.2396 5.93073 15.2897 6.59422 14.8458 6.88866L9.14722 10.4316C8.71205 10.6871 8.10186 10.383 8.11379 9.73651L8.08676 2.70165C8.11143 2.1144 8.68103 1.75994 9.18143 2.05113ZM22.2396 3.35623C21.0574 0.394012 16.1473 -0.258134 10.8338 1.65855L8.47578 0.1523C7.74149 -0.275161 6.90298 0.245579 6.86241 1.11186L6.864 3.55819C2.2848 6.35575 -0.37075 10.383 0.759731 13.2178C2.07 16.4923 7.93879 16.9417 13.8688 14.218C19.8005 11.4958 23.5475 6.63234 22.2396 3.35623Z"
      fill="#161F49"
      className="icon-switch-dark"
    />
  </svg>
);

IconProviderPlaytech.propTypes = {
  className: PropTypes.string
};

IconProviderPlaytech.defaultProps = {
  className: ''
};
