import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderPlankGaming = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <mask id="icon-plank-gaming-c" fill="#fff">
        <use xlinkHref="#icon-plank-gaming-a" />
      </mask>
      <path d="M0 0h24v24H0z" />
      <path fill="url(#icon-plank-gaming-b)" fillRule="nonzero" d="M2.54.21v5.302h10.032a3.126 3.126 0 0 1 1.48.336c.4.201.735.511.966.893a2.306 2.306 0 0 1 0 2.387 2.412 2.412 0 0 1-.966.886 3.125 3.125 0 0 1-1.48.335H2.54V24.21h6.396v-8.559h3.907c1.87 0 3.473-.362 4.808-1.087a7.75 7.75 0 0 0 3.045-2.84 7.416 7.416 0 0 0 0-7.588 7.682 7.682 0 0 0-3.042-2.841C16.32.575 14.719.215 12.85.215L2.54.21z" mask="url(#icon-plank-gaming-c)" />
    </g>
  </svg>
);

IconProviderPlankGaming.propTypes = {
  className: PropTypes.string,
};
IconProviderPlankGaming.defaultProps = {
  className: null,
};
