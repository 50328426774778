import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderRedrakeBig = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="90" height="56" viewBox="0 0 90 56">
    <g fill="none" fillRule="nonzero">
      <g fill="#FFF">
        <path d="M2.193 45.337c-.32 0-.626-.14-.834-.386a1.102 1.102 0 0 1-.248-.887L5.47 16.742a1.097 1.097 0 0 1 1.082-.926h79.663a1.103 1.103 0 0 1 1.082 1.274l-4.359 27.322a1.097 1.097 0 0 1-1.082.925H2.193z" />
        <path d="M86.215 16.916l-4.359 27.322H2.193l4.359-27.322h79.663zm0-2.2H6.552a2.194 2.194 0 0 0-2.164 1.853L.029 43.89c-.101.636.08 1.285.496 1.775.417.49 1.026.772 1.668.772h79.663a2.194 2.194 0 0 0 2.164-1.852l4.359-27.321a2.205 2.205 0 0 0-.497-1.775 2.188 2.188 0 0 0-1.667-.772z" />
      </g>
      <path fill="#FFF" d="M89.873 4.15c-.337-1.334-1.394-2.551-2.898-3.34-.987-.518-2.052-.792-3.078-.792-1.49 0-2.747.601-3.448 1.65l-.003.004-.914 1.373a2.2 2.2 0 0 0-.204.385c-.12.13-.231.27-.33.419l-4.978 7.476c-.082.123-.15.252-.205.385-.12.13-.231.27-.33.419l-1.511 2.269c-.696 1.045-.732 2.34-.157 3.539l-14.31 21.49-4.31-1.31a2.319 2.319 0 0 0-.03-.056c-.732-1.335-2.042-2.101-3.592-2.101-1.149 0-2.142.42-2.52.602a2.129 2.129 0 0 0-.077.039c-.369.108-.71.312-.983.604a2.205 2.205 0 0 0-.459 2.271l1.302 3.525c.188.51.56.933 1.04 1.185L69.828 55.7c.24.126.496.203.754.234a2.185 2.185 0 0 0 2.514-.863l1.949-2.922a2.206 2.206 0 0 0 .097-2.286c-.734-1.334-2.044-2.1-3.595-2.1-.5 0-.97.08-1.369.183l-6.81-5.207L77.652 21.29c1.428-.038 2.626-.635 3.304-1.653l1.508-2.266c.1-.15.187-.307.261-.468a2.2 2.2 0 0 0 .274-.335l4.981-7.476c.102-.153.19-.313.265-.478a2.2 2.2 0 0 0 .266-.326l.913-1.37c.538-.804.698-1.787.45-2.769z" />
      <g fill="#DB1D31">
        <path d="M84.51 4.935c-1.473-.773-3.126-.712-3.69.136l-4.978 7.476c.877-.345 2.146-.222 3.332.4 1.189.623 1.921 1.55 2.003 2.398l4.98-7.474c.565-.85-.174-2.164-1.647-2.936z" />
        <path d="M85.96 2.759c-1.47-.772-3.125-.712-3.691.135l-.914 1.373c.878-.343 2.147-.22 3.333.4 1.188.623 1.92 1.55 2 2.398l.916-1.373c.568-.847-.17-2.16-1.645-2.933zM78.996 13.216c-1.472-.774-3.123-.714-3.69.135l-1.51 2.268c-.376.565-.172 1.339.447 2.021l.554-.837c.322-.481 1.164-.552 1.914-.158.755.394 1.089 1.083.768 1.567l-.557.833c.97.15 1.833-.062 2.21-.63l1.51-2.267c.566-.848-.171-2.161-1.646-2.932zM59.034 42.189l-.002.007.002-.007-11.443-3.477 1.302 3.525 21.95 11.513-1.302-3.527z" />
        <path d="M77.172 18.05c.22-.328-.065-.84-.639-1.138-.57-.3-1.213-.277-1.432.052L58.655 41.663l.457.144 1.484 1.136L77.172 18.05zM69.968 50.352l1.306 3.495 1.95-2.922c-.966-1.755-3.256-.573-3.256-.573zM51.248 39.122c-.946-1.723-3.165-.615-3.248-.576l3.03.902.218-.326z" />
      </g>
      <path fill="#FFF" d="M44.204 16.249h40.371v21.074H44.204z" />
      <path fill="#2B2B2B" d="M4.02 42.196c-.116.657.123.95.773.95.653-.002.996-.295 1.11-.95l.32-1.812H5.45l-.08.458h.28l-.237 1.354c-.053.303-.26.491-.54.491-.277 0-.416-.188-.363-.491l.557-3.167c.063-.362.317-.491.533-.491.284.003.422.192.369.491l-.098.557h.491l.098-.557c.115-.655-.124-.946-.777-.946-.65 0-.993.291-1.108.946l-.555 3.167zM11.718 43.061h.495l.073-1.676.062-3.22h-.807l-1.076 3.233-.513 1.663h.493l.34-1.098h.982l-.05 1.098zm-.793-1.556l1.004-3.267-.145 3.267h-.859zM18.118 43.061l.86-4.897h-.522l-1.652 3.943-.28-3.943h-.521l-.86 4.897h.466l.62-3.52.26 3.52h.307l1.487-3.553-.625 3.553zM23.155 38.164l-.86 4.897h.49l.862-4.897zM29.394 43.061l.86-4.893-.437.007-.627 3.565-.3-3.576h-.52l-.861 4.897h.462l.613-3.488.29 3.488zM33.571 42.196c-.116.657.124.95.774.95.653-.002.996-.295 1.11-.95l.319-1.812h-.772l-.08.458h.28l-.237 1.354c-.054.303-.26.491-.54.491-.277 0-.416-.188-.363-.491l.556-3.167c.064-.362.318-.491.534-.491.283.003.422.192.369.491l-.098.557h.492l.097-.557c.115-.655-.125-.946-.777-.946-.651 0-.993.291-1.108.946l-.556 3.167z" />
      <path fill="#DB1D31" d="M12.215 35.427l-1.477-7.272-.07.046-.138 1.285c-.046.425-.108.854-.184 1.286l-.716 4.655H4.922l2.7-17.463h4.547c.907 0 1.746.07 2.515.21.769.138 1.43.374 1.984.705.554.332.984.784 1.292 1.355.309.572.463 1.297.463 2.177 0 .217-.013.437-.035.66-.023.224-.05.46-.081.707a6.294 6.294 0 0 1-1.05 2.64c-.546.787-1.32 1.367-2.32 1.737l2.378 7.272h-5.1zm-.831-9.38c.307 0 .592-.061.854-.185a2.2 2.2 0 0 0 .68-.498c.193-.208.35-.448.474-.718a3.313 3.313 0 0 0 .3-1.309c0-.556-.158-.964-.474-1.227-.315-.263-.742-.394-1.28-.394h-.185l-.669 4.308.3.023zM26.774 17.964h3.784c1.17 0 2.193.155 3.07.464.876.309 1.611.753 2.204 1.331a5.553 5.553 0 0 1 1.339 2.108c.3.826.45 1.757.45 2.79 0 .31-.012.626-.035.95a10.47 10.47 0 0 1-.127 1.02c-.2 1.343-.504 2.493-.912 3.45-.408.958-.877 1.76-1.408 2.409a7.416 7.416 0 0 1-1.719 1.564 8.115 8.115 0 0 1-1.846.89 8.703 8.703 0 0 1-1.8.394c-.585.063-1.116.093-1.593.093h-4.107l2.7-17.463zm2.654 13.248c.539 0 .996-.15 1.373-.452.376-.3.688-.672.935-1.111.245-.44.438-.911.576-1.413.138-.502.239-.961.3-1.378a9.897 9.897 0 0 0 .14-1.714c0-.37-.028-.726-.082-1.065a2.729 2.729 0 0 0-.3-.904 1.68 1.68 0 0 0-.6-.625c-.254-.154-.573-.232-.958-.232l-1.384 8.894z" />
      <path fill="#424243" d="M44.493 35.427l-1.477-7.272-.069.046c-.046.432-.092.86-.139 1.285-.046.425-.107.854-.184 1.286l-.715 4.655H37.2l2.7-17.463h4.546c.908 0 1.746.07 2.515.21.77.138 1.431.374 1.985.705.554.332.984.784 1.292 1.355.308.572.463 1.297.463 2.177 0 .217-.013.437-.035.66-.024.224-.05.46-.081.707a6.304 6.304 0 0 1-1.05 2.64c-.546.787-1.32 1.367-2.32 1.737l2.377 7.272h-5.1zm-.83-9.38c.307 0 .592-.061.853-.185a2.2 2.2 0 0 0 .681-.498 2.7 2.7 0 0 0 .474-.718 3.291 3.291 0 0 0 .3-1.309c0-.556-.159-.964-.474-1.227-.315-.263-.742-.394-1.28-.394h-.185l-.67 4.308.3.023zM75.149 17.964l-5.055 8.43 2.793 9.033h-5.055l-1.684-8.013h-.023l-1.246 8.013H60.17l2.7-17.463h4.708L66.426 25.4h.022l3.74-7.435h4.96zM76.159 17.964h8.747l-.623 4.123h-4.039l-.393 2.432h3.624l-.647 4.122h-3.623l-.415 2.663h4.038l-.622 4.123h-8.747z" />
      <path fill="url(#icon-provider-redrake-big-a)" d="M25.419 22.087l.622-4.123h-8.746l-.058.373c.587.391 1.061.9 1.4 1.53.369.683.555 1.539.555 2.544 0 .24-.014.49-.04.74-.022.23-.05.47-.082.721a7.117 7.117 0 0 1-1.181 2.987 5.321 5.321 0 0 1-2.002 1.722l2.237 6.846h5.217l.623-4.123h-4.038l.415-2.663h3.623l.647-4.122h-3.624l.393-2.432h4.039z" />
      <path fill="url(#icon-provider-redrake-big-b)" d="M59.906 35.427l-.992-17.463h-5.747l-1.702 4.325c0 .042.006.08.006.122 0 .24-.014.49-.04.74-.023.23-.05.47-.082.721a7.121 7.121 0 0 1-1.182 2.987 5.176 5.176 0 0 1-.89.983l-.739 1.88 1.865 5.705h.734l.738-2.246h3.162v2.246h4.869zm-6.83-5.952l1.269-4.054c.169-.54.33-1.088.484-1.643.153-.556.307-1.104.461-1.645h.047l-.093 1.645c-.03.555-.053 1.103-.068 1.643l-.116 4.054h-1.985z" />
    </g>
  </svg>

);

IconProviderRedrakeBig.propTypes = {
  className: PropTypes.string,
};
IconProviderRedrakeBig.defaultProps = {
  className: null,
};
