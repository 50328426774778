import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProvider1x2Gaming = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.9615 7.90316L32.7243 7.69557L32.5315 7.53247L27.8756 14.2939H28.6467L23.5608 21.2925L28.8691 28.8397H25.2067L21.6925 23.8576L18.0598 28.8545H17.096L11.9805 36.4314L12.1584 36.5649C14.8432 38.2503 17.9294 39.1879 21.098 39.2806C24.2666 39.3734 27.4024 38.618 30.1812 37.0926C32.9599 35.5671 35.2807 33.327 36.9035 30.604C38.5263 27.8809 39.3921 24.7737 39.4115 21.6038C39.3843 18.9908 38.7941 16.4142 37.681 14.0499C36.5679 11.6856 34.9582 9.58904 32.9615 7.90316Z" fill="url(#paint0_linear)" />
    <path d="M31.1376 6.62787C28.197 4.73939 24.7864 3.71209 21.2921 3.66235C18.9646 3.66235 16.66 4.12126 14.5101 5.01283C12.3602 5.9044 10.4072 7.21113 8.76284 8.85826C7.11847 10.5054 5.81501 12.4606 4.92704 14.612C4.03907 16.7634 3.58402 19.0687 3.58792 21.3961C3.61984 24.0099 4.21368 26.5862 5.32915 28.9501C6.44461 31.3141 8.05559 33.4104 10.0527 35.0968L10.29 35.3044L10.4827 35.4675L15.1534 28.7062H14.3824L19.4534 21.7075L14.1452 14.1455H17.7927L21.3069 19.1275L24.91 14.1455H25.859L31.3155 6.73166L31.1376 6.62787Z" fill="url(#paint1_linear)" />
    <path opacity="0.2" d="M28.7364 13.9529H29.2998L24.036 21.248C24.0226 21.2677 24.0155 21.291 24.0155 21.3148C24.0155 21.3386 24.0226 21.3618 24.036 21.3815L29.5222 29.2105H29.2998C25.8895 29.2105 25.1481 29.2105 25.0591 29.2105L21.8564 24.6584C21.8276 24.6482 21.7962 24.6482 21.7674 24.6584C21.7535 24.6518 21.7383 24.6484 21.7229 24.6484C21.7075 24.6484 21.6923 24.6518 21.6784 24.6584L18.2829 29.2105H17.3933C17.3797 29.2026 17.3644 29.1985 17.3488 29.1985C17.3332 29.1985 17.3178 29.2026 17.3043 29.2105L12.5298 36.2684C12.5175 36.2781 12.5075 36.2905 12.5007 36.3046C12.4938 36.3187 12.4902 36.3342 12.4902 36.3499C12.4902 36.3656 12.4938 36.3811 12.5007 36.3953C12.5075 36.4094 12.5175 36.4218 12.5298 36.4315C15.1713 38.0376 18.1918 38.9139 21.2828 38.9709C24.3738 39.0278 27.4245 38.2634 30.1234 36.7556C32.8223 35.2479 35.0727 33.0508 36.6447 30.3888C38.2167 27.7269 39.054 24.6954 39.0712 21.6039C39.0401 19.0397 38.4587 16.512 37.3664 14.1918C36.2741 11.8716 34.6964 9.81306 32.7398 8.15528H32.6657C32.6418 8.14616 32.6154 8.14616 32.5915 8.15528L28.6622 13.7898C28.6531 13.8082 28.6483 13.8285 28.6483 13.8491C28.6483 13.8697 28.6531 13.89 28.6622 13.9084C28.6761 13.915 28.6913 13.9184 28.7067 13.9184C28.7221 13.9184 28.7373 13.915 28.7512 13.9084L28.7364 13.9529ZM28.7512 13.8046L32.6212 8.21459C34.5679 9.85723 36.1392 11.8987 37.2289 14.2009C38.3187 16.5032 38.9014 19.0126 38.9378 21.5594C38.9456 26.1318 37.1389 30.5204 33.9141 33.7618C30.6893 37.0033 26.3101 38.8327 21.7378 38.8484C18.5257 38.866 15.3708 37.999 12.6188 36.3425L17.3933 29.3291H18.3422L21.7378 24.6584L24.9553 29.2105C26.3915 29.3204 27.834 29.3204 29.2702 29.2105H29.7002L24.0805 21.2036L29.4778 13.7898L28.7512 13.8046Z" fill="#20201E" />
    <path opacity="0.2" d="M10.3785 34.9042C10.4024 34.9133 10.4288 34.9133 10.4527 34.9042L14.3375 29.2845C14.3466 29.2661 14.3514 29.2458 14.3514 29.2252C14.3514 29.2046 14.3466 29.1843 14.3375 29.1659C14.3213 29.158 14.3036 29.154 14.2856 29.154C14.2676 29.154 14.2499 29.158 14.2337 29.1659H13.7147L18.9785 21.8707C18.9919 21.8511 18.9991 21.8278 18.9991 21.804C18.9991 21.7802 18.9919 21.757 18.9785 21.7373L13.4923 13.8045H13.7147C17.1251 13.8045 17.8665 13.8045 17.9554 13.8045L21.173 18.3566C21.2018 18.3668 21.2332 18.3668 21.262 18.3566C21.2759 18.3632 21.2911 18.3666 21.3065 18.3666C21.3218 18.3666 21.337 18.3632 21.3509 18.3566L24.7465 13.7897H25.6361C25.65 13.7963 25.6652 13.7997 25.6806 13.7997C25.696 13.7997 25.7112 13.7963 25.7251 13.7897L30.7665 6.95419C30.7734 6.92494 30.7734 6.89447 30.7665 6.86522C30.7665 6.86522 30.7665 6.86522 30.7665 6.79109C27.9097 5.06337 24.6447 4.12685 21.3065 4.07764C16.7104 4.08153 12.303 5.906 9.04891 9.15176C5.79482 12.3975 3.95904 16.8002 3.94336 21.3963C3.97629 23.9617 4.55996 26.49 5.65485 28.8103C6.74974 31.1305 8.3303 33.1885 10.2896 34.8449H10.3637L10.3785 34.9042ZM10.3637 34.7263C8.41973 33.0822 6.8512 31.0402 5.76406 28.738C4.67692 26.4358 4.09649 23.9271 4.06198 21.3814C4.06983 16.8156 5.88809 12.4393 9.118 9.21214C12.3479 5.98501 16.7258 4.17052 21.2916 4.1666C24.6032 4.22439 27.8409 5.15529 30.6775 6.86522L25.6361 13.7007H24.6872L21.2916 18.3714L18.074 13.7897C16.6379 13.6798 15.1954 13.6798 13.7592 13.7897H13.344L18.9637 21.7966L13.5665 29.2104H14.3523L10.3785 34.7856L10.3637 34.7263Z" fill="#20201E" />
    <path d="M32.9615 7.90292L32.7243 7.69533L32.5315 7.53223L27.8756 14.2936H28.6467L23.5608 21.2922L28.8691 28.8395H25.2067L21.6925 23.8574L18.0598 28.8543H17.096L11.9805 36.4312L12.1584 36.5646C14.8432 38.25 17.9294 39.1876 21.098 39.2804C24.2666 39.3732 27.4024 38.6178 30.1812 37.0923C32.9599 35.5669 35.2807 33.3268 36.9035 30.6037C38.5263 27.8806 39.3921 24.7735 39.4115 21.6036C39.3843 18.9905 38.7941 16.414 37.681 14.0497C36.5679 11.6854 34.9582 9.5888 32.9615 7.90292Z" fill="url(#paint2_linear)" />
    <path d="M14.3824 28.7062L19.4534 21.7075L14.1452 14.1455H17.7927L21.3069 19.1275L24.91 14.1455H25.859L31.3155 6.73166L31.1376 6.62787C28.197 4.73939 24.7864 3.71209 21.2921 3.66235C18.9646 3.66235 16.66 4.12126 14.5101 5.01283C12.3602 5.9044 10.4072 7.21113 8.76284 8.85826C7.11847 10.5054 5.81501 12.4606 4.92704 14.612C4.03907 16.7634 3.58402 19.0687 3.58792 21.3961C3.61984 24.0099 4.21368 26.5862 5.32915 28.9501C6.44461 31.3141 8.05559 33.4104 10.0527 35.0968L10.29 35.3044L10.4827 35.4675L15.1534 28.7062H14.3824Z" fill="url(#paint3_linear)" />
    <path opacity="0.25" d="M13.1083 27.8758H9.23833V17.0072C9.23833 16.2213 8.82316 15.821 7.96316 15.821H7.13281V14.3382H10.217C10.6152 14.2877 11.0197 14.3304 11.3987 14.4627C11.7777 14.5951 12.1208 14.8136 12.4011 15.101C12.6813 15.3884 12.891 15.737 13.0138 16.1192C13.1365 16.5014 13.1689 16.9069 13.1083 17.3037V27.8758Z" fill="#0D0F0F" />
    <path opacity="0.25" d="M37.2313 27.8906H29.0762V24.3764C29.1224 23.9259 29.2624 23.4899 29.4871 23.0967C29.7118 22.7034 30.0163 22.3615 30.381 22.093L32.7831 20.2247C33.7024 19.4685 34.162 18.8754 34.162 18.475V16.8885C34.162 16.325 33.8062 16.0285 33.1093 16.0285C32.4124 16.0285 32.2048 16.2954 32.2048 16.844V18.0599L29.091 20.3137V17.6595C29.091 15.4947 30.4403 14.3975 33.1389 14.3975C35.8375 14.3975 37.2462 15.2426 37.2462 16.9182V18.9199C37.1957 19.3506 37.0514 19.7651 36.8234 20.134C36.5954 20.5029 36.2892 20.8173 35.9265 21.055L33.5244 22.8937C33.1668 23.1389 32.8655 23.4574 32.6405 23.8281C32.4155 24.1988 32.272 24.6132 32.2196 25.0437V26.0075H37.2165L37.2313 27.8906Z" fill="#0D0F0F" />
    <path d="M13.0048 27.772H9.11999V16.9034C9.11999 16.1176 8.70481 15.7172 7.84481 15.7172H7.0293V14.2345H10.1134C10.5117 14.184 10.9162 14.2266 11.2952 14.359C11.6742 14.4913 12.0173 14.7098 12.2976 14.9972C12.5778 15.2847 12.7875 15.6332 12.9103 16.0154C13.033 16.3976 13.0654 16.8031 13.0048 17.2V27.772Z" fill="white" />
    <path d="M37.0692 27.787H28.9141V24.2728C28.9603 23.8223 29.1002 23.3864 29.325 22.9931C29.5497 22.5999 29.8542 22.258 30.2189 21.9894L32.621 20.1211C33.5403 19.3649 33.9999 18.7718 33.9999 18.3715V16.7701C33.9999 16.2066 33.6441 15.9101 32.9472 15.9101C32.2503 15.9101 32.0427 16.177 32.0427 16.7256V17.9415L28.9437 20.1953V17.5411C28.9437 15.3763 30.293 14.2791 32.9916 14.2791C35.6903 14.2791 37.0692 15.1242 37.0692 16.7997V18.8015C37.0188 19.2322 36.8745 19.6466 36.6465 20.0156C36.4184 20.3845 36.1123 20.6989 35.7496 20.9366L33.3475 22.7753C32.9899 23.0205 32.6885 23.339 32.4636 23.7097C32.2386 24.0804 32.0951 24.4948 32.0427 24.9253V25.8891H37.0692V27.787Z" fill="white" />
  </svg>
);

IconProvider1x2Gaming.propTypes = {
  className: PropTypes.string
};

IconProvider1x2Gaming.defaultProps = {
  className: ''
};
