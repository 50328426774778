import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderRedrake = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.7503 38.068L12.4524 26.7642H12.351L12.1313 28.758C12.0637 29.4226 11.968 30.0872 11.8441 30.7518L10.7289 37.9835H3.37891L7.58615 10.9321H14.6827C15.9907 10.9236 17.2968 11.031 18.5858 11.2531C19.6742 11.4301 20.7217 11.8022 21.6779 12.3514C22.5164 12.8628 23.1999 13.593 23.6548 14.4635C24.1731 15.5124 24.4228 16.6736 24.3813 17.8428C24.3986 18.1861 24.3986 18.5301 24.3813 18.8735C24.3813 19.2227 24.3419 19.5888 24.263 19.9718C24.0225 21.452 23.4444 22.8569 22.5734 24.0776C21.6807 25.335 20.416 26.2806 18.9575 26.7811L22.6579 38.0849H14.7503V38.068ZM13.5168 23.4862C13.9776 23.4912 14.4337 23.393 14.8517 23.199C15.2541 23.0123 15.6158 22.7482 15.9161 22.4218C16.222 22.0922 16.473 21.7157 16.6596 21.3066C16.8455 20.8977 16.9761 20.4658 17.0482 20.0225C17.0482 19.8873 17.0482 19.7521 17.0482 19.6338C17.0482 19.5156 17.0482 19.3973 17.0482 19.279C17.0779 18.9252 17.0263 18.5692 16.8974 18.2383C16.7686 17.9074 16.5659 17.6103 16.3048 17.3697C15.7326 16.9384 15.0265 16.723 14.311 16.7614H14.0237L12.9761 23.52L13.5168 23.4862Z" fill="#DB1D31" />
    <path d="M35.9397 38.0678L33.6418 26.764H33.5404L33.3208 28.7578C33.2532 29.4224 33.1574 30.087 33.0335 30.7516L31.9184 37.9834H24.5684L28.7249 10.932H35.8046C37.1182 10.9225 38.43 11.0299 39.7246 11.253C40.813 11.43 41.8605 11.802 42.8166 12.3513C43.6677 12.8579 44.3632 13.5885 44.8273 14.4634C45.345 15.5125 45.5946 16.6735 45.5539 17.8427C45.5716 18.186 45.5716 18.53 45.5539 18.8734C45.5539 19.2226 45.5145 19.5886 45.4356 19.9716C45.1951 21.4518 44.6169 22.8568 43.746 24.0775C42.8533 25.3349 41.5886 26.2804 40.1301 26.7809L43.9318 38.0678H35.9397ZM34.6387 23.4861C35.0995 23.491 35.5556 23.3929 35.9735 23.1989C36.3759 23.0122 36.7376 22.7481 37.038 22.4216C37.3441 22.0922 37.5951 21.7157 37.7815 21.3065C37.9673 20.8975 38.098 20.4657 38.1701 20.0223C38.1701 19.8872 38.1701 19.752 38.1701 19.6337C38.1701 19.5154 38.1701 19.3972 38.1701 19.2789C38.1997 18.925 38.1482 18.5691 38.0193 18.2382C37.8905 17.9073 37.6878 17.6102 37.4266 17.3696C36.8687 16.9483 36.1821 16.7334 35.4835 16.7613H35.1963L34.1487 23.5199L34.6387 23.4861Z" fill="white" />
  </svg>
);

IconProviderRedrake.propTypes = {
  className: PropTypes.string
};

IconProviderRedrake.defaultProps = {
  className: ''
};

