import React from 'react';

export const IconProviderTomHornGaming = () => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <g transform="translate(2 2)">
        <mask id="icon-provider-tom-horn-gaming-c" fill="#fff">
          <use xlinkHref="#icon-provider-tom-horn-gaming-a" />
        </mask>
        <path
          fill="url(#icon-provider-tom-horn-gaming-b)"
          fillRule="nonzero"
          d="M12.46-8.398L-8.593 4.756l12.86 20.579 21.05-13.154z"
          mask="url(#icon-provider-tom-horn-gaming-c)"
        />
      </g>
      <g transform="translate(4.237 4.895)">
        <mask id="icon-provider-tom-horn-gaming-f" fill="#fff">
          <use xlinkHref="#icon-provider-tom-horn-gaming-d" />
        </mask>
        <path
          fill="url(#icon-provider-tom-horn-gaming-e)"
          fillRule="nonzero"
          d="M5.175 26.355l21.492-13.43-13.3-21.284-21.492 13.43z"
          mask="url(#icon-provider-tom-horn-gaming-f)"
        />
      </g>
    </g>
  </svg>
);
