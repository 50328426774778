import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderQuickSpin = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 198.89 327.7"
    className={classNames('producers-svg-icon', className)}
  >
    <path
      fill="#ff7800"
      d="M499.45,414.65V310.14c0-30.66-9.34-44.52-20.05-53.95-12.14-10.68-33.84-20-79.4-20s-67.26,9.36-79.4,20c-10.71,9.43-20.05,23.29-20.05,53.95V416.75a73.41,73.41,0,0,0,23.55,53.87L352.64,497a6.48,6.48,0,0,0,9.6-.89l24.19-32.61a6.47,6.47,0,0,0-.81-8.61L365.39,436.2a43.2,43.2,0,0,1-13.85-31.69V332.07h0c0-21.95,8.42-31.35,11.61-33.83,3.67-2.86,11.44-9.67,36.85-9.67s33.18,6.81,36.85,9.67c3.19,2.48,11.61,11.88,11.61,33.83V400.4a51.79,51.79,0,0,1-10.2,30.86L378,512.58a6.48,6.48,0,0,0,.81,8.62L400,540.85h0l8.53,7.89,5.6,5.18h0l7,6.47a12.91,12.91,0,0,0,8.79,3.45h56.89a6.47,6.47,0,0,0,4.39-11.23l-45.12-41.76L485,458.37A73.37,73.37,0,0,0,499.45,414.65Z"
      transform="translate(-300.55 -236.15)"
    />
  </svg>
);

IconProviderQuickSpin.propTypes = {
  className: PropTypes.string
};

IconProviderQuickSpin.defaultProps = {
  className: ''
};

