import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderWazdanBig = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} xmlns="http://www.w3.org/2000/svg" width="141" height="78" viewBox="0 0 141 78">
    <defs>
      <path id="icon-producer-wazdan-big-a" d="M0 0h141v78H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="icon-producer-wazdan-big-b" fill="#fff">
        <use xlinkHref="#icon-producer-wazdan-big-a" />
      </mask>
      <path d="M0 0h141v78H0z" />
      <g mask="url(#icon-producer-wazdan-big-b)">
        <path fill="#000" fillRule="nonzero" d="M14.508 46.679L.49 32.688a.84.84 0 01.599-1.443h14.018c.463 0 .844.381.844.844v13.99c0 .736-.898 1.117-1.443.6m3.158-.599V32.089c0-.463.38-.844.844-.844h14.018a.84.84 0 01.599 1.443l-14.019 13.99c-.544.518-1.442.137-1.442-.598m17.176 0V32.089c0-.463.38-.844.844-.844h14.018a.84.84 0 01.599 1.443l-14.019 13.99c-.544.518-1.442.137-1.442-.598M69.792 31.218v9.663l-3.375-6.832h-1.933l-3.429 6.914v-9.745h-3.049v15.706h3.021l4.383-8.547 4.409 8.547h2.995V31.218zm11.488 4.518l1.768 5.526h-3.565l1.796-5.526zm-1.526-4.518l-5.471 15.679h3.402l.953-2.967h5.253l.953 2.967h3.402l-5.469-15.68h-3.022zm9.12 0v2.722h6.396l-6.396 10.888v2.096h10.615v-2.695h-6.56l6.343-10.915v-2.096zm21.122 8.874V38.05c0-2.695-.735-4.11-2.858-4.11h-2.395v10.289h2.395c2.123 0 2.858-1.415 2.858-4.137m-8.438 6.832V31.245h5.58c3.566 0 6.043 1.824 6.043 6.832v2.043c0 4.981-2.477 6.832-6.043 6.832h-5.58v-.027zm19.136-11.188l1.77 5.526h-3.567l1.797-5.526zm-1.525-4.518l-5.47 15.679h3.402l.952-2.967h5.254l.953 2.967h3.402l-5.444-15.68h-3.049zm18.81 0v9.853l-6.152-9.853h-2.641v15.706h3.022v-9.718l6.124 9.718H141V31.218z" />
      </g>
    </g>
  </svg>
);

IconProviderWazdanBig.propTypes = {
  className: PropTypes.string
};

IconProviderWazdanBig.defaultProps = {
  className: ''
};
