import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderAvatarUxBig = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="139"
    height="46"
    viewBox="0 0 139 46"
    fill="none"
  >
    <g clipPath="url(#avatarux-big-1)">
      <path
        d="M61.4843 28.1118H59.2321L58.2008 26.3626L55.7381 22.1605L53.2754 26.3626L52.2475 28.1118H49.9918L51.0232 26.3626L55.7381 18.3103L60.4565 26.3626L61.4843 28.1118Z"
        fill="white"
      />
      <path
        d="M69.8546 18.3103L68.8268 20.0595L64.1084 28.1118L59.3935 20.0595L58.3621 18.3103H60.6178L61.6457 20.0595L64.1084 24.2616L66.5711 20.0595L67.6024 18.3103H69.8546Z"
        fill="white"
      />
      <path
        d="M78.2566 28.1118H76.0044L74.973 26.3626L72.5103 22.1605L70.0476 26.3626L69.0162 28.1118H66.764L67.7954 26.3626L72.5103 18.3103L77.2252 26.3626L78.2566 28.1118Z"
        fill="white"
      />
      <path d="M87.2863 18.3241V20.0732H82.6662V28.1255H80.6525V20.0732H76.0359V18.3241H87.2863Z" fill="white" />
      <path
        d="M96.5898 28.1118H94.3341L93.3062 26.3626L90.8436 22.1605L88.3809 26.3626L87.3495 28.1118H85.0973L86.1252 26.3626L90.8436 18.3103L95.5584 26.3626L96.5898 28.1118Z"
        fill="white"
      />
      <path
        d="M0.0293579 40.5075L18.3521 6.40426L26.7751 0.570312L44.6067 41.991L23.4775 16.3092L0.0293579 40.5075Z"
        fill="white"
      />
      <path d="M14.7705 34.4321L22.141 40.7491L49.3428 15.2743L22.562 46.307L14.7705 34.4321Z" fill="#7FD4F7" />
      <path
        d="M107.247 26.3765L108.279 28.1256H106.037L104.985 26.3765L103.932 24.5411L103.883 24.4583C103.689 24.1928 103.432 23.9777 103.134 23.8311C102.837 23.6845 102.507 23.6109 102.175 23.6165H100.165V28.1256H98.1509V18.3242H105.265C106.051 18.3157 106.813 18.5892 107.409 19.0935C107.682 19.3275 107.902 19.6157 108.055 19.9391C108.207 20.2625 108.288 20.6139 108.293 20.9703C108.277 21.5008 108.099 22.0144 107.783 22.4449C107.468 22.8753 107.028 23.2029 106.521 23.3853C106.244 23.4997 105.951 23.5707 105.651 23.5958L107.247 26.3765ZM105.251 21.8673C105.396 21.8687 105.54 21.8393 105.672 21.7811C105.821 21.7371 105.954 21.6545 106.058 21.5421C106.163 21.4297 106.235 21.2917 106.266 21.1425C106.297 20.9934 106.287 20.8387 106.236 20.6948C106.185 20.5509 106.096 20.4232 105.978 20.3252C105.774 20.1578 105.516 20.0683 105.251 20.0733H100.151V21.8811L105.251 21.8673Z"
        fill="white"
      />
      <path
        d="M124.381 18.3413V25.9313C124.381 27.1664 123.455 28.1566 122.343 28.1566H113.38C112.254 28.1566 111.345 27.1664 111.345 25.9313V18.3413H112.166V25.9313C112.15 26.2651 112.269 26.5914 112.496 26.8399C112.723 27.0883 113.041 27.2391 113.38 27.2596H122.343C122.682 27.2391 122.999 27.0883 123.226 26.8399C123.453 26.5914 123.572 26.2651 123.557 25.9313V18.3413H124.381Z"
        fill="#7FD4F7"
      />
      <path
        d="M133.751 23.2403L139.567 28.1393H138.234L133.085 23.8027L127.952 28.1393H126.602L132.418 23.2403L126.602 18.3413H127.952L133.085 22.678L138.234 18.3413H139.567L133.751 23.2403Z"
        fill="#7FD4F7"
      />
    </g>
  </svg>
);

IconProviderAvatarUxBig.propTypes = {
  className: PropTypes.string,
};
IconProviderAvatarUxBig.defaultProps = {
  className: null,
};
