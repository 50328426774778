import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderEgt = ({ className }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="url(#icon-provider-egt-a)"
        d="M4.8 8.4c0-2.064 1.968-4.752 5.328-6.192 5.856-2.544 9.216-.192 10.08 2.016.816 2.064-.816 5.76-3.552 8.448 1.968-1.44 3.6-2.928 4.704-4.416 1.488-1.968 2.112-3.84 1.536-5.328C22.08.768 18.768.048 14.64.624 10.512 1.2 7.344 2.256 4.8 3.6 1.92 5.04.48 6.72.24 8.688c-.288 2.832 2.784 6.672 9.744 7.152 3.648.24 7.2.336 13.968-1.344-.816.048-19.2 2.352-19.152-6.096z"
        transform="translate(0 4)"
      />
      <path
        fill="#A5CD39"
        d="M11.568 15.712c2.16 0 3.984-1.776 3.984-3.984 0-2.16-1.776-3.984-3.984-3.984-2.16 0-3.984 1.776-3.984 3.984.048 2.208 1.824 3.984 3.984 3.984z"
      />
      <g transform="translate(7.536 7.36)">
        <mask id="icon-provider-egt-d" fill="#fff">
          <use xlinkHref="#icon-provider-egt-b" />
        </mask>
        <use fill="#A5CD39" xlinkHref="#icon-provider-egt-b" />
        <path
          fill="url(#icon-provider-egt-c)"
          d="M2.256 6.432c2.544 0 4.656-2.112 4.656-4.656C6.912-.768 4.8-2.88 2.256-2.88-.288-2.88-2.4-.768-2.4 1.776a4.66 4.66 0 0 0 4.656 4.656z"
          mask="url(#icon-provider-egt-d)"
          opacity=".36"
        />
        <path
          fill="url(#icon-provider-egt-e)"
          d="M5.136 6.624c2.016 0 3.648-1.632 3.648-3.696S7.152-.768 5.136-.768 1.488.864 1.488 2.928 3.12 6.624 5.136 6.624z"
          mask="url(#icon-provider-egt-d)"
          opacity=".71"
        />
      </g>
    </g>
  </svg>
);

IconProviderEgt.propTypes = {
  className: PropTypes.string,
};

IconProviderEgt.defaultProps = {
  className: '',
};
