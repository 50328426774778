import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProvider3oaksGamingBig = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} xmlns="http://www.w3.org/2000/svg" width="124" height="21" viewBox="0 0 124 21" fill="none">
    <g clipPath="url(#icon-provider-3-oks-gaming-big-clip)">
      <mask id="mask0_4809_196564" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="124" height="21">
        <path d="M124 0H0V21H124V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_4809_196564)">
        <path fillRule="evenodd" clipRule="evenodd" d="M37.2045 0H43.7966C43.5276 0.21959 43.3088 0.497439 43.1857 0.891807C42.2785 0.932138 41.3667 1.05762 40.4595 1.27721C39.4429 1.52369 38.5585 2.11972 38.5585 3.14597C38.5585 3.4776 38.6497 3.79129 38.8047 4.06466L38.7956 3.84955L38.9323 3.78233C39.0554 3.71959 39.1876 3.66581 39.3153 3.61204C39.6435 3.4776 39.9854 3.36555 40.3273 3.27145C42.3332 2.71575 45.2873 2.71575 47.2932 3.27145C47.6351 3.36555 47.977 3.48207 48.3053 3.61204C48.4329 3.66133 48.5651 3.71959 48.6882 3.78233L48.825 3.84955L48.8159 4.06466C48.9709 3.79129 49.062 3.4776 49.062 3.14597C49.062 2.11972 48.1776 1.51472 47.161 1.27721C46.1945 1.05314 45.2281 0.918692 44.2616 0.887324C44.4622 0.636363 44.7722 0.475032 45.0822 0.318182C45.2782 0.215109 45.4742 0.116517 45.652 0H50.416C51.0633 0 51.5922 0.519846 51.5922 1.15621V19.8393C51.5922 20.4757 51.0633 20.9955 50.416 20.9955H37.2045C36.5572 20.9955 36.0283 20.4757 36.0283 19.8393V1.15621C36.0238 0.519846 36.5526 0 37.2045 0ZM44.795 9.09731C46.4681 8.16071 47.2339 7.08068 47.3297 5.29258C46.6504 7.47952 45.6566 8.53713 44.795 9.09731ZM39.1511 6.30089C39.3882 9.43793 42.8347 9.70232 43.8103 11.477C44.7859 9.70232 48.2323 9.43341 48.4694 6.30089L48.5788 3.98848C46.2219 2.78297 41.4032 2.78297 39.0463 3.98848L39.1511 6.30089Z" fill="url(#icon-provider-3-oks-gaming-big-gradient-1)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M19.1879 0H32.3994C33.0467 0 33.5755 0.519846 33.5755 1.15621V19.8393C33.5755 20.4757 33.0467 20.9955 32.3994 20.9955H19.1879C18.5405 20.9955 18.0117 20.4757 18.0117 19.8393V1.15621C18.0117 0.519846 18.5405 0 19.1879 0ZM26.8513 14.372C28.5244 13.4353 29.2903 12.3553 29.386 10.5672C28.7067 12.7541 27.7129 13.8118 26.8513 14.372ZM21.1391 11.7458C21.3761 14.8829 24.8226 15.1517 25.7982 16.9219C26.7738 15.1472 30.2202 14.8783 30.4573 11.7458L30.5667 9.43341C28.2098 8.22791 23.3911 8.22791 21.0342 9.43341L21.1391 11.7458ZM25.1737 6.33675C24.2664 6.37707 23.3547 6.50255 22.4475 6.72215C21.4308 6.96864 20.5464 7.56467 20.5464 8.59089C20.5464 8.92253 20.6376 9.23622 20.7926 9.50959L20.7835 9.2945L20.9203 9.2273C21.0434 9.16456 21.1755 9.11075 21.3032 9.05699C21.6314 8.92253 21.9734 8.8105 22.3153 8.71637C24.3211 8.16071 27.2753 8.16071 29.2811 8.71637C29.6231 8.8105 29.965 8.927 30.2932 9.05699C30.4208 9.10628 30.5531 9.16456 30.6761 9.2273L30.8129 9.2945L30.8038 9.50959C30.9588 9.23622 31.05 8.92253 31.05 8.59089C31.05 7.56467 30.1655 6.95966 29.1489 6.72215C28.1825 6.49808 27.216 6.36363 26.2495 6.33229C26.4501 6.08128 26.7601 5.91995 27.0701 5.76314C27.7539 5.40908 28.4195 5.06402 28.5016 4.07362C27.2342 4.23048 27.8725 4.17222 26.6234 4.92958C26.0216 5.24328 25.4061 5.56148 25.1737 6.33675Z" fill="url(#icon-provider-3-oks-gaming-big-gradient-2)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M1.17618 0H3.07721L3.12279 0.981435C3.35985 4.11844 6.80634 4.38284 7.78193 6.15751C8.75747 4.38284 12.204 4.11396 12.441 0.981435L12.4866 0H14.3876C15.035 0 15.5638 0.519846 15.5638 1.15621V19.8393C15.5638 20.4757 15.035 20.9955 14.3876 20.9955H12.4912L12.5504 19.7542C10.1935 18.5487 5.37485 18.5487 3.01794 19.7542L3.07721 20.9955H1.17618C0.528823 21 0 20.4757 0 19.8393V1.15621C0 0.519846 0.528823 0 1.17618 0ZM11.3925 0C10.7224 1.86876 9.81515 2.81434 9.01738 3.32971C10.5354 2.47823 11.3059 1.50576 11.5065 0H11.3925ZM7.16193 16.662C6.2547 16.7023 5.34294 16.8278 4.43573 17.0474C3.41912 17.2939 2.53471 17.8899 2.53471 18.9161C2.53471 19.2478 2.62588 19.5614 2.78088 19.8348L2.77176 19.6197L2.90853 19.5525C3.03162 19.4897 3.16382 19.436 3.29147 19.3822C3.61971 19.2478 3.96162 19.1357 4.30353 19.0416C6.30941 18.4859 9.26351 18.4859 11.2694 19.0416C11.6113 19.1357 11.9532 19.2522 12.2815 19.3822C12.4091 19.4315 12.5413 19.4897 12.6644 19.5525L12.8012 19.6197L12.7921 19.8348C12.9471 19.5614 13.0382 19.2478 13.0382 18.9161C13.0382 17.8899 12.1538 17.2849 11.1372 17.0474C10.1707 16.8233 9.20426 16.6889 8.23777 16.6575C8.43836 16.4065 8.74836 16.2452 9.05836 16.0884C9.74223 15.7343 10.4078 15.3892 10.4899 14.3989C9.22253 14.5557 9.86074 14.4975 8.61164 15.2548C8.00985 15.564 7.3944 15.8822 7.16193 16.662Z" fill="url(#icon-provider-3-oks-gaming-big-gradient-3)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M64.352 12.0775C67.001 12.0775 68.6284 10.4597 68.6284 8.25933V8.22799C68.6284 6.05895 67.051 5.22541 65.4373 4.95652L68.4419 2.1377V0.201721H60.4137V2.40211H65.0818L62.2462 5.203L62.6517 6.83873H63.737C65.1959 6.83873 66.0617 7.37203 66.0617 8.32207V8.35346C66.0617 9.22286 65.3643 9.7696 64.3795 9.7696C63.1762 9.7696 62.328 9.26764 61.5121 8.3669L59.7207 10.0609C60.7374 11.244 62.2144 12.0775 64.352 12.0775Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M81.899 12.0775C85.5642 12.0775 88.2269 9.35723 88.2269 6.0365V6.00511C88.2269 2.68438 85.596 0 81.9309 0C78.2657 0 75.603 2.72023 75.603 6.0365V6.06784C75.603 9.39309 78.2339 12.0775 81.899 12.0775ZM81.9309 9.70678C79.8245 9.70678 78.3338 8.03969 78.3338 6.0365V6.00511C78.3338 4.00192 79.7927 2.37068 81.899 2.37068C84.0054 2.37068 85.4961 4.03777 85.4961 6.04097V6.07236C85.4961 8.07555 84.0372 9.70678 81.9309 9.70678Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M88.7695 11.8759H91.4317L92.5164 9.25874H97.5406L98.6253 11.8759H101.356L96.2732 0.116638H93.8618L88.7695 11.8759ZM93.4557 6.99116L95.0332 3.20435L96.6106 6.99116H93.4557Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M103.194 11.8758H105.81V8.30863L107.183 6.90593L110.866 11.8758H114.003L108.947 5.15371L113.784 0.201721H110.629L105.81 5.3061V0.201721H103.194V11.8758Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M119.637 12.0461C122.199 12.0461 124 10.7465 124 8.42515V8.39375C124 6.35917 122.641 5.50772 120.234 4.88928C118.183 4.37392 117.672 4.12296 117.672 3.35663V3.32078C117.672 2.75164 118.197 2.3035 119.2 2.3035C120.203 2.3035 121.237 2.7382 122.286 3.45522L123.644 1.51924C122.441 0.569179 120.964 0.0358887 119.232 0.0358887C116.806 0.0358887 115.074 1.43858 115.074 3.55381V3.58518C115.074 5.9021 116.619 6.55636 119.013 7.1569C121 7.6588 121.406 7.99045 121.406 8.64024V8.67163C121.406 9.35728 120.763 9.77403 119.692 9.77403C118.333 9.77403 117.216 9.22283 116.145 8.35789L114.6 10.1774C116.022 11.4277 117.841 12.0461 119.637 12.0461Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M62.328 20.9999C63.2355 20.9999 63.9373 20.6503 64.4481 20.2246V18.1093H62.2918V19.037H63.3902V19.7316C63.1121 19.9288 62.7615 20.0229 62.3648 20.0229C61.4847 20.0229 60.8647 19.3687 60.8647 18.4768V18.4634C60.8647 17.6343 61.494 16.9442 62.2874 16.9442C62.8619 16.9442 63.2037 17.1234 63.5817 17.4371L64.2703 16.6215C63.7551 16.1913 63.2125 15.9538 62.3236 15.9538C60.8236 15.9538 59.7251 17.0876 59.7251 18.4768V18.4903C59.7207 19.9333 60.783 20.9999 62.328 20.9999Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M71.8745 20.9149H72.9867L73.4382 19.8214H75.5353L75.9868 20.9149H77.1264L75.002 16.0032H73.9946L71.8745 20.9149ZM73.8305 18.8758L74.4911 17.2939L75.1523 18.8758H73.8305Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M84.7075 20.915H85.7741V17.7511L87.1557 19.817H87.1875L88.5828 17.7332V20.915H89.6675V16.0392H88.4917L87.1875 18.1007L85.8839 16.0392H84.7075V20.915Z" fill="black" />
        <path d="M98.8035 16.0392H97.7139V20.915H98.8035V16.0392Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M106.85 20.915H107.93V17.8138L110.333 20.915H111.258V16.0392H110.182V19.0417L107.857 16.0392H106.85V20.915Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M121.643 20.9999C122.55 20.9999 123.253 20.6503 123.763 20.2246V18.1093H121.607V19.037H122.706V19.7316C122.428 19.9288 122.076 20.0229 121.68 20.0229C120.8 20.0229 120.175 19.3687 120.175 18.4768V18.4634C120.175 17.6343 120.805 16.9442 121.598 16.9442C122.172 16.9442 122.514 17.1234 122.892 17.4371L123.581 16.6215C123.066 16.1913 122.523 15.9538 121.634 15.9538C120.135 15.9538 119.036 17.0876 119.036 18.4768V18.4903C119.036 19.9333 120.102 20.9999 121.643 20.9999Z" fill="black" />
      </g>
    </g>
  </svg>
);

IconProvider3oaksGamingBig.propTypes = {
  className: PropTypes.string
};

IconProvider3oaksGamingBig.defaultProps = {
  className: ''
};
