import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderApolloBig = ({ className }) => (
  <svg className={classNames(className)} width="157" height="72" viewBox="0 0 157 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#icon-provider-apollo-big)">
      <path
        d="M80.787 35.0132L82.4194 33.3685C82.4194 33.3685 81.9979 33.6639 78.2892 30.3768C74.5805 27.0874 73.4744 26.1901 73.4744 26.1901C73.4744 26.1901 74.1233 26.9106 77.7317 30.9496C80.6866 34.2591 80.8316 34.8946 80.7892 35.0154L80.787 35.0132ZM87.4751 30.9295C90.74 27.1926 91.6321 26.0782 91.6321 26.0782C91.6321 26.0782 90.9184 26.7338 86.9064 30.3678C83.6215 33.3439 82.9926 33.4893 82.8721 33.4446L84.5046 35.0893C84.5046 35.0893 84.2124 34.6664 87.4751 30.9295ZM82.8721 33.4446L82.8431 33.4155C82.8431 33.4155 82.8454 33.4356 82.8721 33.4446ZM77.7674 39.5377C74.5025 43.2746 73.6126 44.389 73.6126 44.389C73.6126 44.389 74.3263 43.7356 78.3383 40.1016C81.6233 37.1255 82.2522 36.9779 82.3726 37.0226L80.7401 35.3779C80.7401 35.3779 81.0345 35.8008 77.7696 39.5377H77.7674ZM82.3726 37.0204L82.4016 37.0495C82.4016 37.0495 82.4016 37.0293 82.3726 37.0204ZM87.6424 39.4661C84.6875 36.1566 84.5425 35.5234 84.5871 35.4025L82.9547 37.0472C82.9547 37.0472 83.3761 36.7519 87.0849 40.0412C90.7958 43.3283 91.8997 44.2279 91.8997 44.2279C91.8997 44.2279 91.2507 43.5074 87.6424 39.4661Z"
        fill="#96C8D8"
      />
      <path
        d="M100.829 35.1295C100.829 35.1295 97.3323 35.0826 91.9465 34.7961C88.8912 34.6328 86.7905 34.418 85.5394 34.2613C84.237 34.042 83.8824 33.6482 83.704 32.413C83.5278 31.0839 83.278 28.8753 83.0929 25.9551C82.7785 20.9875 82.6268 17 82.6268 17C82.6268 17 82.58 20.5355 82.2945 25.9618C82.1473 28.7858 81.9555 30.8041 81.8039 32.0908C81.621 33.6907 81.291 34.0801 79.6005 34.3083C78.258 34.4806 76.1907 34.7089 73.519 34.8789C68.5881 35.1967 64.6453 35.3488 64.6453 35.3488C64.6453 35.3488 68.1421 35.3958 73.5279 35.6845C76.5631 35.8456 78.6572 36.0582 79.9105 36.2148C81.233 36.4341 81.592 36.8235 81.7727 38.0698C81.7838 38.1571 82.212 41.8224 82.3837 44.5233C82.6982 49.4909 82.8498 53.4784 82.8498 53.4784C82.8498 53.4784 82.8966 49.9429 83.1799 44.5165C83.3471 41.3279 83.5702 39.1685 83.7285 37.9266C83.9292 36.7116 84.3552 36.3759 85.8694 36.1701C87.2097 35.9978 89.2815 35.7695 91.9577 35.5972C96.8885 35.2795 100.831 35.1273 100.831 35.1273L100.829 35.1295Z"
        fill="#96C8D8"
      />
      <path
        d="M15.1097 45.3243C15.0896 45.2885 15.0495 45.2662 15.0071 45.2662H13.5731C13.5308 45.2662 13.4929 45.2885 13.4728 45.3243L8.01566 54.8232C7.99559 54.859 7.99559 54.9038 8.01566 54.9418C8.03797 54.9776 8.07588 55.0022 8.11825 55.0022H9.58568C9.62582 55.0022 9.66596 54.9799 9.68603 54.9441C9.68603 54.9441 10.6093 53.3665 10.674 53.2569H17.9085C17.9732 53.3665 18.8965 54.9441 18.8965 54.9441C18.9188 54.9799 18.9567 55.0022 18.9991 55.0022H20.4799C20.5222 55.0022 20.5601 54.9799 20.5802 54.9418C20.6025 54.906 20.6025 54.8613 20.5802 54.8232L15.1097 45.3243ZM17.0388 51.7263H11.5437C11.7266 51.4063 14.1039 47.2801 14.289 46.9623C14.4719 47.2801 16.8537 51.4063 17.0388 51.7263Z"
        fill="#96C8D8"
      />
      <path
        d="M30.3192 45.622C29.5096 45.387 28.3076 45.2662 26.7509 45.2662H21.7688C21.7042 45.2662 21.6506 45.3199 21.6506 45.3848V54.8836C21.6506 54.9485 21.7019 55.0022 21.7688 55.0022H23.0578C23.1225 55.0022 23.176 54.9485 23.176 54.8836V50.9073H26.7398C28.3076 50.9073 29.5118 50.7865 30.3236 50.5515C31.7888 50.1331 32.5337 49.3051 32.5337 48.0901C32.5337 46.875 31.7888 46.0404 30.3236 45.622H30.3192ZM31.0038 48.0901C31.0038 48.9449 29.7326 49.3768 27.2282 49.3768H23.1738V46.7967H27.1278C28.4392 46.7967 29.4338 46.9131 30.085 47.1436C30.705 47.3629 31.006 47.6717 31.006 48.0901H31.0038Z"
        fill="#96C8D8"
      />
      <path
        d="M43.6308 46.2485C42.6474 45.5951 41.2178 45.2662 39.378 45.2662C37.5381 45.2662 36.1086 45.5973 35.1251 46.2485C33.9476 47.0473 33.3522 48.3564 33.3522 50.1398C33.3522 51.9232 33.9476 53.2367 35.1251 54.0356C36.1042 54.6755 37.5359 54.9978 39.378 54.9978C41.2201 54.9978 42.6518 54.6733 43.6308 54.0356C44.8084 53.2345 45.4038 51.9232 45.4038 50.1376C45.4038 48.3519 44.8084 47.0451 43.6308 46.2463V46.2485ZM42.5671 52.8944C41.8936 53.2904 40.8209 53.4896 39.378 53.4896C37.9351 53.4896 36.8557 53.2904 36.1822 52.8944C35.3169 52.3909 34.8776 51.4645 34.8776 50.1398C34.8776 48.8151 35.3169 47.8798 36.1822 47.3785C36.8512 46.9914 37.9262 46.7967 39.378 46.7967C40.8298 46.7967 41.8936 46.9959 42.5671 47.3919C43.4391 47.8999 43.8784 48.824 43.8784 50.1398C43.8784 51.4555 43.4391 52.3864 42.5671 52.8944Z"
        fill="#96C8D8"
      />
      <path
        d="M56.4005 53.4918H48.3252V45.3848C48.3252 45.3199 48.2739 45.2662 48.2092 45.2662H46.918C46.8533 45.2662 46.7998 45.3199 46.7998 45.3848V54.8836C46.7998 54.9485 46.8533 55.0022 46.918 55.0022H56.4005C56.4652 55.0022 56.5187 54.9485 56.5187 54.8836V53.6126C56.5187 53.5478 56.4652 53.494 56.4005 53.494V53.4918Z"
        fill="#96C8D8"
      />
      <path
        d="M67.2234 53.4918H59.1481V45.3848C59.1481 45.3199 59.0968 45.2662 59.0299 45.2662H57.7409C57.6762 45.2662 57.6227 45.3199 57.6227 45.3848V54.8836C57.6227 54.9485 57.6762 55.0022 57.7409 55.0022H67.2234C67.2881 55.0022 67.3416 54.9485 67.3416 54.8836V53.6126C67.3416 53.5478 67.2881 53.494 67.2234 53.494V53.4918Z"
        fill="#96C8D8"
      />
      <path
        d="M77.5913 46.2485C76.6078 45.5951 75.1761 45.2662 73.3362 45.2662C71.4964 45.2662 70.0669 45.5973 69.0834 46.2485C67.9059 47.0473 67.3104 48.3564 67.3104 50.1398C67.3104 51.9232 67.9059 53.2367 69.0811 54.0356C70.0624 54.6755 71.4941 54.9978 73.334 54.9978C75.1739 54.9978 76.6078 54.6733 77.5869 54.0356C78.7644 53.2345 79.3598 51.9232 79.3598 50.1376C79.3598 48.3519 78.7644 47.0451 77.5891 46.2463L77.5913 46.2485ZM76.5253 52.8944C75.854 53.2904 74.7814 53.4896 73.3362 53.4896C71.8911 53.4896 70.814 53.2904 70.1405 52.8944C69.2752 52.3909 68.8358 51.4645 68.8358 50.1398C68.8358 48.8151 69.2752 47.8798 70.1405 47.3785C70.8095 46.9914 71.8844 46.7967 73.3362 46.7967C74.788 46.7967 75.854 46.9959 76.5253 47.3919C77.3973 47.8999 77.8389 48.824 77.8389 50.1398C77.8389 51.4555 77.3973 52.3864 76.5253 52.8944Z"
        fill="#96C8D8"
      />
      <path
        d="M97.3993 49.3745H91.9912C91.9243 49.3745 91.8708 49.426 91.8708 49.4931V50.7865C91.8708 50.8514 91.9243 50.9051 91.9912 50.9051H95.9921V52.2208C95.5951 52.7377 95.0041 53.1002 94.2303 53.2949C93.9649 53.3576 93.6482 53.4068 93.2936 53.4403C92.9368 53.4739 92.5198 53.4918 92.0537 53.4918C90.6197 53.4918 89.5426 53.2927 88.8557 52.8944C87.9904 52.3931 87.551 51.4645 87.551 50.1398C87.551 48.8151 87.9904 47.8932 88.8557 47.3919C89.5292 46.9959 90.6041 46.7967 92.0537 46.7967C93.0327 46.7967 93.8043 46.8728 94.344 47.025C95.1625 47.2465 95.7668 47.6649 96.1415 48.2669C96.175 48.3183 96.2396 48.3385 96.2932 48.3116L97.4595 47.7477C97.4885 47.7343 97.5108 47.7075 97.522 47.6761C97.5309 47.6448 97.5264 47.6112 97.5108 47.5821C96.6411 46.0449 94.8057 45.2662 92.0537 45.2662C90.2138 45.2662 88.7821 45.5973 87.8008 46.2485C86.6211 47.0473 86.0256 48.3564 86.0256 50.1398C86.0256 51.9232 86.6233 53.2233 87.7986 54.0221C88.7776 54.6711 90.2094 55 92.0537 55C93.6973 55 95.0153 54.7225 95.9921 54.1944V54.8814C95.9921 54.9463 96.0456 55 96.1103 55H97.4015C97.4662 55 97.5197 54.9463 97.5197 54.8814V49.4931C97.5197 49.426 97.4684 49.3745 97.4015 49.3745H97.3993Z"
        fill="#96C8D8"
      />
      <path
        d="M110.88 54.8232L105.41 45.3243C105.39 45.2885 105.35 45.2662 105.307 45.2662H103.873C103.833 45.2662 103.793 45.2885 103.773 45.3243L98.3158 54.8232C98.2958 54.859 98.2958 54.9038 98.3158 54.9418C98.3359 54.9776 98.376 55.0022 98.4184 55.0022H99.8858C99.9282 55.0022 99.9661 54.9799 99.9884 54.9441C99.9884 54.9441 100.912 53.3665 100.976 53.2569H108.213C108.276 53.3665 109.201 54.9441 109.201 54.9441C109.221 54.9799 109.259 55.0022 109.301 55.0022H110.782C110.825 55.0022 110.863 54.9799 110.883 54.9418C110.905 54.906 110.905 54.8613 110.883 54.8232H110.88ZM107.339 51.7263H101.844C102.027 51.4063 104.404 47.2801 104.587 46.9623C104.77 47.2801 107.154 51.4063 107.337 51.7263H107.339Z"
        fill="#96C8D8"
      />
      <path
        d="M123.699 45.2662H122.43C122.39 45.2662 122.35 45.2885 122.33 45.3243C122.33 45.3243 117.939 52.966 117.743 53.3061C117.546 52.966 113.151 45.3243 113.151 45.3243C113.131 45.2885 113.091 45.2662 113.048 45.2662H111.788C111.721 45.2662 111.67 45.3199 111.67 45.3848V54.8836C111.67 54.9485 111.721 55.0022 111.788 55.0022H113.077C113.142 55.0022 113.195 54.9485 113.195 54.8836V48.4593C113.588 49.1418 116.929 54.9441 116.929 54.9441C116.951 54.9799 116.989 55.0045 117.031 55.0045H118.456C118.496 55.0045 118.536 54.9821 118.557 54.9441C118.557 54.9441 121.9 49.1418 122.29 48.4593V54.8836C122.29 54.9485 122.343 55.0022 122.41 55.0022H123.699C123.764 55.0022 123.815 54.9485 123.815 54.8836V45.3848C123.815 45.3199 123.764 45.2662 123.699 45.2662Z"
        fill="#96C8D8"
      />
      <path
        d="M135.403 46.7967C135.47 46.7967 135.521 46.743 135.521 46.6781V45.3848C135.521 45.3199 135.47 45.2662 135.403 45.2662H125.923C125.858 45.2662 125.804 45.3199 125.804 45.3848V54.8836C125.804 54.9485 125.858 55.0022 125.923 55.0022H135.403C135.47 55.0022 135.521 54.9485 135.521 54.8836V53.6126C135.521 53.5478 135.47 53.494 135.403 53.494H127.33V50.9095H135.403C135.47 50.9095 135.521 50.8558 135.521 50.791V49.4976C135.521 49.4305 135.47 49.379 135.403 49.379H127.33V46.799H135.403V46.7967Z"
        fill="#96C8D8"
      />
      <path
        d="M146.777 49.737C145.972 49.4976 144.763 49.3745 143.188 49.3745L141.487 49.3432C141.032 49.323 140.668 49.294 140.407 49.2582C139.125 49.0747 138.474 48.6831 138.474 48.0879C138.474 47.2196 139.854 46.7811 142.58 46.7811L144.254 46.8079C144.703 46.8258 145.064 46.8527 145.325 46.8885C146.529 47.0429 147.245 47.374 147.455 47.8708C147.477 47.9245 147.535 47.9536 147.591 47.9379L148.844 47.6224C148.875 47.6135 148.902 47.5956 148.92 47.5665C148.935 47.5396 148.94 47.5061 148.931 47.4747C148.654 46.4946 147.789 45.8345 146.362 45.5145C145.617 45.3467 144.477 45.2639 142.979 45.2639C141.306 45.2639 140.062 45.3713 139.272 45.5839C137.731 45.9956 136.951 46.8392 136.951 48.0856C136.951 49.332 137.684 50.104 139.132 50.5314C139.959 50.7775 141.143 50.9028 142.646 50.9028L144.404 50.9297C144.87 50.9476 145.233 50.9744 145.488 51.0102C146.808 51.1915 147.475 51.592 147.475 52.194C147.475 53.0622 146.101 53.5008 143.391 53.5008L141.759 53.4739C141.32 53.456 140.958 53.4292 140.686 53.3934C139.469 53.2434 138.735 52.9234 138.507 52.4423C138.483 52.3909 138.425 52.364 138.369 52.3797L137.113 52.731C137.082 52.74 137.058 52.7623 137.042 52.7892C137.027 52.8183 137.024 52.8518 137.033 52.8809C137.334 53.8364 138.204 54.4741 139.618 54.774C140.354 54.9217 141.482 54.9978 142.974 54.9978C144.622 54.9978 145.867 54.8948 146.679 54.6912C148.222 54.2772 149.002 53.4381 149.002 52.194C149.002 50.9498 148.253 50.1555 146.779 49.7325L146.777 49.737Z"
        fill="#96C8D8"
      />
    </g>
  </svg>
);

IconProviderApolloBig.propTypes = {
  className: PropTypes.string
};

IconProviderApolloBig.defaultProps = {
  className: ''
};
