import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderPlayngo = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.1757 0H7.39458C5.16993 7.98652e-06 3.20014 1.43731 2.52134 3.55586L0.702296 9.23388C0.0239261 11.3508 1.60329 13.5149 3.82603 13.5149H15.7537C18.0232 13.515 20.0217 12.0203 20.6628 9.84314L22.3222 4.20712C22.9411 2.10596 21.3661 0 19.1757 0Z" fill="black" />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.1322 1.22553C19.4614 1.22245 19.7881 1.28274 20.0945 1.4031C20.9434 1.74207 21.404 2.60195 21.2194 3.53793C21.1166 4.06349 20.953 4.57357 20.7897 5.08249C20.7153 5.31432 20.641 5.54591 20.5725 5.77861C20.292 6.72831 20.0007 7.67576 19.7096 8.62276C19.6377 8.85681 19.5657 9.09083 19.4939 9.32485C18.9743 11.0206 17.2699 12.2833 15.502 12.2833H14.287C14.1137 12.275 13.9252 12.2778 13.7275 12.2808C13.4154 12.2856 13.0805 12.2907 12.7466 12.2526C11.7782 12.142 11.0915 11.4037 11.0408 10.4283C11.015 9.93761 11.1501 9.47924 11.2918 9.01912C11.7977 7.37197 12.3022 5.72452 12.8052 4.07678C13.3112 2.41869 14.9094 1.22859 16.6392 1.22553H19.1322ZM16.6886 9.3629C17.2149 7.63323 17.7462 5.90501 18.2824 4.17825C18.3332 4.01555 18.3012 3.97662 18.1372 3.97793C17.3654 3.98406 16.5936 3.98406 15.8217 3.97793C15.6888 3.97662 15.6341 4.01992 15.5947 4.14632C15.0606 5.88125 14.5201 7.61427 13.9734 9.34541C13.917 9.52429 13.9791 9.53873 14.1269 9.5361C14.4042 9.5317 14.6817 9.5325 14.9591 9.53329C15.0676 9.5336 15.1762 9.53392 15.2846 9.53392C15.3918 9.53392 15.499 9.53341 15.6062 9.5329C15.8849 9.53159 16.1636 9.53027 16.4419 9.53785C16.5828 9.54135 16.6475 9.49761 16.6886 9.3629Z" fill="#FE1323" />
    <path d="M9.88742 1.23077C10.7692 1.23077 11.6514 1.23559 12.5327 1.22684C12.7304 1.22465 12.7479 1.28151 12.6914 1.45252C12.4366 2.23368 12.1871 3.016 11.9431 3.79948C11.8993 3.94557 11.8359 3.98843 11.6863 3.98799C10.0318 3.98231 8.3776 3.98799 6.72344 3.98056C6.54281 3.98056 6.46102 4.02429 6.40459 4.20799C5.88455 5.90457 5.35489 7.59503 4.82523 9.28767C4.75 9.5291 4.75088 9.52998 4.99362 9.53042C5.72972 9.53042 6.46583 9.52736 7.20193 9.53348C7.34145 9.53348 7.40618 9.49411 7.44511 9.35372C7.56058 8.9334 7.68479 8.51483 7.82432 8.10195C7.88642 7.91781 7.82432 7.89201 7.66205 7.89682C7.38519 7.90557 7.10789 7.89682 6.83104 7.90119C6.68583 7.90425 6.63334 7.86839 6.68495 7.71225C6.8981 7.07076 7.10542 6.42753 7.3069 5.78254C7.34189 5.67014 7.40094 5.64215 7.51159 5.64215C8.72837 5.64565 9.94559 5.6474 11.1624 5.64215C11.343 5.64215 11.3487 5.70688 11.305 5.84815C10.9253 7.07718 10.5824 8.31845 10.163 9.53392C9.68185 10.9265 8.72312 11.852 7.27147 12.2198C7.10936 12.2616 6.94287 12.2839 6.77549 12.2863C5.77696 12.2924 4.77843 12.299 3.78034 12.2863C2.88285 12.2749 2.16467 11.7483 1.92762 10.9549C1.78328 10.4708 1.81915 9.98835 1.96698 9.51423C2.54869 7.64751 3.12603 5.77992 3.72217 3.91801C4.22384 2.35264 5.79052 1.23252 7.43986 1.23209C8.25542 1.23092 9.07127 1.23048 9.88742 1.23077Z" fill="#FE1323" />
  </svg>
);

IconProviderPlayngo.propTypes = {
  className: PropTypes.string
};

IconProviderPlayngo.defaultProps = {
  className: ''
};
