import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderOryxBig = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} xmlns="http://www.w3.org/2000/svg" width="141" height="24" viewBox="0 0 141 24">
    <g fill="none" fillRule="evenodd">
      <path fill="#000" d="M131.884 16.596c-.918-1.042-1.377-2.468-1.377-4.279 0-2.058.438-3.658 1.312-4.8.874-1.14 2.266-1.711 4.176-1.711 1.277 0 2.418.167 3.423.502v.912c-1.042-.348-2.146-.521-3.312-.521-1.661 0-2.843.48-3.543 1.441-.7.962-1.051 2.354-1.051 4.177 0 3.311 1.537 4.967 4.613 4.967.57 0 1.141-.05 1.712-.149.57-.099 1.06-.235 1.47-.41v-4.26h-3.405v-.818h4.335v5.6c-.397.26-.943.477-1.638.65-.694.174-1.556.261-2.585.261-1.836 0-3.213-.52-4.13-1.562M93.246 5.992h1.303l4.52 11.98h-1.042l-1.32-3.497h-5.619l-1.32 3.497h-1.06l4.538-11.98zm3.219 7.646L93.879 6.81l-2.568 6.828h5.154zM101.022 5.992L102.213 5.992 106.547 14.865 110.882 5.992 112.072 5.992 112.072 17.972 111.068 17.972 111.068 7.48 106.957 15.888 106.156 15.888 102.027 7.48 102.027 17.972 101.022 17.972zM78.308 16.596c-.918-1.042-1.376-2.468-1.376-4.279 0-2.058.437-3.658 1.311-4.8.874-1.14 2.267-1.711 4.176-1.711 1.278 0 2.419.167 3.423.502v.912c-1.041-.348-2.145-.521-3.311-.521-1.662 0-2.843.48-3.544 1.441-.7.962-1.05 2.354-1.05 4.177 0 3.311 1.537 4.967 4.613 4.967.57 0 1.14-.05 1.711-.149.57-.099 1.06-.235 1.47-.41v-4.26h-3.404v-.818h4.334v5.6c-.397.26-.942.477-1.637.65-.695.174-1.556.261-2.586.261-1.836 0-3.212-.52-4.13-1.562M57.436 13.545L53.138 5.992 55.761 5.992 58.701 11.406 61.64 5.992 64.245 5.992 59.947 13.545 59.947 17.972 57.436 17.972z" />
      <path fill="#000" d="M67.872 11.963L63.649 5.992 66.607 5.992 69.379 9.991 72.15 5.992 75.108 5.992 70.885 11.963 75.22 17.972 72.225 17.972 69.379 13.972 66.532 17.972 63.538 17.972z" />
      <path fill="#2F80F4" d="M8.25 18.629C2.033 9.185 2.549 5.26 3.093 3.975 1.17 6.106 0 8.928 0 12.023c0 6.17 4.647 11.253 10.631 11.942 0-1.439-1.168-3.437-2.38-5.336" />
      <path fill="#2F80F4" d="M12.023 19.104c6.945-9.187 8.373-14.549 8.584-15.498C18.425 1.38 15.385 0 12.023 0 8.661 0 5.621 1.38 3.44 3.606c.211.95 1.639 6.31 8.584 15.498" />
      <path fill="#2F80F4" d="M20.954 3.974c.538 1.27 1.051 5.124-4.96 14.351-.006.01-.012.022-.02.033-1.272 1.98-2.553 4.1-2.554 5.607 5.982-.693 10.626-5.774 10.626-11.942 0-3.096-1.17-5.917-3.092-8.049" />
      <path fill="#000" d="M29.829 12.038c0-2.158.48-3.736 1.442-4.735.96-.998 2.42-1.497 4.38-1.497s3.42.5 4.382 1.497c.96.999 1.441 2.577 1.441 4.735 0 4.08-1.941 6.12-5.822 6.12-3.883 0-5.823-2.04-5.823-6.12m8.297 3.004c.533-.65.8-1.652.8-3.004 0-1.476-.26-2.527-.782-3.153-.52-.626-1.351-.94-2.492-.94s-1.972.314-2.493.94c-.521.626-.782 1.677-.782 3.153 0 1.352.267 2.353.8 3.004.533.65 1.359.977 2.475.977 1.115 0 1.94-.326 2.474-.977M43.223 5.992h5.748c1.34 0 2.341.353 3.005 1.06.663.707.995 1.693.995 2.958 0 .807-.208 1.5-.624 2.084-.415.582-1.001 1.004-1.758 1.265.187.161.338.331.457.511.117.18.244.431.38.753l1.452 3.349h-2.605l-1.413-3.218c-.124-.285-.264-.48-.419-.586-.155-.105-.4-.158-.735-.158h-1.971v3.962h-2.512V5.992zm5.097 5.935c.682 0 1.203-.152 1.562-.457.36-.303.54-.79.54-1.46 0-.67-.161-1.16-.484-1.47-.322-.31-.812-.465-1.469-.465h-2.734v3.852h2.585zM114.863 17.972L115.886 17.972 115.886 5.992 114.863 5.992zM118.676 5.992L119.643 5.992 127.066 16.447 127.066 5.992 128.089 5.992 128.089 17.972 127.104 17.972 119.699 7.536 119.699 17.972 118.676 17.972z" />
    </g>
  </svg>
);

IconProviderOryxBig.propTypes = {
  className: PropTypes.string,
};
IconProviderOryxBig.defaultProps = {
  className: null,
};

