import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderPragmatic = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="29" height="42" viewBox="0 0 29 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.64062 22.9917H5.36498C6.13136 22.9917 6.51455 22.9917 7.28093 23.1833C7.85572 23.3749 7.85572 23.5665 8.04731 23.7581C8.23891 24.1413 8.23891 24.3329 8.23891 24.7161C8.23891 25.0992 8.04732 25.674 7.85572 26.0572C7.66413 26.632 7.47253 26.8236 6.89774 26.8236C6.70615 26.8236 6.32296 27.0152 5.17339 27.0152V30.0807H3.64062V22.9917ZM5.36498 26.0572H5.74817C6.13136 26.0572 6.32296 26.0572 6.32296 26.0572C6.51455 26.0572 6.51455 25.8656 6.51455 25.8656C6.51455 25.674 6.70615 25.674 6.70615 25.4824C6.70615 25.2908 6.51455 25.0992 6.32296 24.9077C6.13136 24.9077 5.93977 24.7161 5.55658 24.7161H5.36498V26.0572Z" fill="white" />
    <path d="M9.00391 22.9917H10.1535V29.1227H12.261V30.2723H9.00391V22.9917Z" fill="white" />
    <path d="M16.4762 22.9917H17.6258L20.4998 30.6555H18.7754L18.2006 28.9311H15.1351L14.5603 30.6555H12.8359L16.4762 22.9917ZM17.051 25.0992L16.2847 27.7816H18.2006L17.051 25.0992Z" fill="white" />
    <path d="M19.543 22.9917H21.2673L22.4169 25.674L23.5665 22.9917H25.2908L22.9917 27.0152V30.2723H21.8421V27.0152L19.543 22.9917Z" fill="white" />
    <path d="M16.2861 15.7107C11.6878 15.9023 13.7953 21.6502 18.7768 21.4586C23.3751 21.0754 20.8843 15.3275 16.2861 15.7107Z" fill="#F6921F" />
    <path d="M14.3696 12.262C6.32264 12.262 0 18.5846 0 26.6316C0 34.6786 6.32264 41.0012 14.3696 41.0012C22.4166 41.0012 28.7393 34.6786 28.7393 26.6316C28.7393 18.5846 22.4166 12.262 14.3696 12.262ZM14.3696 39.4685C7.47221 39.4685 1.72436 33.7206 1.72436 26.6316C1.72436 19.7342 7.47221 13.9863 14.3696 13.9863C21.2671 13.9863 27.0149 19.7342 27.0149 26.6316C27.0149 33.529 21.2671 39.4685 14.3696 39.4685Z" fill="#F6921F" />
    <path d="M13.9853 2.49074C10.7282 5.17307 11.1114 8.81338 11.1114 8.81338C11.1114 8.81338 8.81224 3.25712 6.12991 0C5.93832 4.0235 7.08789 9.77136 7.08789 9.77136C7.08789 9.77136 5.55513 6.32264 1.14844 6.13105C4.59715 8.23859 6.12991 11.8789 6.32151 12.6453C8.42905 11.4957 10.9198 10.5377 13.0273 10.3461C13.2189 9.77135 12.0694 5.93945 13.9853 2.49074Z" fill="#F6921F" />
  </svg>
);

IconProviderPragmatic.propTypes = {
  className: PropTypes.string
};

IconProviderPragmatic.defaultProps = {
  className: ''
};
