import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderBetsoftBig = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="100" height="39" viewBox="0 0 100 39">
    <g fill="none" fillRule="evenodd">
      <g mask="url(#icon-provider-betsoft-big-b)">
        <path fill="#000" fillRule="nonzero" d="M11 0h89l-9.833 37.487h-89l5-19.241L3 20.734l-3 2.322.833-3.151 2.334-8.626L4 7.962l1.667 2.322 2 2.654z" />
        <path fill="#FFF" d="M42.333 1.659H98l-7.333 27.866h-55.5l3.333-13.27h14.333l-3 11.446h39.334l6.5-24.384h-39.5l-3 11.612H39l3.333-13.27zm-15 0h14.334l-3.5 13.27H23.833l3.5-13.27zm10.5 14.597l-3.5 13.27H20l3.5-13.27h14.333zM12.167 1.659H26.5l-3.333 13.27h-14.5l3.5-13.27zm10.5 14.597l-3.334 13.27h-14.5l3.5-13.27h14.334z" />
        <path fill="#FF0" fillRule="nonzero" d="M85.5 7.962H88l-4.833 18.08H85.5l4.833-18.08h2.5l.667-2.986h-7.167L85.5 7.962zm-4.333 0H85l.833-2.986H79.5L74 26.042h2.333l2.334-8.625h3l.833-2.986h-3l1.667-6.47zm-14.5 13.104c-.834 3.317-.167 5.308 2.166 5.308 2.334 0 4.167-1.99 5-5.308l2.834-10.948c1-3.317.166-5.308-2.167-5.308-2.333 0-4 1.99-5 5.308l-2.833 10.948zM72 9.952c.333-1.658 1-2.156 1.833-2.156.667 0 1 .498.667 2.156l-3 11.28c-.5 1.493-1 2.156-1.833 2.156-.667 0-1-.663-.667-2.156l3-11.28zm-10.5.166c-1.667 5.972 2.833 6.801 1.667 11.114-.334 1.493-1 1.99-1.834 1.99-.666 0-1-.497-.666-1.99l.5-1.659H59l-.5 1.493c-.833 3.317-.167 5.308 2.167 5.308 2.333 0 4-1.99 4.833-5.308 1.667-5.972-2.667-6.8-1.667-11.114.5-1.658 1-2.156 1.834-2.156.666 0 1 .498.5 2.156l-.167.996h2.167l.166-.83c1-3.317.334-5.308-2-5.308-2.333 0-4 1.99-4.833 5.308z" />
        <path fill="#FFF" d="M44.167 7.63h2.666l-5 18.91h2.5l4.834-18.91h2.666l.834-3.151H45l-.833 3.151zm2 .332h-2.5l.166-.498.834-3.151V3.98h8.5L53 4.479l-.833 3.151v.332H49.5l-4.833 18.744v.331h-3.5l.166-.663 4.834-18.412z" />
        <path fill="#2B2A29" fillRule="nonzero" d="M44.167 7.63h2.666l-5 18.91h2.5l4.834-18.91h2.666l.834-3.151H45z" />
        <path fill="#FFF" d="M32.167 7.63H36.5l.833-3.151H30.5l-5.667 22.06H31.5l.833-3.15H28l1.833-6.47h3.334L34 13.767h-3.333l1.5-6.137zm4.333.332h-4l-1.333 5.308H34.5l-.167.497-.833 3.152v.332h-3.333l-1.5 5.64h4.166l-.166.663-.834 3.152v.331h-7.666l.166-.663 5.834-22.061V3.98h7.666l-.166.498-.834 3.151v.332H36.5z" />
        <path fill="#2B2A29" fillRule="nonzero" d="M32.167 7.63H36.5l.833-3.151H30.5l-5.667 22.06H31.5l.833-3.15H28l1.833-6.47h3.334L34 13.767h-3.333z" />
        <path fill="#FFF" d="M18 7.63c.833 0 1 .664.667 2.157l-.334 1.326c-.5 1.66-1.166 2.323-2.166 2.323h-1l1.5-5.806H18zm-2.5 8.957c1.167 0 1.333.664.833 2.654l-.5 1.99c-.333 1.66-1 2.157-1.833 2.157h-1.333l1.666-6.8H15.5zM18.833 4.48h-3.666l-5.834 22.06h3.834c2.5 0 4.166-1.824 5.166-5.307l.5-1.825c.5-2.322.5-3.98-.666-4.644 1.333-.83 2.166-2.157 2.833-4.479l.167-.83c.833-3.317.166-4.975-2.334-4.975zm-.5 3.815c0-.166-.166-.332-.333-.332h-1l-1.167 4.976h.334c.333 0 .666 0 .833-.332.333-.331.667-.829.833-1.658l.334-1.161c.166-.83.166-1.327.166-1.493zm-2.166 8.957c-.167-.166-.334-.332-.667-.332h-.833l-1.5 5.971H14c.333 0 .5 0 .833-.331.167-.166.334-.664.667-1.493l.5-1.99c.167-.83.333-1.493.167-1.825zm-1-13.27h3.666c1.5 0 2.334.498 2.834 1.493.5.995.333 2.322-.167 4.147l-.167.829c-.333 1.161-.666 2.156-1.166 2.82a3.923 3.923 0 0 1-1.334 1.493c.5.497.667.995.667 1.658.167.83 0 1.99-.333 3.152l-.5 1.825c-.5 1.824-1.167 3.151-2 4.146-1 .996-2.167 1.493-3.5 1.493h-4.5l.166-.663 5.834-22.061.166-.332h.334z" />
        <path fill="#2B2A29" fillRule="nonzero" d="M18 7.63c.833 0 1 .664.667 2.157l-.334 1.326c-.5 1.66-1.166 2.323-2.166 2.323h-1l1.5-5.806H18zm-2.5 8.957c1.167 0 1.333.664.833 2.654l-.5 1.99c-.333 1.66-1 2.157-1.833 2.157h-1.333l1.666-6.8H15.5zM18.833 4.48h-3.666l-5.834 22.06h3.834c2.5 0 4.166-1.824 5.166-5.307l.5-1.825c.5-2.322.5-3.98-.666-4.644 1.333-.83 2.166-2.157 2.833-4.479l.167-.83c.833-3.317.166-4.975-2.334-4.975z" />
        <path fill="#FF0" d="M4.5 11.28l3.167 4.312-5.5 4.313L4.5 11.279zm53.833 0L53 15.591l3.167 4.313 2.166-8.626z" />
        <path fill="#FFF" fillRule="nonzero" d="M48.167 34.17l.166-.498h1.5l-.333.995c-.333.332-.667.498-1 .664-.333.166-.667.166-1.167.166-.666 0-1.166-.166-1.5-.498-.166-.332-.333-.663-.166-1.327a2.58 2.58 0 0 1 1-1.327c.5-.332 1-.497 1.666-.497.334 0 .667.165 1 .165.334.166.5.498.667.664l-.5.332-.5-.498c-.167 0-.5-.166-.833-.166-.5 0-.834.166-1.167.498-.5.166-.667.497-.833.83 0 .497 0 .663.166.994C46.667 35 47 35 47.5 35c.333 0 .5 0 .833-.166.334 0 .5-.166.667-.331l.167-.332h-1zm7 0H56.5l-.333-1.327-1 1.327zM53.5 35.497l2.667-3.484h.333l1 3.484h-.667l-.166-.995h-1.834l-.666.995H53.5zm8.333 0l1-3.484h.334l1 1.991L66 32.014h.5l-.833 3.483H65l.667-2.488-1.834 1.824L63 33.01l-.667 2.488h-.5zm8.834 0l1-3.484h.5l-.834 3.484h-.666zm5.666 0l1-3.484h.334l1.5 2.323.666-2.323h.5l-.833 3.484h-.333L77.5 33.009l-.5 2.488h-.667zm10.834-1.327l.166-.498h1.5l-.333.995c-.167.332-.5.498-1 .664-.333.166-.667.166-1.167.166-.666 0-1-.166-1.333-.498-.333-.332-.5-.663-.333-1.327a2.58 2.58 0 0 1 1-1.327c.5-.332 1-.497 1.666-.497.5 0 .834.165 1 .165.334.166.5.498.667.664l-.5.332-.5-.498c-.167 0-.5-.166-.833-.166-.5 0-.834.166-1.167.498-.5.166-.667.497-.833.83 0 .497 0 .663.333.994.167.332.5.332 1 .332.333 0 .667 0 .833-.166.334 0 .5-.166.667-.331l.167-.332h-1z" />
        <path fill="#FF0" d="M18.667 31.35c.666 0 1.166.663 1 1.327v.166c.666.166 1 .663.833 1.327-.167.83-1 1.327-1.667 1.327-.5 0-1-.332-1-.83-.333.498-1 .83-1.5.83-.666 0-1.166-.498-1-1.327.167-.664.834-1.161 1.667-1.327v-.166c.167-.664 1-1.327 1.667-1.327z" />
        <path fill="#FF0" d="M18 36.326L17.667 35l-.834 1.327H18zm22-4.976c.333 0 2 1.659 1.667 2.82-.167.83-.834 1.327-1.667 1.327-.5 0-.833-.332-1-.83-.333.498-.833.83-1.333.83-.834 0-1.334-.498-1-1.327C37 32.843 39.5 31.35 40 31.35z" />
        <path fill="#FF0" d="M39.167 36.326L39 35l-1 1.327h1.167zm-11-.332c-.667 0-2.334-1.658-2-3.151.166-.664 1-1.327 1.833-1.327.667 0 1 .332 1 .83.5-.498 1-.83 1.667-.83.666 0 1.166.663 1 1.327-.334 1.659-3 3.151-3.5 3.151zM8.5 31.35c.5.995 1 1.659 1.833 2.488-1.166.664-2 1.327-3 2.322-.5-.995-1-1.658-1.833-2.322 1.167-.83 2-1.493 3-2.488z" />
      </g>
    </g>
  </svg>

);

IconProviderBetsoftBig.propTypes = {
  className: PropTypes.string,
};
IconProviderBetsoftBig.defaultProps = {
  className: null,
};
