import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderOryx = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-1016 -892) translate(1016 892)">
          <path d="M0 0H24V24H0z" />
          <g fill="#2F80F4" mask="url(#icon-provider-oryx-b)">
            <path d="M8.25 18.629C2.033 9.185 2.549 5.26 3.093 3.975 1.17 6.106 0 8.928 0 12.023c0 6.17 4.647 11.253 10.631 11.942 0-1.439-1.168-3.437-2.38-5.336" />
            <path d="M12.023 19.104c6.945-9.187 8.373-14.549 8.584-15.498C18.425 1.38 15.385 0 12.023 0 8.661 0 5.621 1.38 3.44 3.606c.211.95 1.639 6.31 8.584 15.498" />
            <path d="M20.954 3.974c.538 1.27 1.051 5.124-4.96 14.351-.006.01-.012.022-.02.033-1.272 1.98-2.553 4.1-2.554 5.607 5.982-.693 10.626-5.774 10.626-11.942 0-3.096-1.17-5.917-3.092-8.049" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconProviderOryx.propTypes = {
  className: PropTypes.string,
};
IconProviderOryx.defaultProps = {
  className: null,
};
