import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderElkBig = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="98" height="39" viewBox="0 0 98 39">
    <g fill="#259BFF" fillRule="evenodd">
      <path
        d="M6.077 38.038c1.808 0 2.5-1.038 2.5-2.038 0-2.538-4.039-1.654-4.039-3.192 0-.616.577-1.077 1.385-1.077a2.77 2.77 0 0 1 2 .884l.5-.615A3.346 3.346 0 0 0 6 31.038c-1.346 0-2.346.77-2.346 1.924 0 2.423 4.038 1.423 4.038 3.192 0 .538-.384 1.192-1.615 1.192a2.73 2.73 0 0 1-2.115-.884l-.5.653a3.27 3.27 0 0 0 2.615 1.116m14.423 0v-6.385h2.154v-.77H17.5v.77h2.154v6.039h.846m14.23 0a2.538 2.538 0 0 0 2.77-2.808v-4h-.846v4.154a1.923 1.923 0 1 1-3.846 0v-4.154h-.846v4.192a2.538 2.538 0 0 0 2.769 2.77m14.884-.885h-1.5v-5.308h1.5a2.654 2.654 0 1 1 0 5.308zm0 .73a3.423 3.423 0 1 0 0-6.807h-2.461v6.808h2.461zm13 0h.847v-6.807h-.924l.077 6.808zm13.731-.615a2.77 2.77 0 1 1 0-5.538 2.77 2.77 0 0 1 0 5.538zm0 .77a3.538 3.538 0 1 0-3.384-3.539 3.346 3.346 0 0 0 3.384 3.538zm15.077 0c1.846 0 2.5-1.039 2.5-2.039 0-2.538-4.038-1.654-4.038-3.192 0-.616.615-1.077 1.423-1.077a2.654 2.654 0 0 1 2 .884l.5-.615a3.346 3.346 0 0 0-2.423-.962c-1.347 0-2.347.77-2.347 1.924 0 2.423 4.039 1.423 4.039 3.192 0 .538-.385 1.192-1.577 1.192a2.73 2.73 0 0 1-2.038-.884l-.5.653a3.27 3.27 0 0 0 2.615 1.116M70.692 16.846v7.692H9.077a6.615 6.615 0 0 1-4.73-1.884 6.656 6.656 0 0 1-1.847-4.5V8.538a7.31 7.31 0 0 1 1.808-4.884 6.423 6.423 0 0 1 4.384-1.808H41.5v14.308h19.23V1.846h9.732v6.692L80 1.846h12.846L79.423 13 93 24.538H79.692l-9-7.692zM81.808 13L97.192.385H79.5l-7.308 5.307V.385h-12.73v14.192H43.308V.269H8.692a7.692 7.692 0 0 0-5.654 2.27A8.346 8.346 0 0 0 1 8.5v9.577a8.308 8.308 0 0 0 2.23 5.538A8.038 8.038 0 0 0 9.078 26H72.192v-5.923l6.962 5.808h18L81.808 13z"
      />
      <path
        d="M31.77 3.346v5.308H12.153A1.23 1.23 0 0 0 11.23 9a1.308 1.308 0 0 0-.308.885v.73H31.77v5.193H10.923v.692c.002.298.11.585.308.808.229.216.53.34.846.346h19.692v5.308H9.27a5.346 5.346 0 0 1-3.846-1.424 5.346 5.346 0 0 1-1.385-3.461V8.423a5.5 5.5 0 0 1 1.385-3.846 5.077 5.077 0 0 1 3.5-1.27H31.77m1.616 0h6.846v14.308h21.038v5.347H33.308V3.308m47.154 0h8.192l-11.539 9.577 11.77 9.884h-8.616L69.192 13.5v9.27h-6.654V3.422h6.693v8.154l11.346-8.154"
      />
    </g>
  </svg>
);

IconProviderElkBig.propTypes = {
  className: PropTypes.string,
};
IconProviderElkBig.defaultProps = {
  className: null,
};
