import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderLivespins = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
  >
    <rect width="60" height="60" rx="30" fill="#4E4EF2" />
    <path
      d="M15.5342 50.1412C15.5429 50.3335 15.6087 50.5189 15.7233 50.6737C15.7948 50.7685 15.8862 50.8464 15.9911 50.9021C16.096 50.9577 16.2119 50.9897 16.3305 50.9958C16.4929 51.0125 16.6565 50.9792 16.7993 50.9002C16.9422 50.8211 17.0574 50.7003 17.1294 50.5539L17.8671 49.1907L29.1377 28.1426H20.5783C20.4415 28.1433 20.3065 28.1109 20.185 28.0481C20.0634 27.9853 19.9589 27.894 19.8804 27.7819C19.8018 27.67 19.7517 27.5406 19.7341 27.405C19.7164 27.2694 19.732 27.1315 19.7792 27.0032L25.6916 11.0001H13.8724C13.7542 10.998 13.637 11.0204 13.5279 11.0659C13.4189 11.1112 13.3204 11.1786 13.2386 11.2639C13.1568 11.3491 13.0936 11.4503 13.0528 11.5612C13.012 11.672 12.9945 11.7901 13.0015 11.908L15.4223 48.6476L15.5342 50.1412Z"
      fill="white"
    />
    <path
      d="M40.7701 22.2831L31.3611 39.8542H41.1003C41.3007 39.8544 41.4953 39.7865 41.6521 39.6618C41.8088 39.537 41.9186 39.3626 41.9632 39.1673C42.7968 35.5599 46.6877 18.6677 48.211 12.0118C48.2389 11.8913 48.2394 11.766 48.2124 11.6452C48.1853 11.5244 48.1315 11.4113 48.0548 11.3141C47.9781 11.217 47.8804 11.1383 47.7691 11.084C47.6579 11.0296 47.5359 11.001 47.4121 11.0002H46.2802C43.8407 11.0348 35.9311 11.0002 35.9311 11.0002L32.2106 21.0477H40.0271C40.1724 21.0471 40.3155 21.0842 40.4423 21.1555C40.569 21.2266 40.675 21.3297 40.7499 21.4542C40.8248 21.5787 40.8661 21.7207 40.8697 21.866C40.8731 22.0112 40.8388 22.1549 40.7701 22.2831Z"
      fill="white"
    />
  </svg>
);

IconProviderLivespins.propTypes = {
  className: PropTypes.string,
};

IconProviderLivespins.defaultProps = {
  className: '',
};
