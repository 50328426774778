import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderGivme = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-837 -894) translate(835 892)">
          <path d="M0 0H24V24H0z" />
          <g fill="#1c1c1c" fillRule="nonzero" mask="url(#icon-provider-giveme-a)">
            <path d="M14.898 6c-.558-.797-1.272-1.42-2.144-1.87-.872-.45-1.798-.676-2.779-.676-1.66 0-2.961.424-3.908 1.27C5.118 5.57 4.45 6.427 4.06 7.29c-.389.865-.584 1.746-.584 2.644 0 .812.157 1.611.47 2.398.313.786.943 1.686 1.89 2.697.95 1.01 2.378 1.515 4.29 1.515 1.1 0 2.162-.317 3.185-.953 1.024-.635 1.79-1.461 2.297-2.476H7.766v-3.35H20v.414c0 2.764-.981 5.091-2.943 6.982C15.093 19.054 12.75 20 10.026 20 7.3 20 4.95 19.04 2.97 17.12.99 15.2 0 12.85 0 10.065c0-2.802.981-5.18 2.944-7.134C4.907.977 7.25 0 9.975 0c1.945 0 3.773.544 5.482 1.636C17.166 2.727 18.367 4.18 19.061 6h-4.163z" transform="translate(2 2)" className="icon-switch-dark" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconProviderGivme.propTypes = {
  className: PropTypes.string,
};
IconProviderGivme.defaultProps = {
  className: null,
};
