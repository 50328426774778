import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderNolimitBig = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" width="108" height="56" viewBox="0 0 256 256">
    <g id="City_Baked" data-name="City Baked">
      <path
        fill="#000"
        d="M61.08,150.22a3,3,0,0,0-2.58,1.26,5.82,5.82,0,0,0-.92,3.49q0,4.67,3.5,4.67a11.3,11.3,0,0,0,3.56-.73v2.48a10,10,0,0,1-3.84.71,5.84,5.84,0,0,1-4.65-1.84,7.81,7.81,0,0,1-1.61-5.3,8.69,8.69,0,0,1,.79-3.82,5.75,5.75,0,0,1,2.27-2.5,6.67,6.67,0,0,1,3.48-.88,9.27,9.27,0,0,1,4.08,1l-1,2.4a14.89,14.89,0,0,0-1.57-.65A4.85,4.85,0,0,0,61.08,150.22Z"
      />
      <path fill="#000" d="M101.8,161.91V148h3v13.95Z" />
      <path fill="#000" d="M147.84,161.91h-3V150.42h-3.79V148h10.53v2.46h-3.78Z" />
      <path fill="#000" d="M192.3,153.71l2.91-5.75h3.19l-4.63,8.52v5.43h-2.94v-5.33L186.21,148h3.2Z" />
    </g>
    <path
      fill="#797710"
      d="M199.08,110.91h-3.26c-.59,0-.66,0-.73-.61a11.83,11.83,0,0,0-1-3.74,7.75,7.75,0,0,0-6.52-4.67,11.67,11.67,0,0,0-5,.46,8.08,8.08,0,0,0-5,4.55,11.86,11.86,0,0,0-.9,3.31l0,.36a.31.31,0,0,1-.34.33h-7.23a.35.35,0,0,1-.36-.34c-.07-.41-.12-.83-.22-1.24a11.55,11.55,0,0,0-1.67-4,7.84,7.84,0,0,0-6.32-3.51,11.83,11.83,0,0,0-3.41.26,8.3,8.3,0,0,0-6,5.18,10.49,10.49,0,0,0-.77,3c-.06.65-.08.66-.79.66h-6c-.24,0-.48,0-.72,0s-.47-.09-.43-.51.11-.89.18-1.34a21.13,21.13,0,0,1,3.12-8.13,15.06,15.06,0,0,1,10.14-6.67,20.23,20.23,0,0,1,4-.35,14.07,14.07,0,0,1,8.94,3.19,20,20,0,0,1,3.2,3.3l.5.65a.22.22,0,0,0,.38,0l.57-.7a20.05,20.05,0,0,1,3.34-3.3,14.82,14.82,0,0,1,9-3.15,18,18,0,0,1,6.14.88,14.44,14.44,0,0,1,7.8,6.15,21.21,21.21,0,0,1,2.94,7.72c.1.53.16,1.07.23,1.61s.06.64-.61.64-1.27,0-1.9,0Z"
    />
    <path
      fill="#797710"
      d="M44.15,113.06c1.14,0,2.29,0,3.44,0,.41,0,.48.07.49.47,0,.63,0,1.26,0,1.9a11.44,11.44,0,0,0,.79,4,10.55,10.55,0,0,0,4.67,5.46,11.13,11.13,0,0,0,4.38,1.35,16.35,16.35,0,0,0,5.49-.31,10.9,10.9,0,0,0,8.23-7.84,9.34,9.34,0,0,0,.37-2.58c0-.69,0-1.39,0-2.08,0-.31.08-.4.36-.4h7.15c.28,0,.35.09.35.41a23.29,23.29,0,0,1-.61,6.91,20.11,20.11,0,0,1-3.39,7.05,16.58,16.58,0,0,1-8.26,5.74,23.23,23.23,0,0,1-6.67,1.1,25.78,25.78,0,0,1-7-.7A17.18,17.18,0,0,1,41.85,123.2,19.8,19.8,0,0,1,40.23,117c-.11-1.19,0-2.41,0-3.61a.3.3,0,0,1,.34-.33h1.63c.66,0,1.32,0,2,0Z"
    />
    <path
      fill="#797710"
      d="M72.1,110.87a1.89,1.89,0,0,1-.22-.71,9.59,9.59,0,0,0-6.31-7.44,13.9,13.9,0,0,0-4.32-.87,17.9,17.9,0,0,0-5.83.55,9.94,9.94,0,0,0-5.92,4.32,10.28,10.28,0,0,0-1.38,3.71c-.07.45-.1.48-.49.48h-7c-.42,0-.5-.1-.44-.48.09-.57.19-1.13.31-1.69a20,20,0,0,1,3.35-7.55,17,17,0,0,1,9.2-6.36,23.8,23.8,0,0,1,5.62-.9,25.4,25.4,0,0,1,7.62.72,17.16,17.16,0,0,1,8.56,5A18.94,18.94,0,0,1,79.05,107a19.63,19.63,0,0,1,.67,2.72,7,7,0,0,1,.1.8c0,.34-.06.43-.41.44h-6.7A5.86,5.86,0,0,1,72.1,110.87Z"
    />
    <path
      fill="#797710"
      d="M8.47,94.09c2.17,0,4.34,0,6.51,0a14.73,14.73,0,0,1,4.28.57,19.48,19.48,0,0,1,7,3.41,14.62,14.62,0,0,1,5.51,9.16c.16.83.22,1.67.32,2.51q0,.31,0,.63c0,.48,0,.53-.5.55H24.59a.3.3,0,0,1-.34-.33,9.09,9.09,0,0,0-.19-1.8,8.61,8.61,0,0,0-1.57-3.71,8,8,0,0,0-5.08-3,11.22,11.22,0,0,0-2.16-.21H8.19a.29.29,0,0,0-.27.3c0,.24,0,.48,0,.72v7.24c0,.82,0,.81-.78.81H.8l-.55,0a.25.25,0,0,1-.23-.24c0-.21,0-.42,0-.63V95c0-1,0-.91,1-.91Z"
    />
    <path
      fill="#797710"
      d="M101,134.27H89.13a.27.27,0,0,1-.29-.27c0-.21,0-.42,0-.63V114.09c0-1.12-.1-1,1-1,2,0,3.92,0,5.88,0h.63a.28.28,0,0,1,.3.27c0,.3,0,.6,0,.9v11c0,1.16-.11,1.08,1.07,1.08H112.3c1.12,0,1-.08,1,1v5.88c0,.24,0,.48,0,.72a.27.27,0,0,1-.29.27H101Z"
    />
    <path
      fill="#797710"
      d="M242.83,93.91h12.85a.28.28,0,0,1,.3.28,4.78,4.78,0,0,1,0,.54V101a4.78,4.78,0,0,1,0,.54.28.28,0,0,1-.29.29h-8c-.8,0-.8,0-.8.79,0,2.38,0,4.77,0,7.15,0,.24,0,.48,0,.72s-.08.41-.45.42h-7.06a.32.32,0,0,1-.35-.32c0-.27,0-.55,0-.82v-6.7c0-.21,0-.42,0-.63,0-.57,0-.61-.63-.61H231c-1.5,0-1.29.19-1.3-1.28,0-1.87,0-3.74,0-5.61,0-.25,0-.49,0-.73a.28.28,0,0,1,.29-.28h12.85Z"
    />
    <path
      fill="#797710"
      d="M212.7,123.69c0-3.29,0-6.58,0-9.86,0-.79-.07-.8.78-.8h6.79a.33.33,0,0,1,.32.36c0,.18,0,.36,0,.54v19.63c0,.69,0,.7-.71.7H213.1c-.33,0-.39-.08-.4-.45s0-.49,0-.73v-9.4Z"
    />
    <path
      fill="#797710"
      d="M195.13,123.62v-9.77c0-.84-.07-.82.85-.82h6.15q.31,0,.63,0a.24.24,0,0,1,.24.23q0,.4,0,.81v19.35c0,.16,0,.31,0,.46s-.1.34-.4.36h-6.7a4.87,4.87,0,0,1-.54,0,.24.24,0,0,1-.23-.23c0-.27,0-.54,0-.81v-9.59Z"
    />
    <path
      fill="#797710"
      d="M176.66,123.68v9.68c0,.95.05.91-.86.91h-6.24a4.78,4.78,0,0,1-.54,0,.26.26,0,0,1-.24-.23,4.87,4.87,0,0,1,0-.54V113.94c0-.9-.07-.91.87-.91h6.24l.54,0a.24.24,0,0,1,.23.23c0,.27,0,.55,0,.82v9.58Z"
    />
    <path
      fill="#797710"
      d="M123.63,123.68v-10c0-.68,0-.7.71-.7h6.88a.32.32,0,0,1,.31.36c0,.15,0,.31,0,.46v19.9c0,.46-.06.51-.54.51h-7c-.33,0-.39-.08-.41-.45s0-.49,0-.73v-9.41Z"
    />
    <path
      fill="#797710"
      d="M7.85,133.86a1,1,0,0,1-.62.12H.35c-.2,0-.34-.11-.34-.33s0-.43,0-.64V114.27c0-.27,0-.55,0-.82s.08-.39.45-.42H7l.64,0a.26.26,0,0,1,.28.28c0,.24,0,.48,0,.72v19A2.15,2.15,0,0,1,7.85,133.86Z"
    />
    <path
      fill="#797710"
      d="M32.15,123.81v9.31c0,.21,0,.43,0,.64,0,.46-.06.51-.53.51H24.75l-.27,0a.25.25,0,0,1-.22-.25c0-.21,0-.42,0-.63V114.11c0-.78,0-.79.8-.79h6.69c.33,0,.4.09.42.46s0,.54,0,.81Z"
    />
    <path
      fill="#797710"
      d="M142.43,123.68v-9.5c0-.27,0-.54,0-.82a.27.27,0,0,1,.27-.29h6.79c.12,0,.24,0,.36,0,.3,0,.38.09.4.37s0,.42,0,.63v19.37a4.87,4.87,0,0,1,0,.54.28.28,0,0,1-.28.29h-7.06c-.44,0-.48-.05-.5-.45,0-.24,0-.48,0-.73v-9.41Z"
    />
    <path
      fill="#797710"
      d="M246.93,123.77v9.59c0,1,0,.91-.87.91h-6.69a.29.29,0,0,1-.29-.29c0-.21,0-.42,0-.63V114.15c0-1.23-.11-1.09,1.14-1.09q2.85,0,5.7,0h.72a.28.28,0,0,1,.28.29c0,.27,0,.55,0,.82v9.59Z"
    />
    <path
      fill="#797710"
      d="M220.6,102.44v7.33c0,.21,0,.42,0,.63,0,.45-.07.51-.54.51h-6.88c-.41,0-.47-.07-.48-.47,0-.18,0-.37,0-.55V95c0-.21,0-.42,0-.63s.07-.4.44-.42h7c.42,0,.47.06.49.47,0,.21,0,.42,0,.63Z"
    />
    <path
      fill="#797710"
      d="M123.63,102.41v-7.7c0-.81,0-.8.79-.8h6.33a4.87,4.87,0,0,1,.54,0,.26.26,0,0,1,.23.24c0,.21,0,.42,0,.64V110c0,.93,0,.9-.88.9h-6.15q-.32,0-.63,0a.24.24,0,0,1-.23-.24c0-.24,0-.48,0-.72Z"
    />
    <path
      fill="#797710"
      d="M96.67,102.41v7.69c0,.8,0,.81-.78.81H89.56l-.45,0a.28.28,0,0,1-.28-.29c0-.24,0-.49,0-.73V95c0-1.14-.09-1,1-1h6.51c.22,0,.32.14.32.35s0,.48,0,.72Z"
    />
  </svg>
);

IconProviderNolimitBig.propTypes = {
  className: PropTypes.string,
};
IconProviderNolimitBig.defaultProps = {
  className: null,
};
