import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderQuickSpinBig = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="110" height="27" viewBox="0 0 759.3 212.31">
    <path
      d="M250.5,119.79H132.76a14.11,14.11,0,0,0-14.11,14.11V318a14.11,14.11,0,0,0,14.11,14.11H250.5A14.11,14.11,0,0,0,264.61,318V133.9A14.11,14.11,0,0,0,250.5,119.79ZM239.87,233.05a35.59,35.59,0,0,1-7,21.21L214,279.73,235.88,300a3.14,3.14,0,0,1-2.14,5.44H206.15a6.25,6.25,0,0,1-4.27-1.67l-3.39-3.14h0l-2.72-2.51-4.14-3.83h0l-10.31-9.54a3.16,3.16,0,0,1-.39-4.18l29.26-39.45a25.15,25.15,0,0,0,4.95-15V193c0-10.65-4.09-15.21-5.63-16.41-1.78-1.39-5.55-4.69-17.88-4.69s-16.1,3.3-17.88,4.69c-1.54,1.2-5.63,5.76-5.63,16.41h0v35.14a21,21,0,0,0,6.72,15.38l9.82,9.08a3.15,3.15,0,0,1,.39,4.17l-11.74,15.82a3.13,3.13,0,0,1-4.65.44L154.81,260.2a35.59,35.59,0,0,1-11.42-26.13V182.35c0-14.87,4.52-21.59,9.72-26.17,5.89-5.18,16.42-9.72,38.52-9.72s32.63,4.54,38.52,9.72c5.2,4.58,9.72,11.3,9.72,26.17Z"
      fill="#ff7800"
      transform="translate(-118.65 -119.79)"
    />
    <path d="M398.8,291" transform="translate(-118.65 -119.79)" fill="#ff7800" />
    <path
      d="M866,232.6v63.8h0v7l-33.68-61.13c-4.08-7.28-6.89-9.65-12.2-9.65s-9.06,3.67-9.06,11v14.21h0v63.8H823v-63.8h0v-7l33.68,61.13h0c4.08,7.28,6.89,9.65,12.2,9.65s9.06-3.67,9.06-10.95V296.4h0V232.6Z"
      transform="translate(-118.65 -119.79)"
      fill="#ff7800"
    />
    <path
      d="M628.78,232.66H612.47l-32.85,35v-35h-11.9v89h11.9V285.11l3.51-3.75,30.21,40.34h15.44l-37.42-49.12Z"
      transform="translate(-118.65 -119.79)"
      fill="#ff7800"
    />
    <path
      d="M572.94,321.7"
      transform="translate(-118.65 -119.79)"
      fill="#ff7800"
    />
    <path
      d="M752.79,238c-6.63-4.37-14.22-5.34-21.7-5.34H709.16v89H721.5V290.4h9.59c7.48,0,15.07-1,21.7-5.34s12.1-11.79,12.1-23.53S759.37,242.33,752.79,238Zm-6.32,37.17c-3.31,2.61-8.45,4.24-15.38,4.24H721.5V243.65h9.59c6.93,0,12.07,1.62,15.38,4.24a17.3,17.3,0,0,1,0,27.28Z"
      transform="translate(-118.65 -119.79)"
      fill="#ff7800"
    />
    <path
      d="M363,311.22a34.62,34.62,0,0,0,3.95-6.33c3.21-6.62,3.19-14.21,3.18-19.23V268.14c0-5,0-12.61-3.18-19.22-5.69-11.72-15.87-17.59-27.93-17.62h-.15c-12.06,0-22.23,5.91-27.92,17.62-3.21,6.61-3.19,14.2-3.18,19.22v17.52c0,5,0,12.61,3.18,19.23,5.7,11.73,15.9,17.61,28,17.61h0a31.71,31.71,0,0,0,13.59-2.91l9.58,12.51H379Zm-4.78-25.05c0,4.07.05,9.63-2,13.82-2.15,4.42-7.28,11.12-17.29,11.12s-15.14-6.7-17.29-11.12-2-10.72-2-14.8V268.61c0-4.07-.16-10.37,2-14.79s7.28-11.12,17.29-11.12,15.14,6.7,17.29,11.12,2,10.72,2,14.79v17.56Z"
      transform="translate(-118.65 -119.79)"
      fill="#ff7800"
    />
    <path
      d="M517.48,247.06c8.14-6.14,17.43-6.77,31.77-3.44v-8.23s-7.1-4.09-18.37-4.09-17.84,2.91-23.07,7.57c-6,5.31-10.55,13.79-11.88,27h0a119.66,119.66,0,0,0,0,22.71h0c1.33,13.18,5.92,21.66,11.88,27,5.23,4.66,11.72,7.56,23.07,7.56S549.25,319,549.25,319v-8.23c-14.34,3.33-23.63,2.7-31.77-3.44-8.3-6.25-10-17.89-10-30.13S509.18,253.31,517.48,247.06Z"
      transform="translate(-118.65 -119.79)"
      fill="#ff7800"
    />
    <path
      d="M674.24,241.71a146.76,146.76,0,0,1,18.12,1.87V234a78.82,78.82,0,0,0-24.06-2.61,69.6,69.6,0,0,0-14,1.94c-6.54,1.68-10.18,4.8-12.2,7.86a14.66,14.66,0,0,0-1.58,13,1.45,1.45,0,0,1,.06.19c.14.38.29.78.46,1.17,2.3,5.94,5.8,10.5,10.76,15.87,5.32,5.76,16.36,16.07,21,21.62,2.89,3.54,6.29,8.27,4.6,12.35-1.86,4.47-10,7.57-19.73,7.29a163.34,163.34,0,0,1-18.12-1.87v9.53s7.54,3.32,24.05,2.66,23.07-5.08,26.19-9.8a18.14,18.14,0,0,0,2.55-8.63v-.26a13,13,0,0,0-.06-1.52c-.24-4.58-1.18-8-7-14.74-6.53-7.53-18.24-18.62-23.51-24.11l-1.45-1.52c-3.86-4.13-8.09-9.3-6.12-14.21C656.28,243,665.48,241.53,674.24,241.71Z"
      transform="translate(-118.65 -119.79)"
      fill="#ff7800"
    />
    <rect x="348.12" y="112.86" width="12.34" height="89.05" fill="#ff7800" />
    <rect x="661.54" y="112.72" width="12.34" height="89.05" fill="#ff7800" />
    <path
      d="M398.8,232.66H386.89V291c0,10.43,3.52,32,30.29,32s30.3-21.61,30.3-32V232.66H435.57V291h0c0,18.6-12.74,21-18.39,21s-18.38-2.35-18.38-21Z"
      transform="translate(-118.65 -119.79)"
      fill="#ff7800"
    />
  </svg>
);

IconProviderQuickSpinBig.propTypes = {
  className: PropTypes.string,
};
IconProviderQuickSpinBig.defaultProps = {
  className: null,
};

