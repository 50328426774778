import { isEmptyOrNil } from 'core/helpers';
import * as R from 'ramda';
import { createBasicPath } from './check-pixel-ratio';

const getImageUrlWithRatio = (imagePath = [], pixelRatio = '') => `${imagePath[0]}${pixelRatio}${imagePath[1]}`;

const getBackgroundImageProp = R.compose(
  R.join(','),
  R.uniq(),
  R.map(str => `url('${str}')`),
  R.reject(isEmptyOrNil),
);

export const getBackgroundStyles = (imageUrl, pixelRatio = '', defaultImageUrl = '') => {
  const imageUrlPath = createBasicPath(imageUrl);
  const defaultImageUrlPath = createBasicPath(defaultImageUrl);

  const imageUrlWithRatio = !isEmptyOrNil(imageUrlPath) && getImageUrlWithRatio(imageUrlPath, pixelRatio) || '';
  const defaultImageUrlWithRatio = !isEmptyOrNil(defaultImageUrlPath) && getImageUrlWithRatio(defaultImageUrlPath, pixelRatio) || '';

  return {
    backgroundImage: getBackgroundImageProp([imageUrlWithRatio, imageUrl])
      || getBackgroundImageProp([defaultImageUrlWithRatio, defaultImageUrl])
      || ''
  };
};
