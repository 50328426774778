import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderGGames = ({ className }) => (
  <svg className={classNames('producers-svg-icon', className)} width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.003 1.02207L1.07153 12.1637C0.898352 12.3402 0.902543 12.6241 1.08086 12.7955L11.7713 23.0667C11.9477 23.2362 12.228 23.2312 12.3983 23.0556L18.8914 16.3596C19.0625 16.1831 19.0579 15.9012 18.881 15.7305L13.0712 10.121C12.8968 9.9526 12.6196 9.95503 12.4482 10.1264L10.4046 12.17C10.231 12.3436 10.231 12.625 10.4046 12.7986L13.5538 15.9478C13.7274 16.1214 13.7274 16.4028 13.5538 16.5764L12.4046 17.7256C12.231 17.8992 11.9496 17.8992 11.7761 17.7256L6.4046 12.3541C6.23103 12.1806 6.23103 11.8992 6.40459 11.7256L11.9983 6.13191C12.1718 5.95834 12.4532 5.95834 12.6268 6.13191L16.8872 10.3923C17.0607 10.5658 17.3421 10.5658 17.5157 10.3923L19.5491 8.35886C19.7245 8.18346 19.7224 7.89843 19.5444 7.72566L12.6298 1.01441C12.4543 0.844101 12.1742 0.847525 12.003 1.02207ZM21.4235 14.4843C22.8963 14.4843 24.0902 13.2904 24.0902 11.8176C24.0902 10.3448 22.8963 9.15093 21.4235 9.15093C19.9507 9.15093 18.7568 10.3448 18.7568 11.8176C18.7568 13.2904 19.9507 14.4843 21.4235 14.4843Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.003 1.02207L1.07153 12.1637C0.898352 12.3402 0.902543 12.6241 1.08086 12.7955L11.7713 23.0667C11.9477 23.2362 12.228 23.2312 12.3983 23.0556L18.8914 16.3596C19.0625 16.1831 19.0579 15.9012 18.881 15.7305L13.0712 10.121C12.8968 9.9526 12.6196 9.95503 12.4482 10.1264L10.4046 12.17C10.231 12.3436 10.231 12.625 10.4046 12.7986L13.5538 15.9478C13.7274 16.1214 13.7274 16.4028 13.5538 16.5764L12.4046 17.7256C12.231 17.8992 11.9496 17.8992 11.7761 17.7256L6.4046 12.3541C6.23103 12.1806 6.23103 11.8992 6.40459 11.7256L11.9983 6.13191C12.1718 5.95834 12.4532 5.95834 12.6268 6.13191L16.8872 10.3923C17.0607 10.5658 17.3421 10.5658 17.5157 10.3923L19.5491 8.35886C19.7245 8.18346 19.7224 7.89843 19.5444 7.72566L12.6298 1.01441C12.4543 0.844101 12.1742 0.847525 12.003 1.02207ZM21.4235 14.4843C22.8963 14.4843 24.0902 13.2904 24.0902 11.8176C24.0902 10.3448 22.8963 9.15093 21.4235 9.15093C19.9507 9.15093 18.7568 10.3448 18.7568 11.8176C18.7568 13.2904 19.9507 14.4843 21.4235 14.4843Z" fill="url(#g-games-a)" />
  </svg>
);

IconProviderGGames.propTypes = {
  className: PropTypes.string
};

IconProviderGGames.defaultProps = {
  className: ''
};
