import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderPoker = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="45"
    viewBox="0 0 48 45"
    fill="none"
  >
    <g clipPath="url(#icon-provider-poker-1)">
      <path
        d="M11.6864 11.5515L6.50672 12.7482C6.0539 12.8545 5.78128 13.312 5.90142 13.7602L11.2243 33.2683L11.6864 11.5515Z"
        fill="#FF7678"
      />
      <path
        d="M35.9121 12.0137L41.0918 13.2104C41.5446 13.3167 41.8172 13.7741 41.6971 14.2223L36.3742 33.7305L35.9121 12.0137Z"
        fill="#FF7678"
      />
      <path
        d="M33.9942 35.5786H13.8715C12.416 35.5786 11.2285 34.3911 11.2285 32.9356V2.64298C11.2285 1.18749 12.416 0 13.8715 0H33.9988C35.4543 0 36.6418 1.18749 36.6418 2.64298V32.9356C36.6418 34.3911 35.4543 35.5786 33.9988 35.5786H33.9942ZM13.8715 1.38618C13.1784 1.38618 12.6147 1.94989 12.6147 2.64298V32.9356C12.6147 33.6287 13.1784 34.1924 13.8715 34.1924H33.9988C34.6919 34.1924 35.2556 33.6287 35.2556 32.9356V2.64298C35.2556 1.94989 34.6919 1.38618 33.9988 1.38618H13.8715Z"
        fill="white"
      />
      <path
        d="M10.1708 45.0001C9.70408 45.0001 9.24202 44.8753 8.83079 44.6304C8.22087 44.27 7.79115 43.6971 7.61557 43.0132L0.0839982 13.6724C-0.0915845 12.9886 0.0100686 12.277 0.370475 11.6717C0.730882 11.0664 1.30384 10.6321 1.98768 10.4565L9.35292 8.56667C9.72256 8.46964 10.1015 8.69605 10.1985 9.0657C10.2955 9.43534 10.0691 9.81423 9.69946 9.91127L2.33423 11.8011C2.01079 11.8843 1.73355 12.0876 1.56259 12.3787C1.39163 12.6698 1.34542 13.0071 1.42859 13.3305L8.96017 42.6713C9.04334 42.9994 9.24664 43.272 9.53774 43.4429C9.82422 43.6139 10.1661 43.6601 10.4896 43.5769L19.7169 41.2066C20.0866 41.1095 20.4655 41.3359 20.5625 41.7056C20.6595 42.0752 20.4331 42.4541 20.0635 42.5512L10.8361 44.9215C10.619 44.977 10.3972 45.0047 10.1754 45.0047L10.1708 45.0001Z"
        fill="white"
      />
      <path
        d="M25.9631 21.093C24.8634 21.093 23.893 20.5986 23.3663 19.7576C23.163 19.4342 23.2646 19.0045 23.5881 18.8012C23.9115 18.5979 24.3412 18.6995 24.5445 19.023C24.8541 19.522 25.4779 19.7715 26.2126 19.6929C26.9565 19.6144 27.8899 19.157 28.2687 18.1173C29.0127 16.075 27.3215 13.6446 23.9531 11.8703C20.5754 13.6492 18.8843 16.0796 19.6236 18.1173C19.8361 18.6995 20.2612 19.1708 20.8296 19.4481C21.1299 19.5451 21.652 19.6283 21.8923 19.4943C22.2296 19.3141 22.6501 19.4342 22.8349 19.7715C23.0197 20.1088 22.895 20.5293 22.5577 20.7141C21.6659 21.1993 20.4692 20.7927 20.3352 20.7418C20.3121 20.7326 20.2936 20.7233 20.2751 20.7141C19.3602 20.2798 18.6671 19.5266 18.3298 18.5886C17.2948 15.7377 19.3325 12.6281 23.6528 10.4702C23.8468 10.3732 24.0779 10.3732 24.2719 10.4702C28.5829 12.6234 30.616 15.7377 29.581 18.5886C29.082 19.9656 27.8483 20.9174 26.3651 21.0699C26.2311 21.0837 26.1017 21.093 25.9723 21.093H25.9631Z"
        fill="white"
      />
      <path
        d="M26.2327 24.9513H21.6306C21.2471 24.9513 20.9375 24.6417 20.9375 24.2582C20.9375 23.8747 21.2471 23.5651 21.6306 23.5651H25.1561L23.2986 19.462C23.1415 19.1154 23.2986 18.7042 23.6452 18.5471C23.9917 18.39 24.4029 18.5471 24.56 18.8937L26.8611 23.9763C26.9581 24.1889 26.9397 24.4384 26.8103 24.6371C26.6809 24.8357 26.4637 24.9559 26.2281 24.9559L26.2327 24.9513Z"
        fill="white"
      />
      <path
        d="M16.6309 3.29907H15.9239L14.6348 6.60742H15.3417L15.6143 5.85426H16.9358L17.2223 6.60742H17.9477L16.6216 3.29907H16.6309ZM15.8223 5.29517L16.2705 4.06609L16.7279 5.29517H15.8269H15.8223Z"
        fill="white"
      />
      <path
        d="M33.2036 29.1746H32.4782L32.1917 29.9277H30.8702L30.5976 29.1746H29.8906L31.1798 32.4829H31.8867L33.2128 29.1746H33.2036ZM31.9699 30.4822L31.5125 31.7113L31.0643 30.4822H31.9653H31.9699Z"
        fill="white"
      />
      <path
        d="M37.3392 44.9861C37.122 44.9861 36.9048 44.9583 36.6877 44.9029L17.1841 39.9357C15.7702 39.5753 14.9154 38.1337 15.2758 36.7244C15.3682 36.3548 15.7471 36.1284 16.1168 36.2254C16.4864 36.3178 16.7128 36.6967 16.6158 37.0664C16.5326 37.3898 16.5834 37.7317 16.7544 38.0182C16.9254 38.3047 17.198 38.5126 17.526 38.5958L37.0296 43.5629C37.7042 43.7293 38.388 43.3273 38.559 42.6527L46.0351 13.298C46.1183 12.9746 46.0675 12.6326 45.8965 12.3462C45.7255 12.0597 45.4529 11.8518 45.1249 11.7686L38.1478 9.98965C37.7781 9.89724 37.5517 9.51835 37.6487 9.1487C37.7412 8.77906 38.12 8.55265 38.4897 8.64968L45.4668 10.4286C46.8807 10.789 47.7355 12.2306 47.3751 13.6399L39.899 42.9946C39.594 44.1913 38.5174 44.9861 37.3392 44.9861Z"
        fill="white"
      />
    </g>
  </svg>
);

IconProviderPoker.propTypes = {
  className: PropTypes.string
};

IconProviderPoker.defaultProps = {
  className: ''
};
