import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderGalaxsysBig = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="138"
    height="20"
    viewBox="0 0 138 20"
    fill="none"
  >
    <g clipPath="url(#galaxysbig1)">
      <path
        d="M11.262 0.31073C6.09069 0.31073 0 2.62373 0 10.1496C0 17.6755 6.09069 19.9885 11.262 19.9885H16.9562V10.1209H13.1409V16.1968H11.262C6.33776 16.1968 4.06237 14.0392 4.06237 10.1209C4.06237 6.8815 6.30903 4.1082 11.94 4.1082H16.9562V0.31073H11.262Z"
        fill="black"
      />
      <path
        d="M36.0729 7.23245C36.0442 6.68009 35.958 6.14499 35.8259 5.63867C35.4868 4.36709 34.8318 3.23936 33.8435 2.24971C32.3323 0.742233 30.3672 0 27.9367 0C25.5636 0 23.5985 0.742233 22.0873 2.24971C20.5819 3.76295 19.812 5.58688 19.812 7.7733V19.9885H23.6272V14.5915H32.2749V19.9885H36.0902V7.7733C36.0902 7.58918 36.0844 7.41082 36.0729 7.23245ZM23.6272 10.7652V7.68124C23.6272 5.4603 25.2246 3.79747 27.9367 3.79747C30.6086 3.79747 32.2289 5.50633 32.2691 7.95167V7.95742C32.2749 7.98619 32.2749 8.01496 32.2749 8.04948V10.7652H23.6272Z"
        fill="black"
      />
      <path d="M51.3169 19.9885V16.1968H42.7555V0.31073H38.9402V19.9943H51.3169V19.9885Z" fill="black" />
      <path
        d="M67.3193 2.25545C65.8081 0.74797 63.8372 0.0057373 61.4067 0.0057373C59.0394 0.0057373 57.0685 0.74797 55.5631 2.25545C54.0577 3.76868 53.2877 5.58687 53.2877 7.77904V19.9942H57.103V14.5972H65.7506V19.9942H69.5602V7.77904C69.5602 5.58687 68.8247 3.76868 67.3193 2.25545ZM65.7506 10.771H57.103V7.68698C57.103 5.46604 58.7004 3.79745 61.4067 3.79745C64.1188 3.79745 65.7506 5.5581 65.7506 8.05522V10.771Z"
        fill="black"
      />
      <path
        d="M89.0849 0.31073H84.5341L80.2764 7.07137L76.0187 0.31073H71.4679L77.6678 10.1496L71.4679 19.9943H76.0187L80.2764 13.2279L84.5341 19.9943H89.0849L82.8851 10.1496L89.0849 0.31073Z"
        fill="black"
      />
      <path
        d="M96.4052 0.322205C91.6993 0.322205 90.0387 3.00345 90.0387 6.02991C90.0387 8.83774 91.3028 11.8585 95.7616 11.8585H98.5024C100.473 11.8585 101.548 12.1979 101.548 14.0161C101.548 15.6502 100.473 16.2083 98.5024 16.2083H90.9006V20H98.5024C103.674 20 105.208 17.9632 105.208 14.1369C105.208 11.3003 104.007 8.2451 98.7438 8.2451H96.1926C94.5607 8.2451 93.6989 7.81357 93.6989 6.2428C93.6989 4.79286 94.5607 4.11392 96.2213 4.11392H103.668V0.322205H96.4052Z"
        fill="black"
      />
      <path
        d="M122.745 0.287659H118.682L113.976 9.87914L109.265 0.287659H105.202L112.126 13.55V19.9655H115.941V13.55L122.745 0.287659Z"
        fill="black"
      />
      <path
        d="M129.203 0.310669C124.497 0.310669 122.836 2.99191 122.836 6.01838C122.836 8.8262 124.101 11.8469 128.559 11.8469H131.3C133.271 11.8469 134.346 12.1864 134.346 14.0046C134.346 15.6386 133.271 16.1967 131.3 16.1967H123.698V19.9885H131.3C136.472 19.9885 138.006 17.9516 138.006 14.1254C138.006 11.2888 136.805 8.23357 131.542 8.23357H128.99C127.359 8.23357 126.497 7.80204 126.497 6.23127C126.497 4.78132 127.359 4.10238 129.019 4.10238H136.466V0.310669H129.203Z"
        fill="black"
      />
    </g>
  </svg>
);

IconProviderGalaxsysBig.propTypes = {
  className: PropTypes.string,
};

IconProviderGalaxsysBig.defaultProps = {
  className: '',
};
