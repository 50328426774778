import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderReevoBig = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="108" height="36" viewBox="0 0 108 36" fill="none">
    <path d="M58.9968 7.72278H44.7983V12.5951C44.7983 12.7384 44.9136 12.854 45.0563 12.854H58.9968C59.1395 12.854 59.2547 12.7384 59.2547 12.5951V7.98176C59.2547 7.83846 59.1378 7.72278 58.9968 7.72278Z" fill="#000" />
    <path d="M62.0221 8.00593L63.864 28.013C63.876 28.146 63.9878 28.2478 64.1202 28.2478H70.8927C71.044 28.2478 71.1627 28.1166 71.1489 27.9647L69.307 7.95759C69.295 7.82464 69.1832 7.72278 69.0508 7.72278H62.2783C62.127 7.72278 62.0083 7.85399 62.0221 8.00593Z" fill="#000" />
    <path d="M72.4815 28.2478H79.2539C79.3863 28.2478 79.4981 28.146 79.5102 28.013L81.352 8.00593C81.3658 7.85399 81.2471 7.72278 81.0958 7.72278H74.3233C74.1909 7.72278 74.0791 7.82464 74.0671 7.95759L72.2252 27.9647C72.2114 28.1166 72.3301 28.2478 72.4815 28.2478Z" fill="#000" />
    <path d="M17.5381 17.9802V27.9837C17.5381 28.127 17.6533 28.2427 17.796 28.2427H24.5702C24.713 28.2427 24.8282 28.127 24.8282 27.9837V23.1425C24.8282 20.2903 22.5254 17.9802 19.6861 17.9802H19.1478H18.938V17.9612C22.3586 17.8766 24.8299 17.3586 24.8299 12.6504C24.8299 7.728 21.6655 7.76598 17.5398 7.728C17.1236 7.72455 16.6971 7.72455 16.262 7.72455C15.8269 7.72455 15.4004 7.72455 14.9842 7.728V7.72455H7.95035C7.80761 7.72455 7.69238 7.84023 7.69238 7.98353V27.9906C7.69238 28.1339 7.80761 28.2496 7.95035 28.2496L14.7245 28.2462C14.8673 28.2462 14.9825 28.1305 14.9825 27.9872V11.6248C14.9825 10.9221 15.5208 10.3092 16.2207 10.2885C16.9448 10.266 17.5381 10.8496 17.5381 11.5713V17.9802Z" fill="#000" />
    <path d="M93.0176 7.72623C92.6014 7.72278 92.1749 7.72278 91.7398 7.72278C91.3064 7.72278 90.8782 7.72278 90.462 7.72623C86.3363 7.76422 83.1719 8.17513 83.1719 12.6503V22.9128C83.1719 27.7592 86.3363 28.2029 90.462 28.2444C90.8782 28.2478 91.3047 28.2478 91.7398 28.2478C92.1749 28.2478 92.6014 28.2478 93.0176 28.2444C97.1433 28.2029 100.308 27.7592 100.308 22.9128V12.6503C100.308 8.17513 97.1433 7.76422 93.0176 7.72623ZM93.0176 14.1369V24.3977C93.0176 25.1055 92.4449 25.6805 91.7398 25.6805C91.3872 25.6805 91.0674 25.5372 90.8369 25.3041C90.6065 25.0727 90.462 24.7516 90.462 24.3977V14.1369V11.5712C90.462 10.8633 91.0347 10.2884 91.7398 10.2884C92.0923 10.2884 92.4122 10.4317 92.6427 10.6648C92.8731 10.8962 93.0176 11.2173 93.0176 11.5712V14.1369Z" fill="#000" />
    <path d="M27.6006 7.72278H41.799C41.9418 7.72278 42.057 7.83846 42.057 7.98176V12.5951C42.057 12.7384 41.9418 12.854 41.799 12.854H32.7118V15.4197H41.799C41.9418 15.4197 42.057 15.5354 42.057 15.6787V20.292C42.057 20.4353 41.9418 20.5509 41.799 20.5509H27.8586C27.7158 20.5509 27.6006 20.4353 27.6006 20.292V7.72278Z" fill="#000" />
    <path d="M27.6006 28.2478H41.799C41.9418 28.2478 42.057 28.1322 42.057 27.9889V23.3756C42.057 23.2323 41.9418 23.1166 41.799 23.1166H27.8586C27.7158 23.1166 27.6006 23.2323 27.6006 23.3756V28.2478Z" fill="#000" />
    <path d="M44.7983 28.2478H58.9968C59.1395 28.2478 59.2547 28.1322 59.2547 27.9889V23.3756C59.2547 23.2323 59.1395 23.1166 58.9968 23.1166H49.9095V20.5509H58.9968C59.1395 20.5509 59.2547 20.4353 59.2547 20.292V15.6787C59.2547 15.5354 59.1395 15.4197 58.9968 15.4197H45.0563C44.9136 15.4197 44.7983 15.5354 44.7983 15.6787V28.2478V28.2478Z" fill="#000" />
  </svg>
);

IconProviderReevoBig.propTypes = {
  className: PropTypes.string,
};

IconProviderReevoBig.defaultProps = {
  className: '',
};
