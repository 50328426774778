import React from 'react';
import PropTypes from 'prop-types';

export const IconProviderMicrogamingBig = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="120" height="30" viewBox="0 0 120 30">
    <g fill="none" fillRule="nonzero">
      <path fill="url(#icon-provider-microgaming-big-a)" d="M14.83 0C6.64 0 0 6.64 0 14.857c0 1.47.218 2.912.653 4.3.272-.381.544-.762.79-1.17a269.01 269.01 0 0 0 3.047-4.6l.734-1.17c.245-.38.49-.788.708-1.17.49-.788.952-1.578 1.388-2.367.217-.408.435-.789.68-1.197.245-.408.435-.79.653-1.197.218-.408.435-.817.626-1.198l.599-1.224.054-.082c.327-.653 1.116-.925 1.796-.626.49.273.789.79.762 1.361 0 .707-.136 1.442-.19 2.15-.055.707-.082 1.442-.137 2.177a116.171 116.171 0 0 0-.081 4.353c0 1.442.081 2.885.217 4.272.082.68.19 1.36.327 2.041.109.599.3 1.197.571 1.742.082.163.19.326.327.462.081.055.027.027.081.027h.055l.054-.027h.027l.082-.027c.027-.027.082-.054.109-.082.218-.163.408-.353.599-.571.435-.517.843-1.034 1.197-1.605.38-.572.735-1.198 1.088-1.824a63.825 63.825 0 0 0 1.987-3.836c.299-.653.626-1.306.925-1.987.3-.653.599-1.333.87-1.986a1.344 1.344 0 0 1 1.742-.735c.436.163.735.544.816 1.007 0 .163.028.381.055.599l.082.626c.054.408.108.816.19 1.224.136.816.3 1.633.517 2.422.218.789.49 1.578.816 2.34.327.762.735 1.47 1.252 2.122.109.164.082.381-.054.49-.136.109-.327.109-.436 0a11.464 11.464 0 0 1-1.796-2.013c-.517-.735-.952-1.524-1.36-2.313-.272-.545-.49-1.089-.735-1.633-.163.327-.3.653-.463.952-.653 1.334-1.306 2.667-2.068 4-.38.708-.761 1.36-1.197 2.014a16.98 16.98 0 0 1-1.442 1.932c-.3.354-.653.653-1.007.952a1.543 1.543 0 0 1-.354.218l-.244.136-.136.054h-.028l-.081.028h-.055l-.109.027c-.081.027-.136.054-.217.054l-.49.082a2.783 2.783 0 0 1-1.061-.136 2.969 2.969 0 0 1-.898-.517 4.197 4.197 0 0 1-1.007-1.279 9.413 9.413 0 0 1-.816-2.422 22.63 22.63 0 0 1-.354-2.313c-.163-1.524-.218-3.02-.245-4.544v-2.15c-.272.517-.544 1.034-.816 1.524a27.5 27.5 0 0 1-.68 1.225l-.68 1.224a126.033 126.033 0 0 1-2.885 4.844 117.172 117.172 0 0 1-1.932 3.075c4.49 6.857 13.687 8.761 20.544 4.272 6.857-4.49 8.762-13.687 4.272-20.545A14.96 14.96 0 0 0 14.83 0z" />
      <path fill="url(#icon-provider-microgaming-big-b)" d="M42.503 20.19v-9.85h-.244l-3.157 9.878h-.79l-3.156-9.878h-.244v9.85h-1.307V9.306h2.313l2.803 8.653 2.748-8.653h2.34v10.912h-1.306z" />
      <path fill="url(#icon-provider-microgaming-big-c)" d="M45.714 10.721V9.306h1.415v1.415h-1.415zm.027 9.47v-8.409h1.388v8.408h-1.388z" />
      <path fill="url(#icon-provider-microgaming-big-d)" d="M55.537 17.442c0 1.633-.625 2.885-3.347 2.885-3.428 0-3.619-1.96-3.619-4.354 0-2.395.19-4.354 3.62-4.354 2.72 0 3.346 1.252 3.346 2.884h-1.306c0-1.333-.462-1.986-2.04-1.986-2.123 0-2.232 1.143-2.232 3.429 0 2.285.109 3.428 2.231 3.428 1.579 0 2.041-.653 2.041-1.986l1.306.054z" />
      <path fill="url(#icon-provider-microgaming-big-e)" d="M58.231 12.68c.653-.408 1.497-.952 2.286-.98.163-.027.327-.027.49-.027.217 0 .408 0 .653.028l-.109 1.006c-.218-.027-.408-.027-.599-.027-.163 0-.326 0-.517.027-.734.028-1.523.572-2.095.898v6.613h-1.388v-8.436h1.089l.19.898z" />
      <path fill="url(#icon-provider-microgaming-big-f)" d="M69.442 16c0 2.395-.19 4.354-3.673 4.354s-3.674-1.96-3.674-4.354c0-2.395.19-4.354 3.674-4.354 3.483 0 3.673 1.96 3.673 4.354zm-5.959 0c0 2.286.109 3.429 2.286 3.429s2.285-1.116 2.285-3.429c0-2.313-.108-3.429-2.285-3.429S63.483 13.687 63.483 16z" />
      <path fill="url(#icon-provider-microgaming-big-g)" d="M71.973 22.15c.517.054 1.197.081 1.959.081 2.122 0 2.395-.571 2.422-2.802-.626.353-1.497.761-2.395.789-3.075.054-3.265-1.606-3.265-4.218s.163-4.354 3.13-4.354c1.006 0 1.931.463 2.666.925l.136-.761h1.088v7.455c0 3.02-.68 3.946-3.455 3.946-.817 0-1.633-.082-2.422-.19l.136-.871zm4.38-8.817c-.598-.38-1.36-.762-2.094-.762-2.041 0-2.177 1.143-2.177 3.429 0 2.231.136 3.293 2.177 3.293.734 0 1.523-.409 2.095-.708v-5.252z" />
      <path fill="url(#icon-provider-microgaming-big-h)" d="M85.252 20.19l-.136-.761c-.68.435-1.579.925-2.558.925-3.075.054-3.265-1.687-3.265-4.354s.163-4.354 3.21-4.354c1.007 0 1.905.463 2.585.871l.136-.735h1.089v8.408h-1.061zm-.272-6.911c-.572-.327-1.361-.708-2.096-.708-2.04 0-2.177 1.143-2.177 3.429 0 2.286.137 3.429 2.177 3.429.735 0 1.497-.409 2.096-.762v-5.388z" />
      <path fill="url(#icon-provider-microgaming-big-i)" d="M89.252 12.517c.626-.38 1.47-.844 2.367-.87 1.415-.055 2.15.326 2.503.924.654-.408 1.524-.898 2.477-.925 2.884-.081 3.074 1.388 3.074 3.429v5.115h-1.387v-5.034c0-1.741-.11-2.585-1.987-2.585-.68 0-1.387.409-1.959.762a7 7 0 0 1 .163 1.85v5.035h-1.197v-5.062c0-1.741-.109-2.585-1.986-2.585-.653 0-1.36.409-1.905.708v6.911h-1.388v-8.408h1.089l.136.735z" />
      <path fill="url(#icon-provider-microgaming-big-j)" d="M101.388 10.721V9.306h1.415v1.415h-1.415zm.027 9.47v-8.409h1.388v8.408h-1.388z" />
      <path fill="url(#icon-provider-microgaming-big-k)" d="M105.741 12.517c.68-.38 1.579-.844 2.558-.87 3.075-.082 3.266 1.387 3.266 3.428v5.115h-1.388v-5.034c0-1.741-.136-2.585-2.177-2.585-.735 0-1.524.409-2.095.735v6.912h-1.388v-8.436h1.088l.136.735z" />
      <path fill="url(#icon-provider-microgaming-big-l)" d="M114.259 22.15c.517.054 1.197.081 1.959.081 2.122 0 2.394-.571 2.421-2.802-.625.353-1.496.761-2.394.789-3.075.054-3.265-1.606-3.265-4.218s.163-4.354 3.129-4.354c1.007 0 1.932.463 2.667.925l.136-.761H120v7.455c0 3.02-.68 3.946-3.456 3.946-.816 0-1.632-.082-2.422-.19l.137-.871zm4.353-8.817c-.598-.38-1.36-.762-2.095-.762-2.04 0-2.177 1.143-2.177 3.429 0 2.231.136 3.293 2.177 3.293.735 0 1.524-.409 2.095-.708v-5.252z" />
    </g>
  </svg>
);

IconProviderMicrogamingBig.propTypes = {
  className: PropTypes.string,
};
IconProviderMicrogamingBig.defaultProps = {
  className: null,
};
