import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderSlotopia = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="27"
    viewBox="0 0 22 27"
    fill="none"
  >
    <g clipPath="url(#clip-icon-provider-slotopia)">
      <mask
        id="mask0_2001_16"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="27"
      >
        <path d="M21.2132 0H0V26.6188H21.2132V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_2001_16)">
        <path
          d="M16.7602 6.92616C17.7865 6.19272 18.5813 5.18802 19.0529 4.02815C18.2201 3.55241 17.293 3.25951 16.3348 3.16943C15.3767 3.07935 14.4101 3.1942 13.5011 3.50617C13.4262 3.53079 13.3563 3.56773 13.2813 3.5948C12.7818 2.19135 11.7379 0.955331 10.6116 0C9.49023 0.955331 8.42883 2.19135 7.93182 3.5948C7.8569 3.56773 7.78699 3.53079 7.71207 3.50617C6.80308 3.1942 5.8365 3.07935 4.87835 3.16943C3.9202 3.25951 2.99308 3.55241 2.16028 4.02815C2.63241 5.18707 3.42608 6.19142 4.45043 6.92616C1.40356 8.33453 0 11.0528 0 13.5125C0 16.5263 2.18275 19.3455 4.87 22.406C7.19509 25.0578 7.83943 26.6189 10.6141 26.6189C13.3887 26.6189 14.0231 25.0578 16.3582 22.406C19.0454 19.3455 21.2281 16.5263 21.2281 13.5125C21.2132 11.0528 19.8071 8.33453 16.7602 6.92616Z"
          fill="#FF3F13"
        />
        <path
          d="M15.392 12.0648C14.3281 11.0134 12.3276 10.6121 10.6069 11.661V5.71231L5.9367 11.2178C4.16352 12.9659 4.51815 15.1031 5.81932 16.3884C6.88323 17.4373 8.88615 17.8386 10.6069 16.7897V22.7261L15.2746 17.2181C17.0478 15.47 16.6931 13.3328 15.392 12.0648Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);

IconProviderSlotopia.propTypes = {
  className: PropTypes.string,
};

IconProviderSlotopia.defaultProps = {
  className: '',
};
